module.exports = {
  ...require('@/project').locales,
  langs: {
    es: 'ES',
    en: 'EN',
    fr: 'FR',
    de: 'DE',
    pt: 'PT',
    it: 'IT',
    names: {
      es: 'Spanish',
      en: 'English',
      fr: 'French',
      de: 'Deutsch',
      pt: 'Portuguese',
      it: 'Italian'
    }
  },

  flags: {
    af: 'Afrikaans',
    sq: 'Albanian',
    am: 'Amharic',
    ar: 'Arabic',
    hy: 'Armenian',
    az: 'Azerbaijani',
    bn: 'Bengali',
    bs: 'Bosnian',
    bg: 'Bulgarian',
    zh: 'Chinese (Simplified)',
    'zh-TW': 'Chinese (Traditional)',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    en: 'English',
    et: 'Estonian',
    tl: 'Filipino, Tagalog',
    fi: 'Finnish',
    fr: 'French',
    'fr-CA': 'French (Canada)',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek',
    ht: 'Haitian Creole',
    in: 'Hindi',
    hu: 'Hungarian',
    is: 'Icelandic',
    id: 'Indonesian',
    ga: 'Irish',
    it: 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    lv: 'Latvian',
    lt: 'Lithuanian',
    ms: 'Malay',
    mt: 'Maltese',
    mn: 'Mongolian',
    no: 'Norwegian',
    pl: 'Polish',
    pt: 'Portuguese',
    'pt-PT': 'Portuguese (Portugal)',
    ro: 'Romanian',
    ru: 'Russian',
    sr: 'Serbian',
    sk: 'Slovak',
    sl: 'Slovenian',
    so: 'Somali',
    es: 'Spanish',
    'es-MX': 'Spanish (Mexico)',
    sv: 'Swedish',
    th: 'Thai',
    tr: 'Turkish',
    uk: 'Ukrainian',
    vn: 'Vietnamese'
  },

  price: {
    total: 'Price + Shipping Cost can\'t be more than $420'
  },

  rrss: {
    official: 'The official account of @:project',
    twitter: 'Official Twitter',
    instagram: 'Official Instagram',
    tiktok: 'Official TikTok',
    twitter_creators: 'Twitter for Creators',
    telegram_creators: 'Telegram for Creators',
    contacts: 'Official Contacts',
    users: 'Email for users',
    creators: 'Email for creators'
  },

  utm: {
    export: 'Exportar UTM',
    export_start: 'Desde',
    export_end: 'Hasta'
  },

  tutorials: {
    first: 'First steps: How to start?',
    affiliates: 'Affiliate Program: Do you want to get extra traffic?',
    referral: 'Referral Program: Invite other creators and earn with them!',
    payments: 'Payment period: When will we send the payouts?',
    trials: 'Free trials: Invitations for users',
    chat: 'Chat: all our tools',
    videochat: 'Videochat: Make video calls and multiply your income!',
    lives: 'Broadcast live for the entire platform',
    wallet: 'Wallet in two currencies: Euros and USD',
    auctions: 'Auctions: You can now auction physical products!',
    sfs: 'SFS Revolution: Earn money by promoting other creators',
    piracy: 'Anti-piracy: How to report stolen content?',
    content: 'We migrate your content from other networks!',
    ranking: 'User\'s ranking: how much do your followers spend?',
    vault: 'How can I upload my files to the vault?',
    automessages: 'How do I schedule automatic messages?',
    products: 'How can I sell my merchandising?'
  },

  campaign: {
    valentine: 'SAINT VALENTINE’S WEEK!',
    carnival: 'CARNIVAL TIME!',
    bonus: 'We are adding an extra <b>balance of 25%</b> on your wallet <b>of the amount that you spend in new subscriptions!</b>'
  },

  reports: {
    abusiveContent: 'Abusive Content',
    additionalKYc: 'Additional KYC',
    blood: 'Blood',
    closeAccount: 'Close Account',
    copyrightViolation: 'Copyright Violation',
    drugsAlcohol: 'Drugs / Alcohol',
    fraud: 'Fraud',
    harassmentAtPlatform: 'Harassment at Platform',
    incest: 'Incest',
    minorAppearingAtContent: 'Minor appearing at content',
    non_consensuatedContent: 'Non-consensuated content',
    requestedByTheAccountOwner: 'Requested by the account owner',
    requestedByUser: 'Requested by User',
    spam: 'Spam',
    subscriptionIssue: 'Subscription Issue',
    claim: 'Potential Content Violation',
    takedown: 'Content Removal Request',
    policy_violation: 'Terms Of Service Violation',
    low: 'Low',
    medium: 'Medium',
    high: 'High'
  },

  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },

  validation: {
    tabs: {
      posts: 'PUBLICATIONS',
      products: 'PRODUCTS'
    }
  },

  academy: {
    telegram: 'JOIN TELEGRAM',

    support: 'If you have any questions, you can write to '
  },

  academy16: {
    title1: 'User\'s ranking: how much do your followers spend?',
    desc1: 'Our chat is one of the best ways to monetize on our platform. And the best way to find out if you are talking to a user who could spend money on you, and not waste time, is the user\'s ranking.',
    desc2: 'This option lets you know if the person who are interacting with you have a preference for:',
    list1: 'subscriptions',
    list2: 'content purchase',
    list3: 'sexting',
    list4: 'tips',
    desc3: 'It also allows you to know his registration date on the platform. So if you see that a user speaks to you in an incisive way but has been registered for two years and has no ranking, you may be wasting your time badly.',
    desc4: 'The ranking classified all users in descending order, with the Top 0.1% being the first position in the ranking. So if a user is in the 0.5% ranking of tips, we recommend you take care of him, because he is a person who hasn\'t problem paying.',
    desc5: 'To access the user\'s ranking, you must enter the chat with him and click on the person-shaped icon that appears in the upper right corner.',
    desc6: 'A pop-up window will appear indicating the user\'s registration date and ranking.'
  },

  academy17: {
    title1: 'File vault',
    desc1: 'The vault is a cloud within the platform where you can keep all your content (videos, photos and audios), so you don\'t always have to upload the same photos and videos. You can always have your files online just for you',
    title2: 'How can I upload my files to the vault?',
    desc2: 'To upload files to the vault, open Messages > Messaging Settings > File Vault.',
    desc3: 'Once open, you will need to drag or attach the files you want to add to the vault.',
    desc4: 'You will be able to upload 20 images or 5 videos at a time.'
  },

  academy18: {
    title1: 'Schedule automatic messages',
    desc1: 'Create predefined messages for when you have a new follower, a new fan, when you receive a tip and when a product is purchased. You can also attach photos and videos to these messages, both free and paid',
    title2: 'How do I schedule automatic messages?',
    desc2: 'In order to schedule automatic messages you will need to open Messages > Messaging Settings > Automatic messages',
    desc3: 'Once opened you will be able to configure the automatic messages individually for; new followers, new fans, buying content or sending a tip'
  },

  productAcademy: {
    title1: 'SELL YOUR PRODUCTS ON @:project_upper',
    desc1: 'On @:project can also sell products physically.',
    desc2: 'You\'ll have your own online store where you can sell every kind of products',
    desc3: 'There are two ways of selling:',
    list1: '<b>Direct (sale)</b>: when you publish the product, you set a fixed price and shipping costs. The user buys for the set price. Once the product is sold, you will receive an email with the address where you have to send the product, you send it and the customer receives it',
    list2: '<b>Auctions</b>: you can set a starting price -minimum- and you decide how long you want the auction to last: one day, one week, one month. At the end, the user who has offered the highest price for the product is the one who pays for it and gets it, this option allows you to increase your earnings per item',
    title2: 'How can I put an item for sale?',
    desc4: 'In the "product" tab you must fill in all the data and include the photos or videos of the item, as well as add the price of the item.',
    desc5: 'In this section you will choose the mode of sale (explained above).',
    desc6: 'Once you have filled in all the information you can publish it.',
    title3: 'How can I see my articles once they are published?',
    desc7: 'To see the published articles you should go to your profile > Shop',
    desc8: 'Remember that you can also auction them! ',
    desc9: 'We tell you how in the following link:',
    desc10: 'https://loverfans.com/academy/8.html'
  },

  promoAcademy: {
    title1: 'THE CHICK NEXT DOOR',
    desc1: 'Upload your <b>HOTTEST STORIES</b>, promote yourself online and <b>win $1.000!</b>',
    desc2: 'At @:project  we continue supporting our creators with extra prizes so they can increase their monetization on our platform.',
    desc3: 'With <b>The Chick Next Door</b> you will have the oportunity to win prizes of up to <b>$1.000</b> every month',
    desc4: 'Let your imagination fly, upload your stories and attract your followers on your social networks so they can vote for you and you can win the following prizes:',
    desc5: 'Participate by uploading as many videos as you want, with a limit of 3 per day! The more you upload, the more chances you have to win the prize.',
    title2: '<b>How do I have to upload the video to be able to participate?</b>',
    desc6: 'You just have to select the <b>STORY</b> option on the post screen and upload your video in vertical format. If your video meets our parameters, we will validate it and it will be eligible for the contest.',
    desc7: 'The main requirement is that the videos you record are in vertical format.',
    title3: 'How do I have to promote my profile so that they can vote for me?',
    desc8: 'Remember that the stories will not appear on your @:project  profile, only on the TheChickNextDoor website, so if you want your followers and fans to vote for you, what you should promote is your profile on the voting website.',
    desc9: 'Copy the next link to share your TheChickNextDoor profile:',
    want_participate: 'I want to participate!',
    copy_link: 'COPY LINK',
    material: 'Here we leave you material so that you can encourage your followers on social networks to vote for you in the contest.',
    go_to_resource: 'Go to resources',
    exclude: '<b>These profiles will be excluded from the contest:</b>',
    list1: 'Those who use bots to use.',
    list2: 'Those who votes through fake accounts.',
    list3: 'Those who create ad hoc profiles to count.',
    list4: 'Those who count on any other type of fraudulent votes.',
    list5: 'Those who upload repeated videos.',
    disclaimer: '*Stories are reviewed manually. They can take up to 12 hours to validate.',
    end: '*The contest ends on the last day of the month.'
  },

  academy20: {
    title1: 'THE CHICK NEXT DOOR',
    title2: 'Your GirlNextDoor',
    title3: 'Follow your favorite ChickNextDoor',
    desc1: 'Last stories uploaded by -probably- your neighbour. Look what they are doing right now, follow their last updates and meet them joining them as a fan to unblock their secret content.',
    desc2: 'On TheChickNextDoor you can follow your favorite girls and watch their last videos, support their profiles to talk directly with them and watch their most secret content.',
    desc3: 'Follow your favorite ChickNextDoor.<br>On TheChickNextDoor you can follow your favorite girls and watch their last videos, support their profiles to talk directly with them and watch their most secret content.',
    desc4: 'Vote for your favorite neighbor\'s stories, and help them win the ChickNextDoor prize from @:project .'
  },

  event: {
    halloween: {
      title1: 'LONELY HALLOWEEN?',
      desc1: 'FIND COMPANY ON @:project',
      title2: 'PROMOTE YOUR PROFILE ON',
      desc2: 'We give you 100% of your income from videocalls',
      desc3: '- Until November 2nd -'
    },
    reload: 'Charge your wallet and we\'ll give you',
    recharge: 'charging your wallet',
    gift: 'GET A ',
    percent: 'a 25% free',
    validPromotion: 'Available promo only at the first purse charge, and just for a maximum of 200€ recharges'
  },
  hints: {
    covid: 'You may experience shipping delays because of COVID-19',
    maintenance: 'Maintenance',
    wallet_1:
      'We are doing a maintance. It is possible that during this maintenance the virtual wallet will not work correctly and you will not see the correct amount.',
    wallet_2: 'We apologize for the inconvenience.',
    action_confirmation: 'Are you sure to perform this action?',
    scatbook_payment_disabled:
      'Credit card service is currently not available. You can make credit card payments from <a href="{origin}">darkfans.com</a> with Scatbook credentials'
  },

  howWork: 'How works?',

  campaigns: {
    offers: 'offers',
    start_to: 'Start to',
    blackFriday: 'black friday',
    christmas: 'christmas',
    new_chat: 'New Chat',
    chat: 'chat',
    promo: 'Promo'
  },

  superadmin: {
    addFollowers: {
      before:
        'You are going to add {count} users to the user {username}<br>Are you sure about this action?',
      after: '{count} users have been added to the user {username}',
      error: 'An error occurred during "Add Followers", do you want to retry?'
    },
    results:
      'There are <strong>{results}</strong> users witht he selected filters',
    user_search_title: 'Search CREATOR to send message'
  },

  tutorial: {
    videos: 'Tutorials',
    academy: {
      title: 'Academy',
      first: 'First Steps',
      affiliates: 'Affiliates Program',
      trials: 'Free trials',
      wallet: 'Using Wallet',
      content: 'Migration Content',
      auctions: 'Auctions',
      piracy: 'Anti-piracy',
      videochat: 'Videochat',
      chat: 'Chat',
      sfs: 'SFS Revolution',
      payments: 'Payouts period'
    }
  },

  timeleft: 'Left',

  countries: require('./countries/en').sort((a, b) =>
    a.text.localeCompare(b.text)
  ),
  countries_v2: require('./countries_v2/en.json').sort((a, b) =>
    a.text.localeCompare(b.text)
  ),

  modal: {
    not_show_again: 'Don\'t show again',
    affiliate_program: 'We launched our Affiliate Program!',
    affiliate_desc:
      'Next week we will begin to do advertising agreements with websites that will send external traffic to your profile and help you <strong>increase your revenue</strong>.',
    not_interested: 'If you are not interested, please uncheck the box.',
    more_info: 'MORE INFO',
    know_more: 'MORE INFORMATION',
    participate: 'LEARN MORE',
    telegram: 'Join our STARs Telegram group to know all the news',
    month_challenge:
      'Remember that you can now upload your video for the Monthly Challenge',
    trials_title: 'Free Trials Availables!',
    trials_desc:
      'You can now create free invitations for your followers to serve as a hook to your <strong>@:project</strong> profile.',
    monthly_title:
      '<span style="color: #F71953 !important;font-family: \'Anton\', sans-serif;">❬ AUCTION ❭ <br>tool now available</span>',
    monthly_desc: 'Auction your products and <br> multiply your selling price!',
    monthly_desc_2:
      'Starting today, fans and followers will also be able to pay in dollars, which will facilitate user payments throughout the Americas.',
    monthly_desc_3:
      'You have to do something? Do not! The price of your subscription, post sales, etc. it will be adjusted daily and automatically to the official dollar exchange rate.',
    monthly_desc_4:
      'Now you will see that two wallets appear, one in euros and the other in dollars. Don\'t worry, you can transfer money from one wallet to another without having a minimum amount in either of the two',
    monthly_btn: 'VOTE NOW',
    blackfriday_title: {
      star:
        '<span style="font-family: \'Anton\', sans-serif;">Get 100% <br> of your new subscriptions!</span>',
      follower:
        '<span style="font-family: \'Anton\', sans-serif;">Up to 95% off <br> until November 30</span>'
    },
    blackfriday_desc: {
      star: 'No platform fees',
      follower: 'Don\'t miss it'
    },
    blackfriday_button: {
      star: '+ INFO',
      follower: 'SEE DISCOUNTS'
    },
    medialib: {
      title: 'WE HAVE A NEW MEDIALIB',
      desc: 'Adjust your tags to have more visibility on '
    },
    chattool: {
      title: 'MULTIPLY YOUR INCOME\nWITH THE NEW CHAT',
      desc1:
        'Autotranslator, new configuration, SFS Revolution, packs, lists, etc.'
    },
    videochat: {
      creator: {
        title: 'Start using Video calls',
        title2: 'We give 100% for you!',
        desc1: 'Multiply your income with this new tool',
        desc2: 'and take advantage of the promotion throughout August'
      },
      follower: {
        title: 'WE ARE LAUNCHING VIDEO CALLS ',
        desc: 'RELOAD YOUR WALLET NOW AND GET 50% FREE'
      }
    },
    christmas: {
      follower: {
        desc1:
          'Get <span class="resaltado" style="color:#e2ff11; font-size: 3.5rem; font-weight: 600;">50%</span> of what you spend on new subscriptions!',
        desc2: 'We also give a 50% bonus on the first wallet recharge.'
      },
      star: {
        desc1: 'Get 100%<br>of what you generate in the chat!',
        desc2:
          'We will not apply the commission on the income you get through our chat until <span class="resaltado" style="color: #e2ff11; font-size: 2.5rem; font-weight: 500;">January 1</span>'
      }
    },
    sfs: {
      title: 'SFS REVOLUTION',
      desc1: 'the new way to multiply your earnings',
      desc2: 'Share profiles of creators and get a percentage of their sales',
      button: 'Increase my income!'
    },
    new_chat: {
      title1: 'WE HAVE NEW CHAT',
      title2: 'And we want to celebrate it with you!',
      star: {
        desc1: 'Take 100% of what you generate in the new messaging system',
        desc2:
          'We will not apply the platform fee to you on the income of what get through the new chat until the end of February'
      },
      user: {
        desc1:
          'We give you 25% of what you reload on your wallet so you can spend it on your favorite creators',
        desc2: 'From today until the end of February'
      }
    },
    tiktok: {
      title: 'WE ARE NOW ON ',
      desc: 'Record a video and win up to '
    },
    special: {
      title: '@:project_upper Advises',
      desc1:
        'We have updated our chat application so you can enjoy @:project to the fullest',
      desc2: 'Click the button.',
      button: 'Go to Chat'
    },
    timerLeft: 'LEFT'
  },

  scat_modal: {
    title: '@:project  familiy is growing!',
    subtitle: 'Welcome to',
    star_platform:
      'The first and only fans platform for <strong>100% extreme content</strong> made for your.',
    fan_platform:
      'The first and only fans platform for <strong>100% extreme content</strong>. Take a look to the newest Star\'s content.',
    what_is: 'What is Scatbook?',
    faq_link: 'Solve your doubts',
    fan_platform_2: 'We are sure this will <strong>INTEREST YOU</strong>. ',
    fan_same_credentials:
      'Use your same @:project \' username and password to login into Scatbook, Twitter login wont work.',
    is_now_on: 'is now at',
    go_to: 'Go to Scatbook',
    prorrogated_subscription: {
      greetings:
        'Hi @{follower}, we had a problem with your fan subscription to @{influencer}. Don\'t worry! Just click on the next link to renew it',
      button_text:
        'RENEW SUBSCRIPTION TO @{influencer} + 20% give back on your wallet FREE FOR YOU',
      extra_text_1:
        'Furthermore we want to announce that Scatbook has become into a part of Darkfans family, the UNIQUE BDSM FAN PLATFORM where you will find much more creators according to your tastes.',
      extra_text_2:
        'That\'s why we wan to ADD YOU AN EXTRA +20% BALANCE ON YOUR WALLET. Take advantage to enjoy our new INSTANT MESSAGE FEATURE and talk with your favorite creators asking them for personalized content and much more!'
    }
  },

  scat_faq: require('./scat_faq/en'),

  chats: {
    new_message: 'New Message',
    including_tax: 'Plus {taxes} including taxes and bank expenses',
    deleteMessage: 'Do you want remove this message?',
    errorDeleteMessage: 'Error deleting, {user} has paid for the content',
    error: {
      send: 'Error has occurred when sending the message'
    },
    tooltipPrice:
      'Each message sent costs you {price} and you charge {preferencePrice} per message received',
    tooltipPriceUser: 'Each message sent costs you {price}',
    tooltipPriceCreator: 'You have set the chat cost to {preferencePrice}',
    buy_action: 'Buy {files} files for {price}',
    price_conversations: 'The conversation will be free',
    want: 'You want to {action} to {user}?',
    load_funds_info:
      'Any amount between <strong> {priceStart} </strong> and <strong> {priceEnd} </strong>',
    user_preferences: {
      title_1: 'REGISTERED ON',
      title_2: 'TOP USER',
      title_3: 'SET INDIVIDUAL PRICES',
      score_text: {
        sub: {
          rank: 'TOP <strong>{porcent}%</strong> IN SUBSCRIPTIONS',
          no_rank: 'UNRANKED IN SUBSCRIPTIONS'
        },
        buy: {
          rank: 'TOP <strong>{porcent}%</strong> IN BUYING CONTENT',
          no_rank: 'UNRANKED IN BUYING CONTENT'
        },
        text: {
          rank: 'TOP <strong>{porcent}%</strong> IN SEXTING',
          no_rank: 'UNRANKED IN SEXTING'
        },
        tips: {
          rank: 'TOP <strong>{porcent}%</strong> IN TIPS',
          no_rank: 'UNRANKED IN TIPS'
        }
      }
    },
    warningDeleteUser: {
      title: 'Warning',
      text: 'This user is currently unavailable'
    },
    attachment: {
      title: 'Attachs file',
      price_send_file:
        'Sending files will cost you {price}, do you want to continue?',
      error_wallet_not_enought_amount: ' Do you wanna {userReceiver} open your file? - Cost {price}',
      unlock: 'UNBLOCK FOR {price}',
      lock: 'Unblock for {price}',
      sold: 'Sold for {price}',
      bought: 'Bought for {price}',
      sell: 'Sells for {price}'
    },
    price_conversations_preference: {
      price_conversations: 'The conversation will be free',
      title_1: 'Free',
      title_2: 'New Price',
      price: 'Message price'
    },
    new_broadcast: {
      title: 'New Multiple Message',
      by: 'Message to',
      exclude: 'Exclude users or Lists',
      include: 'Include users or Lists',
      message: 'Message',
      schedule_message: 'Schedule Message',
      type: {
        all: 'All',
        free: 'Free',
        paid_chat: 'Payment Chat',
        followers: 'Followers',
        fans: 'Fans',
        others: 'Others',
        creators: 'Include Creators',
        creators_note: '(All text messages between creators are FREE)',
        creators_note_not_allowed:
          'Creators cannot be added if there are attachments in the message',
        followed: 'Followed By me'
      }
    },
    schedule_message: {
      title: 'Schedule Messages',
      programmed: 'Programmed to be sended on ',
      sended: 'Sended on ',
      send_to: 'Send to: ',
      price: 'Price: ',
      exclude: 'Exclude list: ',
      excludeMessage: 'The next users won\'t receive this message:',
      includeMessage: 'The following users will receive this message:',
      deleteSchedule: 'Do you want remove this Schedule Message?'
    },
    gallery: {
      images: 'IMAGES',
      videos: 'VIDEOS',
      audios: 'AUDIOS'
    },
    remove: {
      all: 'Do you want delete ALL conversations?',
      list: 'Do you want delete {number} conversations?'
    },
    read: {
      all: 'Do you want mark as read ALL conversations?',
      list: 'Do you want mark as read {number} conversations?'
    },
    video_chat: {
      error: {
        buzy: '{user} is busy right now!',
        error_default: 'Error connecting to {user}',
        hasMoney: 'You don\'t have enought funds in your wallet',
        cams: 'To use this functionality, it is necessary to give permissions to the camera and microphone.Once the permissions are granted, refresh the web',

        cams1: 'To make video calls, {project} needs access to your camera and microphone. Click {icon} in the navigation bar and choose “Always allow {url} to access camera and microphone.” <br><br> * To see the changes refresh the web',
        videochat: 'The user has videochats disabled',
        freeSetting: 'The videocall is free. Do you want to set a new price?',
        hasMoneyFollower: 'the {user} don\'t have enought funds in your wallet'

      },
      tooltip_call: 'Click here to start the video call',
      notification: {
        price: 'Price {price}/min',
        rejected_call: 'You have tried to contact {user} but it is not available',
        oneMinuteFinishCall: 'The call ends in a minute due to lack of balance',
        noFunds: 'The call ended due to lack of balance',
        errorCall_nofund: 'Please, load funds to resume the videocall '
      }
    }
  },

  index: {
    header: {
      loverfans:
        'The Uncensored Socialnetwork for TOP Influencers<br> Hot Instagramers, Tiktokkers and streamers are welcome',
      darkfans:
        'The unique social network for festish and kink lovers.<br> Where Mistress and Masters bring you the opportunity to tribute for their pleasures',
      scatbook:
        'The unique social network for fans of the most extreme content:<br> Scat, pissing and all kind of content that creators can not show you on other platforms'
    },
    counter: {
      creators: 'CREATORS',
      videos: 'VIDEOS',
      photos: 'PHOTOS',
      discover: 'DISCOVER'
    },
    login: 'Login with:',
    twitter: 'LOGIN WITH TWITTER',
    google: 'LOGIN WITH GOOGLE',
    or: 'Or',
    free_account:
      'Create a <strong style="font-family: Quicksand, sans-serif">FREE ACCOUNT</strong> in less than a minute',
    signUp: {
      title: 'I want to register like: ',
      fan: 'Fan',
      creator: 'Creator'
    },
    footer: {
      title: 'MAKE MONEY WITH THE BEST FAN PLATFORM',
      link: '(CLICK HERE AND WE EXPLAIN YOU THE @:project_upper REVOLUTION)'
    }
  },

  revolution: {
    desc:
      'We are the best fan platform to sell your adult content. An uncensored platform with people specially dedicated to grow your brand.',
    why: 'WHY? THESE ARE OUR ADVANTAGES',
    advantages: {
      content: {
        title: 'WE ADMIT ADULT CONTENT',
        desc:
          'You don\'t and won\'t have any problems with your content. Uncensored for different types of niche. Ask us if you have any doubts. We have been in the industry for more than 18 years.'
      },
      support: {
        title: 'PERSONALIZED SUPPORT',
        desc:
          'In all departments we speak your language and we will assign you a personalized account manager to manage your account.'
      },
      visits: {
        title: 'WE FACILITATE YOUR VISITS',
        desc:
          'The only fan platform on the market with an affiliate system. We connect you with large websites and social media accounts for advertising.'
      },
      extra: {
        title: 'PRIZES WITH EXTRA MONEY',
        desc:
          'Every month special bonuses, promotions and extra contests where you can compete for cash prizes.'
      },
      payments: {
        title: 'PAYMENTS EVERY WEEK',
        desc:
          'No waiting, every week you will be paid what you have earned by the payment method you have selected: exclusive payment methods for the whole world.'
      },
      sexting: {
        title: 'OWN SEXTING SYSTEM',
        desc:
          'Charge the user per message when you are chatting with them and generate multiple simultaneous conversations to generate revenue in parallel.'
      },
      videos: {
        title: 'SELLS PHOTOS, VIDEOS AND OBJECTS!',
        desc:
          'Systems of: subscription (fan), PPV (sale of videos and personalized and private videos), generate auctions and sell physical items in your store.'
      },
      live: {
        title: 'LIVE BROADCAST',
        desc:
          'Own Livechat system to broadcast to all your followers or fans or make a customized session at the price you choose.'
      },
      sales: {
        title: '80% OF YOUR SALES',
        desc:
          'Earn 80% of your sales. WE DO NOT CHARGE YOU FOR RETURNS that your customers may make. No more surprises when it comes to receiving your settlements.'
      }
    },
    go_to: 'GO TO CREATORS SIGN UP ➤',
    need: {
      title: 'WHAT NEED?',
      camera: {
        title: 'If you got a cellphone, you can begin!',
        desc:
          'You can start uploading your content straight from your cellphone! Build up your followers and fans so you can start earning money right now.'
      },
      content: {
        title: 'Any kind is welcome',
        desc:
          'Upload the most according videos to your style and fantasies. Our users enjoy many things you couldn\'t ever even imagine. All fetishes are welcome!'
      },
      adult: {
        title: 'Only Adults',
        desc:
          'Remember that you, as any of the participants on your videos, must be older than 18 or 21 years old depending on your country of residence. If necessary, we will ask you for extra documentation.'
      }
    },
    verify:
      'VERIFY YOUR ACCOUNT IN <span class="gris"  style="font-family: Quicksand, sans-serif">5</span> MINUTES',
    step:
      'Upload some photos of your documentation as we ask for and we will verify your account so you can start making money.'
  },

  discover: {
    new: 'What\'s new?',
    top: 'Top Models',
    newest: 'Newest'
  },

  footer: {
    reserved: 'All rights reserved',
    disclaimer:
      'All persons appearing in this website and or videos are eighteen years of age or older. Proof on file.'
  },

  generic: {
    home: 'Home',
    scatbook: 'My Scatbook',
    loverfans: 'My Loverfans',
    about: 'About Me',
    new_videos: 'Newest',
    top_rated: 'Top rated',
    most_viewed: 'Most viewed',
    health: 'Health Disclaimer',
    beginners: 'Beginner\'s Guide',
    past: 'Past Reviews',
    supporting: 'Supporting Scat',
    purchase: {
      price: 'Buy for {price}'
    },
    add: {
      cart: {
        post: 'Add video to cart',
        sale: 'Add to cart'
      },
      credit: 'Add Credit to Cart'
    },
    videos: {
      filter: {
        popular: 'Popular',
        newest: 'Newest'
      }
    }
  },

  month_challenge: {
    header: {
      title: 'YOUR CRAZY FAP',
      signUp: 'SIGN UP',
      subtitle: {
        1: '<br class="telefono">Monthly Challenge by <img style="width:100px" src="/retodelmes/july_files/logotipo.svg" alt="Loverfans">',
        2: 'With your friends, in the lift, at work...',
        3: '<span class="blanco"></span>Who will be done the craziest fap?'
      },
      join: '<strong>JOIN!</strong>',
      prizes:
        '<span class="rojo">1st</span> PRIZE <span class="rojo">500€</span> Y <span class="rojo">3</span> SECOND PRIZES OF <span class="rojo">200€</span>',
      modal: {
        signUp:
          'Sign up <span class="signUp">within one minute </span>to see this vontent'
      },
      btn: 'SIGN UP'
    }
  },

  meta: {
    loverfans: {
      title: 'Loverfans the uncensored fan network for naughty influencers.',
      description:
        'Loverfans the uncensored network for naughty influencers. Where hot instagrammers, tiktokkers, streamers and youtubers meet with their fans'
    },
    scatbook: {
      title: 'Scatbook the unique social network for scat lovers.',
      description:
        'Scatbook is the social network for scat lovers where the nastiest creators meet with their fans and the premium Mistress and Masters control their slaves.'
    },
    darkfans: {
      title: ' Darkfans is the only social network for BDSM and fetish lovers.',
      description:
        'Darkfans is the social network to support your favorites Mistress and Masters tributing them for their pleasures. Become their fans and show them you are a real kinksters'
    },
    passwords: {
      title: 'Remember password | @:project'
    },
    conditions: {
      title: 'Terms and contitions | @:project',
      description:
        'Terms and conditions for @:project usage. You will have all the information relative to @:project platform and your respective rights and obligations.'
    },
    contact: {
      title: 'Contact with @:project',
      description:
        'You can send any message to us and we will answer as soon as possible'
    },
    cookies: {
      title: 'Cookies usage on @:project',
      description:
        'Here is detailed what is the cookies usage we do on @:project.'
    },
    faq: {
      title: 'FAQ: Frequently asked questions | @:project'
    },
    privacy: {
      title: 'Our privacy | @:project'
    },
    signup: {
      title: 'Register | @:project',
      description:
        'Register to @:project platform to become an Star or enjoy watching all our private content'
    },
    users: {
      title: '{name}\'s @:project official profile',
      description:
        '{name}\'s official @:project profile where you have access to custom packs. All {name}\'s private photos and videos only for fans'
    },
    posts: {
      title: '@{username} post | @:project'
    },
    medialib: {
      title: 'Premium content | @:project',
      description:
        'Premium Loverfan\'s content for you. You can search througth all our content and find out the most exclusive photos and videos on the web'
    }
  },

  words: {
    here: 'here',
    all: 'All',
    mark_all: 'Check All',
    finish_in: 'Finishing',
    more_count: '{count} more',
    today: 'today',
    thank_you: 'Thank you!',
    month: 'month',
    months: 'months',
    selected: 'Selected',
    featured: 'ft.',
    unread: 'Unread',
    read: 'Read',
    delete: 'Delete',
    fans: 'Fans',
    with_tip: 'With Tips',
    choose_language: 'Choose your language'
  },

  privacy_v2: {
    legal_base: {
      legit: 'Legitimate interest',
      consentment: 'consent',
      contract: 'Execution of contractual relationship'
    },
    tittle: 'PRIVACY POLICY',
    section_1: {
      desc_1: {
        image: 'This privacy policy (hereinafter, the <b>"Privacy Policy"</b>) has been drafted by <img src="images/artemisa_{color}.png" style="margin-bottom: -1px; width: 180px; height: 13px"/>(hereinafter <b>ARTEMISA</b>, or the <b>"Data Controller"</b> ) in order to comply with the duty of transparency in the processing of personal data that it may carry out in its capacity as data controller, in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (hereinafter, <b>"RGPD"</b>), and the Organic Law 3/2018 of December 5, 2018, on the Protection of Personal Data and guarantee of digital rights (hereinafter, "LOPDGDD").',
        noImage: 'This privacy policy (hereinafter, the <b>"Privacy Policy"</b>) has been drafted by Artemisa 3000 SL (hereinafter <b>ARTEMISA</b>, or the <b>"Data Controller"</b> ) in order to comply with the duty of transparency in the processing of personal data that it may carry out in its capacity as data controller, in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (hereinafter, <b>"RGPD"</b>), and the Organic Law 3/2018 of December 5, 2018, on the Protection of Personal Data and guarantee of digital rights (hereinafter, "LOPDGDD").'
      },
      desc_2: 'The information contained in this Privacy Policy allows you to consult the details of the various processing of your personal data that may be carried out as a result of accessing and using the website indicated below (hereinafter, the "<b>Website</b>"), as well as the various relationships, legal or commercial, that you may have with ARTEMISA.',
      desc_3:
        'We also inform you that ARTEMISA has appointed a data protection officer (hereinafter referred to as <b>"Data Protection Officer"</b> or <b>"DPO"</b>), who, if you wish, can be contacted to discuss any matter related to the processing of your personal data at the following e-mail address: <a href="mailto:rgpd@artemisa3000.com">rgpd@artemisa3000.com</a>.'
    },
    section_2: {
      tittle:
        'NEED TO PROVIDE PERSONAL DATA',
      desc_1:
        'We inform you that the mere access to the Website does not imply any obligation to provide any personal information, except as provided for in the <a href="/cookies">Cookies Policy</a>',
      desc_2:
        'We inform you that the mere access to the Website does not imply any obligation to provide any personal information, except as provided for in theShowhowever, the use of some services or functionalities of the Website does require you to provide certain personal data, indicated in the corresponding forms, and implies its treatment with the purposes and bases of legitimacy indicated in this Privacy Policy, unless otherwise indicated.',
      desc_3:
        'The refusal to provide the required data in these cases may make it impossible to provide some services or functionalities, or to process the requests or contracts made, especially in those cases in which they are identified as mandatory.',
      desc_4:
        'As the interested party, you are responsible for ensuring that the information you provide through the Website is true, accurate, complete and up to date. For these purposes, you will be responsible for the veracity of all the data you provide and you must keep the information provided duly updated, so that it corresponds to your real situation.',
      desc_5:
        'Likewise, he/she shall be liable for any false or inaccurate information provided and for any damages, direct or indirect, that this may cause to the Data Controller or third parties.'
    },
    section_3: {
      tittle:
        'RESPONSIBLE FOR THE PROCESSING OF YOUR PERSONAL DATA',
      desc_1:
        'Below you will find the details of the entity acting as data controller:',
      list_1:
        '<b>Owner</b>: ARTEMISA',
      list_2:
        '<b>Registered office</b>: Paseo de la Castellana 129, 1º, 28020, Madrid, España',
      list_3:
        '<b>NIF</b>: B88466743.',
      list_4:
        '<b>DPO\'s email</b>: <a href="mailto:rgpd@artemisa3000.com">rgpd@artemisa3000.com</a>'
    },
    section_4: {
      tittle:
        'PURPOSES OF PROCESSING, LEGAL BASIS AND CATEGORIES OF DATA',
      desc_1:
        'The data provided through the Website may be processed in accordance with the purposes detailed below and on the bases of legitimacy indicated.',
      desc_2:
        'If you access the Website as a <b>non-registered user</b>, we will process your personal information for the following purposes:',
      table_1: {
        header_1: {
          title: 'Purposes',
          desc_1: 'To allow navigation and access to the content of the Web Site.',
          desc_2: 'Display personalized content based on your preferences.'
        },
        header_2: {
          title: 'Legal Base'
        },
        header_3: {
          title: 'Categories of personal data',
          list_1: 'Personal characteristics data: age of majority.',
          list_2: 'IP Address.',
          list_3: 'Data concerning sexual life and orientation'
        }
      },
      desc_3: 'If you access the Website as a <b>registered user</b>, we will process your information for the following purposes:',
      table_2: {
        header_1: {
          title: 'Purposes',
          desc_1: 'Display personalized content based on your preferences.',
          desc_2: 'Sending commercial communications about our own products and services, as well as updates and news.',
          desc_3: 'Sending commercial communications from ARTEMISA, as well as from other entities that collaborate with us or are part of our group.',
          desc_4: 'Manage the response to your requests, queries or requests through the contact form provided for this purpose on the web.',
          desc_5: 'To allow you to register on our Website and manage your profile, as well as to communicate with you when necessary.',
          desc_6: 'Enable subscription to content creators\' profiles and, if applicable, manage the purchase of premium publications, as well as provide access to paid or PPV (pay-per-view) publications.',
          desc_7: 'Facilitate the purchase of products offered by content creators.',
          desc_8: 'Facilitate video calls with content creators.'
        },
        header_2: {
          title: 'Legal Base'
        },
        header_3: {
          title: 'Categories of personal data',
          list_1: 'Data concerning sexual life and orientation.',
          list_2: 'Identification data: name and surname.',
          list_3: 'Contact information: e-mail',
          list_4: 'Identification data: name and surname.',
          list_5: 'Contact information: e-mail.',
          list_6: 'Other: any personal data provided through the contact form',
          list_7: 'Identification data: name and surname (user name).',
          list_8: 'IP of discharge and IP of use.',
          list_9: 'Contact information: e-mail, address, and telephone number.',
          list_10: 'Data on personal characteristics: date of birth, age, and nationality.',
          list_11: 'Social networks',
          list_12: 'Bank details and card token',
          list_13: 'Profile image.',
          list_14: 'Identification data: name and surname (user name).',
          list_15: 'Bank details: card number and information required to proceed with payment',
          list_16: 'Identification data: name and surname (user name).',
          list_17: 'Contact information: e-mail and postal address (province, city and zip code).',
          list_18: 'Data on personal characteristics: nationality.',
          list_19: 'Image and voice data.'
        }
      },
      desc_4: 'If you access the Website as a <b>content creator</b>, we will process your information for the following purposes:',
      table_3: {
        header_1: {
          title: 'Purposes',
          desc_1: 'Display personalized content based on your preferences.',
          desc_2: 'Sending commercial communications about @:project  own products and services, as well as updates and news.',
          desc_3: 'Sending commercial communications from @:project , as well as from other entities that collaborate with us or are part of our group.',
          desc_4: 'Manage the response to your requests, queries or requests through the contact form provided for this purpose on the Website.',
          desc_5: 'To allow you to register on our Website and manage your profile, as well as to communicate with you when necessary.',
          desc_6: 'Enable subscription to content creators\' profiles and, if applicable, manage the purchase of premium publications, as well as provide access to paid or PPV (pay-per-view) publications.',
          desc_7: 'Facilitate the purchase of products offered by content creators.',
          desc_8: 'Facilitate video calls with users.',
          desc_9: 'Check that the user is of legal age. For this we use a facial recognition system that helps us to speed up this process.'
        },
        header_2: {
          title: 'Legal Base'
        },
        header_3: {
          title: 'Categories of Personal Data',
          list_1: 'Data concerning sexual life and orientation',
          list_2: 'Identification data: name and surname.',
          list_3: 'Contact information: e-mail',
          list_4: 'Identification data: name and surname',
          list_5: 'Contact information: e-mail',
          list_6: 'Other: any personal data provided through the contact form',
          list_7: 'Identification data: name and surname (user name).',
          list_8: 'DNI/Passport/Driver\'s license or any other identification document depending on the selected country',
          list_9: 'Contact information: e-mail, address, and phone number',
          list_10: 'Data on personal characteristics: date of birth, age, and nationality',
          list_11: 'Social networks',
          list_12: 'Bank details and card token',
          list_13: 'Profile image',
          list_14: 'Identification data: name and surname (user name).',
          list_15: 'Bank details: card number and information required to proceed with payment',
          list_16: 'Identification data: name and surname (user name).',
          list_17: 'Contact information: e-mail and postal address (province, city and zip code).',
          list_18: 'Data on personal characteristics: nationality',
          list_19: 'Image and voice data.',
          list_20: 'Biometric data: face biometric pattern',
          list_21: 'Identification data: DNI/Passport/Driver\'s license or any other identification document depending on the country selected.'
        }
      }
    },
    section_5: {
      tittle: 'CONSERVATION PERIOD',
      desc_1: 'Personal data will be kept for the following periods, depending on the purpose for which they are processed:',
      list_1: 'a) To allow navigation and access to the content of the Web Site: the data will be processed for as long as necessary to fulfill the stated purpose or until the user opposes to it. After that, they will be kept for the period of limitation of legal actions that may arise from this treatment.',
      list_2: 'b) Display personalized content based on your preferences: the data will be processed for as long as necessary to fulfill the stated purpose or until the user revokes his consent. After that, they will be kept for the period of limitation of legal actions that may arise from this treatment.',
      list_3: 'c) Sending commercial communications about our own products and services, as well as updates and news: the data will be processed as long as necessary to fulfill the stated purpose or until the user opposes to it. After that, they will be kept during the period of prescription of the legal actions that could derive from this treatment.',
      list_4: 'd) Sending commercial communications from @:project , as well as from other entities that collaborate with us or are part of our group: the data will be processed as long as necessary to fulfill the stated purpose or until the user revokes his consent. After that, they will be kept for the period of limitation of legal actions that may arise from this treatment.',
      list_5: 'e) Manage the response to your requests, queries or requests through the contact form provided for this purpose on the web: the data will be retained for the period necessary to process and answer your request or until the user revokes his consent. After that, the data will be kept for the period of limitation of legal actions that may arise from this treatment.',
      list_6: 'f) To allow registration on our Website and the management of your profile, as well as to communicate with you when necessary: the data will be kept for the duration of the contractual relationship and, once the contractual relationship has ended, for the period of limitation of any legal actions that may arise from this processing.',
      list_7: 'g) To allow subscription to the profiles of content creators and, where appropriate, to manage the purchase of premium publications, as well as to provide access to paid publications or PPV (payment for exclusive material): the data will be processed for as long as they are necessary to fulfill the stated purpose. After that, they will be kept for the period of limitation of legal actions that may arise from this treatment.',
      list_8: 'h) Facilitating the purchase of products offered by the content creators: the data will be processed for as long as necessary to fulfill the stated purpose. After that, they will be kept for the period of limitation of legal actions that may arise from this treatment.',
      list_9: 'i) Facilitating video calls with users: the data will be processed for as long as necessary to fulfill the stated purpose or until the user revokes his consent. After that, they will be kept for the period of limitation of legal actions that may arise from this treatment.',
      list_10: 'j) To verify that the content creator is of legal age: the data will be processed for as long as necessary to fulfill the stated purpose or until the user revokes his consent. After that, the data will be kept for the period of limitation of legal actions that may arise from this treatment.'
    },
    section_6: {
      tittle: 'DATA RECIPIENTS',
      desc_1: 'The personal data of the interested parties may be communicated to the following entities or in the following cases:',
      list_1: 'Public administrations: in those cases provided for by law and based on compliance with a <b>legal obligation.</b>',
      list_2: 'Courts and Tribunals: in those cases provided for by law and based on compliance with a <b>legal obligation.</b>',
      list_3: 'Security Forces and Corps: for those cases provided for by law and based on compliance with a <b>legal obligation.</b>',
      desc_2: 'In addition, ARTEMISA suppliers may have access to personal data, being such accesses, in any case, necessary for the correct fulfillment and operation of the purposes previously described.',
      desc_3: 'In all these cases and in any other in which there are entities that have access to your personal data, @:project guarantees that this access will be exclusively for the purposes described in this Privacy Policy, in accordance with the data protection regulations and complying with all the technical and organizational measures necessary to guarantee the security and confidentiality of your personal information.'
    },
    section_7: {
      tittle: 'DATA PROCESSING OUTSIDE THE EUROPEAN ECONOMIC AREA (EEA)',
      desc_1: 'Regarding the location of your data, we inform you that the use of the services of some of the ARTEMISA providers entails the processing of data outside the European Economic Area, namely in the United States and the United Kingdom, such transfers being covered respectively by the standard contractual clauses and adequacy decision approved by the European Commission.',
      desc_2: 'Notwithstanding the foregoing, in general, the communication of personal data to recipients located in countries outside the European Economic Area is not foreseen. In the event that the intervention of any of the ARTEMISA providers involved in the provision of the services may involve an international transfer of data, the same shall be duly informed and adequately regularized, adopting the appropriate guarantees for its realization.'
    },
    section_8: {
      tittle: 'AUTOMATED DECISIONS',
      desc_1: 'The choice to carry out the operations of identification and verification of the age of majority by means of the facial recognition system means that these checks will be carried out automatically. For this purpose, the validity of the identity document will be checked, as well as the correspondence between the image contained in this document and the face detected by scanning it. If the result of this process is negative, you will not be able to complete the registration process as a content creator. In any case, you will always have the opportunity to challenge the decision taken and repeat the registration process, as well as to request that this process is carried out by a natural person. Likewise, you may carry out the process of identification and verification of the age of majority by alternative means that do not involve the processing of your biometric data for this purpose. In this sense, if you need more information on this matter, please do not hesitate to write to us at <a href="mailto:rgpd@artemisa3000.com">rgpd@artemisa3000.com<a/>.'
    },
    section_9: {
      tittle: 'RIGHTS OF THE DATA OWNER',
      desc_1:
        'As a data subject, you can exercise your data protection rights by sending an e-mail to <a href="mailto:rgpd@artemisa3000.com">rgpd@artemisa3000.com</a>, at any time and free of charge. Specifically, your data protection rights are as follows:',
      list_1: '<b>Access rights: </b>',
      desc_2: 'You have the right to be informed by @:project  whether or not it is processing your personal data and, if so, you can access such data and receive information on the purposes for which they are processed, the categories of data affected by the processing, the recipients to whom your personal data were communicated and the expected period of data retention, among other information.',
      list_2: '<b>Right of rectification: </b>',
      desc_3: 'You have the right to obtain without undue delay from ARTEMISA the rectification of inaccurate personal data concerning you, as well as to have inaccurate personal data completed or amended.',
      list_3: '<b>Right to suppression: </b>',
      desc_4: 'You have the right to obtain without undue delay, by @:project , the deletion of personal data concerning you when such data is no longer necessary for the purposes for which it was collected or when the data has not been processed in accordance with the provisions of the regulations.',
      list_4: '<b>Right to limitation of processing: </b>',
      desc_5: 'You have the right to obtain from @:project  the limitation of the processing of your personal data when any of the conditions provided for in the data protection regulations are met.',
      list_5: '<b>Right to withdraw consent: </b>',
      desc_6: 'You have the right to withdraw the consent you have given, for any or all of the purposes based on such consent. Specifically, you may withdraw the consents granted in relation to the following purposes: (i) display personalized content based on your preferences, (ii) sending commercial communications from @:project , as well as from other entities that collaborate with us or are part of our group, (iii) manage the response to your requests, queries or requests through the contact form provided for this purpose on the web, (iv) facilitate video calls with content creators and (v) verify that the user is of legal age.',
      list_6: '<b>Right of opposition: </b>',
      desc_7: 'You have the right to object to the processing, at any time, on grounds relating to your particular situation, where the processing is based on the legitimate interest of ARTEMISA. In this case, ARTEMISA will cease processing, unless compelling legitimate grounds override the interests, rights and freedoms of the data subject. Specifically, you may exercise your right of opposition with respect to the following purposes: (i) to allow navigation and access to the content of the Website and (ii) to send commercial communications about our own products and services, as well as updates and news.',
      list_7: '<b>Right to data portability</b>',
      desc_8: 'You have the right to receive the personal data you have provided to ARTEMISA in a structured, common and machine-readable format, and to be able to transmit it to another data controller without being prevented from doing so by the data controller to whom you have provided it, in the cases legally provided for this purpose.',
      list_8: '<b>Automated individual decisions: </b>',
      desc_9: 'Furthermore, in the event that automated individual decisions are made, you have the right not to be subject to a decision based solely on automated processing, including profiling, that produces legal effects or is likely to significantly affect you in a similar way. In this regard, you have the right to obtain human intervention by ARTEMISA, as well as, if necessary, to challenge the decision and to express your point of view.',
      desc_10: 'In any of the cases described above, you can contact @:project  by sending a communication to the address Paseo de la Castellana 129, 1º, 28020, Madrid, Spain or to the following e-mail address <a href="mailto:rgpd@artemisa3000.com">rgpd@artemisa3000.com</a> In addition, when you consider that ARTEMISA has violated the rights that are recognized by the applicable regulations on data protection, you may file a complaint with the Spanish Data Protection Agency.'
    },
    section_10: {
      tittle: 'SECURITY MEASURES',
      desc_1: 'ARTEMISA will treat the personal data in an absolutely confidential manner and will keep the mandatory duty of secrecy with respect to the same, in accordance with the provisions of the applicable regulations, adopting for this purpose the necessary technical and organizational measures aimed at ensuring the security of the data and to avoid its alteration, loss, treatment or unauthorized access, taking into account the state of the technology, the nature of the data stored and the risks to which they are exposed.'
    },
    section_11: {
      tittle: 'UPDATING THIS PRIVACY POLICY',
      desc_1: 'ARTEMISA reserves the right to revise and update this Privacy Policy at any time it deems appropriate. Updates to this Privacy Policy will be available on the Website, so we encourage you to periodically check this Privacy Policy.'
    }
  },

  unlogged: {
    disclaimer: {
      title: 'ACCESS FORBIDDEN TO ',
      underAge: 'MINORS +18',
      paragraph_1:
        'This website is strictly reserved for adults over 18 years of age or the legal age of majority in your country of residence (whichever is higher).',
      paragraph_2: 'Any attempt of access by minors is strictly prohibited.',
      paragraph_3: 'By accessing this website, you must certify that you are 18 years of age or older and that you have the legal capacity to access adult content in your jurisdiction.',
      paragraph_4: 'Any unauthorized access by minors will be treated as a serious violation of our terms of use and may result in legal action.',
      paragraph_5: 'In addition, <span style="text-decoration: underline;">we advise parents and legal guardians to constantly supervise the activities of minors.</span> and, for this purpose, use parental control tools to prevent any attempt to access this website: <a style="text-decoration: underline; font-weight: bold">please check here for multiple FREE access control options for minors</a> we offer you',
      paragraph_6: 'We are not responsible for any damage, inconvenience or legal consequences that may arise from unauthorized access by minors to this website. The responsibility lies entirely with the parents, legal guardians and those who attempt to circumvent these age restrictions.',
      paragraph_7: 'To this end, this website meets the standards for restricting access to minors and has been labeled in its own code as RTA (Restricted to Adults) or what is the same "Restricted access to minors".',
      paragraph_8: 'If you have any questions or concerns about our age restriction policies, please contact us immediately at ',
      email: 'rta@siouxmedia.com',
      answer_yes: 'YES, I\'M OF LEGAL AGE',
      answer_no: 'NO, TAKE ME SOMEWHERE ELSE'
    },
    unlogged: 'Guest'
  },

  landing: {
    first: {
      welcome: 'WELCOME TO <br>@:project_upper',
      paragraph:
        'Are you looking for <strong>STARS</strong>? or are you the <strong>STAR</strong>?'
    },
    second: {
      star_tab: {
        title: 'I\'M A STAR',
        content: {
          c1:
            'BECOME A <strong>STAR</strong><br><span style="font-size: 46px !important"><strong>IT\'S FREE</strong></span>',
          // c2: `<strong>IT'S FREE</strong>`,
          c3: '@:project is the only platform that allows you ',
          c4: 'manage your entire business easily, in one place',
          c5:
            'your <strong>fans</strong> their <strong>fantasies</strong> your <strong>business</strong> your <strong>rules</strong>',
          btn: 'BECOME STAR FREE',
          cards: {
            mail: {
              title: 'PAYMENT MESSAGING',
              desc: 'Interact with your fans, with paid messages'
            },
            shop: {
              title: 'SHOP',
              desc:
                'You can sell literally any type of content, physical or digital in @:project'
            },
            live: {
              title: 'LIVES',
              desc:
                'Schedule your live shows and encourage your audience with challenges that you can monetize'
            },
            refer: {
              title: 'REFERALS',
              desc:
                'Bring stars from other platforms and benefit from your income, for life'
            },
            photo: {
              title: 'PHOTOS',
              desc: 'Share your best photos with your fans'
            },
            video: {
              title: 'VIDEO',
              desc: 'Show off your most daring and crazy videos to your fans'
            },
            bid: {
              title: 'BIDS',
              desc:
                'Cloths, accessories, toys, you set the limit and the starting price of the bid!'
            },
            premium: {
              title: 'PREMIUM CONTENT',
              desc:
                'Create custom content for your best fans and negotiate a price accordingly'
            }
          },
          know_more: {
            title:
              'YOU HAVE <br> <span style="font-size: 46px"><strong>CONTROL</strong></span>',
            desc:
              'At @:project_upper you choose how you want your followers to access your content: ',
            card: {
              type: {
                public: {
                  title: '<strong>PUBLIC</strong>',
                  desc: '<strong>Any user</strong> can see it'
                },
                private: {
                  title: '<strong>PAID</strong>',
                  desc:
                    'Only those <strong>who pay</strong> the price fixed by you will have access'
                }
              },
              target: {
                multi: {
                  title: '<strong>MY FANS</strong>',
                  desc: 'Only <strong>your fans</strong> can see it'
                },
                single: {
                  title: '<strong>ONE FAN</strong>',
                  desc:
                    'Only <strong>WHO</strong> you choose will be able to see it'
                }
              }
            }
          },
          loverfans: 'Know more about @:project',
          warranty:
            'We pay worldwide and <strong>our personalized team</strong> will help you find the <strong>most advantageous solutions for you </strong>'
        }
      },
      fan_tab: {
        title: 'I\'M A FAN',
        content: {
          c1: 'GO IN AND FOLLOW YOUR <strong>FAVORITES</strong>',
          c2: 'STARS',
          c3: 'Register and enjoy its content ',
          c4:
            'or become her/his <strong>FAN</strong>, to access their exclusive content',
          c5: {
            more: 'more than ',
            stars: '<strong>STARS</strong>',
            waiting: 'are waiting for you'
          },
          btn_discover: 'DISCOVER THEM',
          btn_view: 'TAKE A LOOK',
          cards: {
            fansite: {
              title: '<strong>FANSITE</strong>',
              desc:
                'Find everything related to your favorite <strong>STARS </strong>, exclusive content just for fans, paid content, their most intimate clothes and products specially designed by the STARS <strong>for you </strong>'
            },
            medialib: {
              title: '<strong>MEDIATECA</strong>',
              desc:
                'Are you looking for specific type of content? you\'re sure to find something you like, from your <strong>favorite creators</strong> or your <strong>favorites themes </strong>'
            },
            market: {
              title: '<strong>MARKET</strong>',
              desc:
                'Can you imagine being able to buy the garment that your favorite <strong>STAR</strong> was wearing in that scene? You can <strong>bid on it</strong> or even ask your STAR directly for personalized content, showing it off. Everything is possible at <strong>@:project_upper! </strong>'
            },
            custom: {
              title: '<strong>CUSTOM CONTENT</strong>',
              desc:
                'Do you have a specific <strong>fantasy</strong> that your favorite <strong>STAR</strong> can fulfil? Why don\'t you contact her <strong>directly</strong> and propose it to her?'
            },
            access:
              'If you want you can enter without registering to take a look at the platform and discover the contents that the <strong>STAR</strong> offer in "FREE ACCESS"',
            try_btn: 'TRY IT'
          }
        }
      }
    },
    third: {
      star_tab: {
        question: 'WHY',
        loverfans: '@:project_upper',
        cards: {
          transparency: {
            title: '<strong>TRANSPARENCY</strong>',
            desc:
              'From the first day, <strong>we comply with all the existing regulations</strong> at the level of <strong>tax rates</strong>, with <strong>@:project</strong> you will not have surprises. Our goal <strong>transparency</strong> with our STARS'
          },
          security: {
            title: '<strong>SECURITY</strong>',
            desc:
              'All payments are certified with <strong>3D Secure</strong>, we assure you that <strong>all your transactions are real</strong> and are guaranteed by the <strong>@:project_upper</strong> guarantee fund'
          },
          attention: {
            title: '<strong>PERSONAL ATTENTION</strong>',
            desc:
              'We have an <strong>Account Manager</strong> for each <strong>STAR</strong>, to provide a <strong>personal service</strong>. Likewise, we offer you different forms of <strong>international payment: Paypal, Western Union, Cosmopayments..</strong>'
          },
          followers: 'FOLLOWERS'
        }
      },
      fan_tab: {
        question: 'WHAT ARE IN',
        loverfans: '@:project_upper',
        cards: {
          photo: {
            title: '<strong>PHOTOS /VIDEOS</strong>',
            desc:
              'Access to a  <strong>huge collection</strong>, for the enjoyment of all your senses'
          },
          lives: {
            title: '<strong>LIVES</strong>',
            desc:
              '<strong>Join</strong> to the <strong>hottest</strong> lives broadcast'
          },
          message: {
            title: '<strong>MESSAGING</strong>',
            desc:
              'Direct <strong>Chat</strong> with your <strong>favourite Star!</strong>'
          },
          shop: {
            title: '<strong>SHOPS AND BIDS</strong>',
            desc:
              '<strong>Buy or bid</strong> for <strong>physical or digital content</strong>'
          },
          transparency: {
            title: '<strong>SEEKER</strong>',
            desc: {
              _1:
                'Contextual search engine allows you to search the <strong>STAR profile</strong> and <strong>all its content </strong>',
              _2:
                'Use the <strong>preferences</strong> in STARS and always be on the lookout for <strong>more than 100 new additions a week</strong> of your <strong>favorite STARS. </strong>'
            }
          },
          security: {
            title: '<strong>SECURITY</strong>',
            desc:
              'In <strong>@:project_upper</strong> Every single pay are done with <strong>3D secure</strong>, we guarantee not just the <strong>security</strong> but also the <strong>privacy</strong>, @:project <strong>will never access to your credit card datas</strong>, we\'ll use the most secure <strong>payment gateways</strong>'
          },
          contact: {
            title: '<strong>CONTACT</strong>',
            desc:
              '<strong>Contact</strong> with your <strong>STARS</strong> directly through <strong>messaging</strong> keep them informed of your preferences. And if you have questions or queries about our service, do it with <strong>our Customer Service </strong>, in the <strong>contact form </strong>. We\'ll give you personalized attention so that you can enjoy every minute you are connected to <strong>@:project_upper </strong>'
          },
          card: {
            desc:
              'We integrate the <strong>safest and most modern payment platforms</strong>, to give you the solution you need'
          },
          content: {
            desc:
              'We serve the original <strong>raw</strong> content to your device, in <strong>the most agile way</strong> so you can see the <strong>content exactly</strong> as your favorite <strong>STAR created it </strong>'
          },
          followers: 'FOLLOWERS'
        }
      }
    },
    fourth: {
      star_tab: {
        title: '<strong>@:project_upper\' METHOD</strong>',
        desc:
          'Discover the way to get more from your business, within adult leisure, using all the tools provided by the <strong>@:project_upper</strong> platform',
        btn: '<strong>START NOW</strong>'
      },
      fan_tab: {
        cards: {
          publications: 'MULTIMEDIA POSTS',
          products: 'PREMIUM CONTENT'
        }
      }
    }
  },

  actions: {
    actions: 'Actions',
    add: 'Add',
    add_more: 'Add more',
    already_subscribed: 'Subscribed',
    back: 'Back',
    configure: 'Setting',
    ok: 'Accept',
    ok_all: 'Accept All',
    ok_selected: 'Accept Selected',
    cancel: 'Cancel',
    cancel_subscription: 'Cancel Subscription',
    edit: 'Edit',
    continue: 'Continue',
    save: 'Save',
    update: 'Update',
    send: 'Send',
    pay: 'Pay',
    see: 'Show',
    show: 'Show',
    see_all: 'Show all',
    see_more: 'See more',
    more_info: '+Info',
    see_rank: 'See ranking',
    show_all: 'Show all',
    follow: 'Follow for free',
    subscribe: 'Subscribe',
    signup: 'Free Sign up',
    free_access: 'Access w/o Registration',
    search: 'Search',
    search_users: 'Search Users',
    search_lists: 'Search Lists',
    login: 'Log in',
    logout: 'Logout',
    publish: 'Publish',
    enter: 'Enter',
    exit: 'Exit',
    request_email: 'Request email',
    copy_link: 'Copy link',
    copy_link_referral: 'Copy referral link',
    report: 'Report',
    close: 'Close',
    buy: 'Buy',
    show_details: 'Show details',
    show_edit: 'Show or edit',
    delete: 'Delete',
    filter: 'Filter',
    upload: 'Upload',
    upload_image: 'Upload image',
    next: 'Next',
    previous: 'Previous',
    unfollow: 'Unfollow',
    reload: 'Reload',
    reload_wallet: 'Reload wallet',
    withdraw: 'Withdraw',
    request_witdraw: 'Request Withdrawal',
    other: 'Other',
    send_tip: 'Send tip',
    load_funds: 'Load Funds',
    upload_new: 'Upload new',
    upload_album: 'From Album',
    upload_vault: 'From Vault',
    vault: 'From Vault',
    private: 'Videocall',
    remove_micro: 'Remove micro',
    remove_cam: 'Remove camera',
    call: 'Call',
    calling: 'Calling',
    hanging: 'Hanging',
    hang: 'Hang',
    tip: 'Tip',
    like: 'Love',
    comment: 'Comment',
    repost: 'Repost',
    favorite: 'Favorite',
    share: 'Share in media',
    finish: 'Finish',
    show_item: 'Show {item}',
    show_post: 'Show post',
    show_product: 'Show product',
    go_to_item: 'Go to {item}',
    connect: 'Connect',
    disconnect: 'Disconnect',
    bid: 'Bid',
    bid_again: 'Bid again',
    twitter_login: 'Sign up/Login with Twitter',
    discover_them: 'Discover them',
    more: 'More',
    bring_me_there: 'Bring me',
    block: 'Block',
    unblock: 'Unblock',
    show_more: 'Show more...',
    reject: 'Reject',
    commit: 'Commited',
    chose: 'Chose',
    order_by: 'Order by',
    join: 'Join',
    start: 'Start',
    retry: 'Retry',
    change: 'Change',
    placeholder_select_file: 'Choose or drop a File',
    download: 'Download',
    downloadQR: 'Download QR',
    rotate_left: 'Rotate 90º to Left',
    rotate_right: 'Rotate 90º to Right',
    flipX: 'Flip horizontally',
    flipY: 'Flip Vertically',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    fly: 'Change fly position',
    record: 'Record',
    stop: 'Stop',
    fullVideo: 'SHOW FULL VIDEO',
    step: 'Go to step {count}',
    end: 'Finish',
    move: 'Move',
    reset_changes: 'Reset changes',
    set_price: 'Set price',
    attach: 'Attach file',
    buy_for: 'FOR: {price}',
    buy_for_multiple: '{number} FOR: {price}',
    translate: 'Translate',
    nottranslate: 'Don\'t Translate',
    activesFrom: 'Active from',
    online: 'Online',
    addFollowers: 'Add Followers',
    sendMultipleMessage: 'Send broadcast Message',
    criteria: 'Strict Criteria',
    status: 'Status',
    sendDate: 'Send',
    name: 'Name',
    description: 'Description',
    amount: 'Amount',
    requiredAmount: 'Required Amount',
    startDate: 'Start Date',
    code: 'Code',
    expirationDate: 'Expiration Date',
    still_uploading: 'Loading video...',
    sign: 'Sign',
    signed: 'Signed'
  },

  application: {
    title: '@:project',
    company_name: 'Artemisa 3000 Tech Solutions S.L.',
    company_cif: 'CIF B - 8 8 4 6 6 7 4 3',
    company_address: 'CALLE GOYA, NUM 47 PLANTA 7, PUERTA 12 28001 MADRID',
    company_city: 'Madrid',
    company_country: 'Spain',
    contact: 'Contact',
    imprint: 'Imprint',
    payments_imprint: 'Payments Imprint',
    contact_email: '@:contact_email',
    contact_number: '+34 654987321',
    under_construction: 'Under manteinance',
    under_construction_description:
      'We are working to improve the website and it will be available very soon',
    not_found: 'We couldn\'t find what you are looking for',
    not_found_description:
      'We are sorry to tell you that we couldn\'t find what you were looking for.',
    commercegate_1: 'Payment Facilitator and ',
    commercegate_2: 'Billing Support',
    files_max_size: 'Max file size is 6GB',
    vat: 'VAT',
    total: 'Total',
    fee: 'Fee',
    invoice_fee: 'Fees',
    invoice_tax: 'Tax Withholding',
    artemisa: {
      title: 'Artemisa',
      name: 'Artemisa 3000 Tech Solutions S.L.',
      cif: 'CIF B - 8 8 4 6 6 7 4 3',
      address: 'CALLE GOYA, NUM 47 PLANTA 7, PUERTA 12 28001 MADRID',
      city: 'Madrid',
      country: 'Spain'
    },
    payout_agency: {
      title: 'Sioux Media',
      name: 'Sioux Media Group LTD',
      cif: 'CY 10390284C',
      address: 'Onisilou str. Vera Court 1 Block A Flat 2',
      city: 'Ayios Tychonas 4532',
      country: 'Cyprus'
    },
    maintance: {
      warning_1: '@:project is currentyly down for maintance. ',
      warning_2:
        'We are doing some scheduled maintance. We are improving some sections and our servers for giving you a better experience',
      warning_3:
        'We\'re sorry for the inconvenience. Please check back in 30 minutes.'
    },
    contract: {
      warningSing: 'Verify all the information before signing the Creators Contract.',
      textModals: 'We remind you that you have pending the sign of the updated Content Creator Agreement. It is necessary to sing it in order to be able to request withdrawals from your wallet.',
      signContractWallet: 'You have to sign the new creators\' contract. You must sign it to be able to request withdrawals.',
      btnClose: 'SIGN AT ANOTHER TIME',
      btnSign: 'SIGN UPDATED CONTRACT',
      check: 'I accept the terms set forth in this contract.',
      title: "Creators' Contract",
      text_cretors: 'In order to continue using your creator profile on @:project  you must sign our creator contract. Please make sure that your personal information below is correct before signing.',
      download_text_creators: 'You have correctly signed the creators contract dated {date}. If your situation has changed and you need to modify your details, please contact us through our Contact page. You can download a copy of the contract using the Download button.'
    },
    menu: {
      home: 'Home',
      lives: 'Lives',
      post_promo: 'Promo',
      stories: 'Stories',
      statistics: 'Statistics',
      notifications: 'Notifications',
      categories: 'Categorías',
      influencers: 'Creators',
      favorites: 'Favorites',
      videotube: 'Medialib',
      market: 'Shop',
      products: 'Products',
      settings: 'Settings',
      wallet: 'Wallet',
      referrals: 'Referrals',
      chat: 'Chat',
      resources: 'Resources',
      messages: 'Messages',
      users: 'Users',
      posts: 'Posts',
      admin: 'Admin',
      billing: 'Monetization',
      xsale: 'Xsale',
      wallet_transfer: 'Wallet Transfer',
      addFollowers: 'Add Followers',
      faq: 'FAQ',
      tutorial: 'Tutorial',
      contact: 'Contact',
      complaints: 'Complaints',
      bonus: 'Bonus',
      language: 'Language',
      logout: 'Log out',
      terms_conditions: 'Terms and Conditions',
      contract_conditions: 'Contract Conditions',
      privacy: 'Privacy',
      cookies: 'Cookies',
      light_theme: 'Change to light theme',
      imprint: 'Imprint',
      dark_theme: 'Change to dark theme',
      validation: 'Validation',
      agents: 'Agents',
      my_panel: 'My Panel',
      theme: 'Theme',
      lang: {
        title: 'Language',
        dialog: 'Select a Language'
      },
      legal: {
        title: 'Legal',
        dialog: 'Legal Information',
        legal_warning: 'Legal Advice',
        conditions: 'Terms and Conditions',
        privacy: 'Privacy Policy',
        cookies: 'Cookies Management',
        usc: 'USC 2257',
        dmca: 'DMCA Policy and Notice and Takedown Procedures',
        complaints: 'Complaints and content removal'
      },
      become_creator: 'Become a Creator',
      subscriptions: 'My Subscriptions',
      more: 'More',
      development: 'In Development',
      marketing: 'I agree to receive commercial communications from entities that collaborate with @:project',
      management: '@:project will process the data provided in order to manage the registration and allow the management of your profile, as well as to send its own commercial communications, and, if you so indicate, third parties. You can exercise your data protection rights and obtain more information <a href=\'/privacy\'>here</a>. In the event that you register with any external platform, your manager will provide us with the data for registration.'
    },
    contact_marketing: {
      management: '@:project will process the personal data provided through this form in order to respond to your requests, inquiries, or petitions. You can exercise your data protection rights and obtain more information <a href=\'/privacy\'>here</a>.'
    },
    preferences: {
      management: 'By selecting any of these options and pressing the button, you consent to @:project processing this information in order to show you content that aligns with your preferences. If you prefer not to select any of the options, click the "X" in the upper right corner. You can exercise your data protection rights and obtain more information <a href="/privacy">here</a>.'
    },
    chat_setting: {
      user_setting: 'User Settings',
      user_profile: 'View Profile',
      delete: 'Delete Conversation',
      delete_btn: 'Delete',
      delete_text:
        'All your messages will be deleted from this conversation. Messages from {user} and media you have purchased will not be deleted. Are you sure?',
      mute: 'Mute',
      mute_btn: 'Mute',
      mute_text: 'Do you want to mute {user}?',
      unmute: 'Unmute',
      unmute_btn: 'Unmute',
      unmute_text: 'Do you want to unmute {user}?',
      default_price: 'Default price',
      default_price_sexting: 'Sexting (Price per Instant Message)',
      default_price_media: 'Media (Price per file sent/received)',
      default_price_video: 'Video (Price per minute)',
      setting: {
        title: 'Settings',
        subtittle: 'Allow DM from',
        all: 'All',
        followers: 'Followers',
        fans: 'Fans',
        followers_paid: 'Followers (Only Paid)',
        fans_paid: 'Fans (Only Paid)',
        followed: 'Followed by me',
        save: {
          subtittle: 'Changes will apply to',
          option_1: 'Apply to <b>ALL</b> conversations',
          option_2: 'Apply only to <b>NEW</b> conversations'
        }
      },
      schedule: {
        title: 'Schedule Messages',
        new_follower: 'Message to new Follower',
        new_fan: 'Message to new Fan',
        new_tip: 'Message to new Tip',
        new_content: 'Message to new content purchased'
      },
      userList: {
        title: 'User lists',
        title_1: 'Create List',
        title_2: 'View List',
        title_3: 'Edit List',
        deleteWarning: 'Do you want to delete your list of users {nameList}?',
        row: {
          name: 'Name:',
          createAt: 'Create At:',
          countUser: 'Users:'
        }
      },
      vault: {
        title: 'File vault',
        title_1: 'Video',
        title_2: 'Image',
        title_3: 'Audio',
        delete_warning: 'Are you sure you want to delete the file?'
      },
      albums: {
        title: 'Content albums',
        title_1: 'Create Album',
        title_2: 'View Albums',
        title_3: 'Edit Album',
        deleteWarning: 'Do you want to delete your album {nameList}?',
        row: {
          name: 'Name:',
          createAt: 'Create At:',
          attach: 'Items:'
        }
      },
      general: {
        title: 'General Settings',
        lang_select: {
          title: 'Chat Language',
          tooltip:
            'Select the language you want to translate your conversations',
          tooltip_2: 'AutoTranslate to'
        },
        delete_chats: {
          title: 'Delete all chats',
          tooltip: 'Delete all your open conversations',
          btn: 'DELETE ALL'
        },
        videochat: {
          title: 'Enable/Disable Videochat calls',
          tooltip: 'Enable or disable incoming videochats',
          btn_enable: 'DISABLED',
          btn_disable: 'ENABLED'
        }
      }
    }
  },
  share: {
    profile: 'Share profile',
    title: 'Share on...',
    via_app: 'Via application'
  },

  cancel_subscription: {
    title: 'Confirm Unsubscription',
    info:
      'Do you really want to unsubscribe from <strong>{username}</strong>?<br>The next time you want to register as <strong>a {username}\'s subscriber</strong> it\'s possible that the current amount <strong>won\'t be maintained and will be updated</strong> according to the prices set by {username}.',
    note_1:
      '* You will continue to have access to your Followers content until the end of the subscription.',
    note_2:
      '* You will continue to have access to the premium and personalized content purchased for you'
  },

  login: {
    title: 'Acces',
    description: 'Love is in the fans',
    description_2: 'Your fans, their <strong>fantasies</strong>',
    description_3: 'Your <strong>business</strong>, your rules',
    login_field: 'User or email',
    password_field: 'Password',
    remember_password: 'Forgot password?',
    advice_1: 'Find out what\'s going on in internet',
    advice_2: 'Follow your favorites Stars',
    advice_3: 'Become an Star',
    or_you_can: 'or you can register'
  },

  signup: {
    already_registered: 'Already a member?',
    not_member: 'Don\'t have an account yet?',
    secure_net: 'Secure connection',
    form: {
      title: 'Personal data',
      description: 'Sign up to start using Lovefans'
    },
    promotion_check: {
      title: 'Do you want to become an STAR?',
      description:
        'Being an STAR will open a new world of possibilities and you will be able to earn money while enjoying @:project!',
      description_1: 'Upload Premium content only for your best FANS',
      description_2: 'Sell products or create Auctions',
      description_3: 'Earn money interacting with your fans',
      next: 'Yes, become an STAR',
      skip: 'No thanks. continue with signup'
    },
    social_media: {
      title: 'Connect with your Social Media',
      description:
        'You will be able to share content via your Social Media Accounts'
    },
    personal_data: 'Personal Data and Price'
  },

  email_validation: {
    title: 'Email verification is required!',
    description:
      'The email must be verified to continue using @:project. To verify the email you must follow the verification link in the welcome email sent to you.',
    description_2:
      'If you have not received the email, please check your spam or bulk email folder or request another email click the button.',
    validating: 'Verifying email. Please wait...',
    validated: 'Your email is verified. Enjoy @:project!',
    validation_error:
      'There was an error verifying your email. Your can request another email with a new link clicking the button. This email will have a new link to verify your email.'
  },

  remember_password: {
    title: 'Find your @:project\' Account',
    hint: 'We will send you and email to change your password',
    instructions:
      'Enter your email to request a new password. We will send you an email with the instructions to change the password.',
    email_sent:
      'An email has been sent with the new password instructions. Follow the link in there to change the password.',
    email_sent_2:
      'If you cannot find out the email, please check your spam or bulk email folder, social network emails or others.'
  },

  change_password: {
    title: 'New password',
    success_message:
      'Password changed successfully! Enter in @:project with your new credentials:',
    error_message:
      'Ops! There was an error changing the password. Your can request another password change and a new email will be sent to your account.'
  },

  admin: {
    wallet: {
      wallet_transfer: 'Wallet Transfer',
      modify_wallet: 'Modify Wallet'
    },
    profile: 'See Profile',
    optional: {
      displayName: '(Optional) Display name instead of your username.'
    },
    users: {
      actions: 'Selected actions',
      role_filter: 'Role: all',
      check_pending: 'Pending promotions',
      promotion_status: 'Creator request',
      scat_users: 'Scat Users (All)',
      only_scat: 'Only Scat profiles',
      only_premium: 'Only Premium profiles',
      username_email: 'Username or email',
      country_city: 'Country or city',
      promotion: {
        review: 'Check',
        document_info: 'ID Document',
        photo_verification: 'Verification pics',
        verify_document: 'Accept pic',
        detail: 'Comment',
        reject_detail: 'Why is rejected?',
        reject_detail_mandatory:
          'This field is mandatory if pictures are not accepted',
        reject_detail_placeholder:
          'ie. the pictures are not visibles or the info does not match',
        statuses: {
          pending: 'Pending',
          rejected: 'Rejected',
          standby: 'Stand By',
          auto_pending: 'Ongoing',
          processing: 'Ongoing',
          approved: 'Approved',
          all: 'All',
          scat: 'SCAT Users',
          no_scat: 'NO SCAT Users'
        }
      }
    }
  },

  favorites: {
    purchased_posts: 'Purchased publications',
    favorites_posts: 'Saved publications',
    no_favorites: 'There are not saved content yet.',
    no_purchased: 'There are not purchased content yet'
  },

  videotube: {
    title: 'Premium posts',
    recent: 'Recently added',
    trending: 'Trending',
    preferences: 'Preferences',
    by_followings: 'Last added by your interest',
    tutorials: {
      chat: 'Messages',
      lives: 'Lives',
      referrals: 'Referrals'
    },
    filter: {
      creator: 'Profile',
      categories: 'Categories',
      countries: 'Countries'
    }
  },

  market: {
    sales: 'Sales',
    auctions: 'Auctions',
    recent: 'Recently added',
    sections: {}
  },

  publication: {
    create_post: 'Make your first post!',
    select_type: 'What do you wanna publish?',
    type_video: 'Video',
    type_image: 'Image',
    type_post: 'Post',
    type_product: 'Product',
    type_conference: 'Live',
    type_promo: 'Story',
    new_image_post: 'New image post',
    new_video_post: 'New video post',
    new_product_post: 'New product',
    new_conference_post: 'New Live',
    promo: 'Monthly challenge',
    promotional_1: 'NEW PROMOTION FOLLOW THE RABBIT!',
    promotional_2: 'UPLOAD YOUR STORIES AND VIDEOS TO JOIN OUR "FOLLOW THE RABBIT" EASTER CONTEST!',
    promotional_3: 'ACTIVE PROMOS!',
    promotional_4: 'UPLOAD YOUR STORIES AND VIDEOS TO JOIN TO THE PROMOS!',
    more_info: 'ALL INFO HERE'
  },

  posts: {
    recommended: 'Recommended content',
    programmed: 'Programmed for: ',
    search: 'Posts containing \'{query}\'',
    share_text: 'Hey! Check this publication',
    reposted_by: '{name} has reposted',
    draft_hint:
      'We are optimizing this post for a better user experience. It will be available in a moment',
    draft_hint_2:
      'You may leave this window and keep using @:project if you wish.',
    draft_continue: 'Continue to @:project',
    draft_hint_3: 'ALL GOOD!',
    draft_hint_4: 'YOUR POST IS BEING CHECKED',
    draft_hint_5: 'PROCESSING TIME DEPENDS ON YOUR FILE\'S SIZE AND IT WILL NEVER TAKE LONGER THAN AN HOUR',
    card_visibility: {
      open: 'Public',
      premium: 'Premium: {amount}',
      followers: 'My fans',
      exclusive: 'Exclusive'
    },
    card_overlay: {
      buy_action: 'Buy for {price}',
      special_buy_action: 'Buy for',
      subscribe_action: 'Subscribe',
      open_description: 'Signup for free to view this post',
      premium_description: 'to view this post',
      followers_description: 'to view this STAR\'s content',
      exclusive_description: 'to view this exclusive content for you',
      private_description: 'This post is private',
      followers_text_1: 'Full video for Fans',
      followers_text_2:
        'Be my Fan and gain access to all my videos and contact with me for only',
      premium_text_1: 'Buy this video for only',
      signup_text_1: 'Free signup {time}',
      signup_text_2: 'And watch full video in HD',
      free_signup_text_1: '{free} signup in less than 1 minute',
      free_signup_text_2: '!Watch all videos from @{username} and talk to her',
      signup_time: 'in less than 1 minute',
      free: 'free'
    },
    form: {
      title: 'Title',
      type: 'Premium',
      price: 'Price',
      content: 'Description',
      upload_file_text: 'Select or drop some files',
      visibility_message: 'Visibility',
      all_fans: 'My fans',
      all_followers: 'My followers',
      type_description: {
        open: 'Any Loverfan member can watch this post',
        followers: 'Only your subscribers can watch this post',
        premium: 'Only members who acquired this post can watch it',
        exclusive: 'Only the chosen member can acquire this post'
      },
      teaser: 'Add teaser',
      edit_teaser: 'Edit teaser',
      teaser_hint:
        '(Optional) Teaser to show as a preview for paid/fans videos. If not teaser is added then 3 seconds for this video will be chose.',
      edit_teaser_hint:
        '(Optional) You can change the teaser of this publication. If you dont do anything, previous teaser will be kept.'
    },
    edit: {
      title: 'Edit post',
      visibility_hint: 'Only public or fans posts can change their visibility'
    },
    mentions: {
      text: 'Other creator on @:project',
      other_text: 'Other creator without @:project',
      other_people: 'Are there more people on your post?'
    },
    participants: {
      text: 'Creators',
      agreement_uploaded: 'Agreement uploaded',
      current_participants_count:
        'Other creators without @:project | There is one character more | There are {count} characters more',
      download_dni: 'Descargar ID: Lado {index}',
      download_agreement: 'Descargar contrato'
    },
    approval: {
      post_pending_approval: 'New Post',
      product_pending_approval: 'New Products',
      post_revised_approval: 'Revised Posts',
      product_revised_approval: 'Revised Products',
      rejection: {
        reason: 'Reason',
        detail: 'Detail',
        reason_placeholder: 'Select reason',
        detail_placeholder: 'Write a comment',
        reasons: {
          document_missed: 'Incorrect or missing documents',
          not_allowed: 'Content not allowed',
          other: 'Other'
        },
        defaults: {
          document_missed:
            'You must upload all required documents for each character appearing on the video.',
          not_allowed: '',
          other: ''
        }
      },
      status: {
        approved: 'Approved',
        rejected: 'Rejected',
        pending: 'Approval pending',
        requested: 'Approval requested',
        whitelisted: 'Whitelisted',
        blocked: 'Blocked'
      },
      status_label: {
        approved: 'Online',
        whitelisted: 'Online',
        blocked: 'Rejected',
        rejected: 'Rejected',
        pending: 'Pending',
        requested: 'Pending'
      }
    }
  },

  products: {
    description: 'Description',
    update_delivery_info: 'Add delivery info',
    copmlete_delivery_info: 'Complete info',
    guarantee_detail: 'Guarantee detail',
    no_more_guarantee: 'There are no additional guarantees in this product.',
    selling_engagement: 'Do you want to sell a product?',
    current_bidder_user: 'by @{username}',
    auction_winner_info: 'You have won this auction',
    shipping_costs: 'Shipping costs',
    shipping_cost_hint: 'XXX,YY (this amount will be added to product\'s price)',
    purchases: 'Purchases',
    sales: 'Sales',
    forbidden_country:
      'Delivery for this article is not allowed on your country. Please, contact directly with the vendor via PM.',
    edit: {
      title: 'Edit product',
      auction_no_editable: 'Cannot be edited because this auction has bids'
    },
    buy_product: {
      sale: 'Buy',
      auction: 'Bid',
      shipping_cost_description: 'Plus {price} shipping costs'
    },
    auction: {
      bid_title: 'Bid for {name}',
      accept_terms: 'I accept auction terms',
      already_reserved_amount:
        'You have bidded for this product before and the initial {amount} are already reserved',
      not_enough_amount: 'The amount must be greater than current bid',
      finished: 'This auction has finished',
      finished_auction: 'Auction finished',
      current_bid: 'Current bid:',
      winner_bid: 'Winner bid:',
      bid_count: 'no bids | one bid | {count} bids',
      duration_hint: 'Days to finish',
      no_winner: 'No one has bid',
      current_bidder: 'You are winning this auction',
      bid_description: {
        desc_1:
          'To bid on this auction you need a minimum amount of {amount} on your wallet.',
        desc_2:
          'This amount will be retained as a deposit to participate on this auction and it will be returned in case you don\'t win.',
        desc_3:
          'The winner will have 5 days to pay for the item. If it is not paid between these days, the retained amount won\'t be returned.',
        show_all_terms_conditions:
          'You can read the complete conditions for auctions {link}',
        show_terms_conditions: 'Show auctions conditions',
        link_text: 'CLICKING HERE'
      },
      terms: {
        title: 'HOW TO BID AT AN AUCTION',
        point_1: 'You must have a minimum of {amount} in your user Wallet.',
        point_2:
          'This amount ({amount}) will be retained as a deposit to guarantee the veracity of the bid.',
        point_3:
          'In the event that your bid is not among the three highest, the money will be returned to your user Wallet. In no case will the money be reimbursed to the user directly in their bank account / credit card.',
        point_4:
          'Once the auction is concluded, the winner has 5 days to pay the total amount of the item. After this period, the right to acquire the product as well as the money deposited as a guarantee will be lost to the offerer for the damage caused.',
        point_5:
          'At the moment in which the payment of the item is made effective by the winner of the bid, the amounts withheld as a guarantee will be released to the bidders who were in the second and third positions.',
        point_6:
          'If after the mentioned period of 5 calendar days the highest bid has not made the purchase effective, the user who was in the second position will be awarded as the winner and will acquire the same rights, obligations, and penalties previously described.',
        point_7:
          'If the latter does not make the purchase effective, the third-highest bid will be considered the winner. The winner acquires the same rights, obligations, and penalties previously described.',
        point_8:
          'In the event that none of the three highest bids complete the purchase of the item, the auction will be canceled and there won\'t be a winner.'
      }
    },
    sale: {
      acquired: 'This product has already been sold',
      user_acquired: 'You have purchased this product',
      ref_number: 'Tracking number: {ref}'
    },
    delivery: {
      detail: 'Delivery detail',
      sent: 'This product has already been sent',
      not_sent: 'The product has not been shipped yet',
      not_completed: 'Delivery details needed',
      mark_sent: 'Mark as sent',
      description: {
        digital:
          'Digital content. We will send you an email with instructions to access this content.',
        packet: 'Physical delivery, this STAR will send you a package.',
        free_shipping: 'Free delivery'
      },
      shipment: {
        title: '{name} has acquired this product.',
        sent_to: 'Delivery details',
        email: 'Email: {email}',
        name: 'Name: {name}',
        country: 'Country: {country}',
        province: 'Province: {province}',
        city: 'City: {city}',
        postalCode: 'Postal/ZIP Code: {postalCode}',
        address: 'Address: {address}',
        sent: 'Already sent'
      }
    },
    pending: {
      title: 'PENDING VALIDATE'
    },
    validate: {
      title: 'REVIEWED'
    }
  },

  statistics: {
    nodata: 'Not enough data',
    stats: {
      creator: 'Top Creator',
      earner: 'Top Earner',
      loved: 'Top Loved',
      followed: 'Top Followed'
    },
    ranking: {
      title: 'Funds and actions',
      position: 'You\'re at',
      top: 'not enough data | top {position} % | top {position} %',
      users: {
        title: 'User Ranking',
        user: 'User',
        views: 'Views',
        tips: 'Tips',
        subscriber: 'Subscriber'
      },
      posts: {
        title: 'My Post Ranking',
        tabTitle: 'Title',
        date: 'Date',
        views: 'Views',
        likes: 'Likes',
        repost: 'Repost',
        content: 'Content',
        type: 'Type',
        visibility: 'Visibility',
        deposit: 'Deposit'
      }
    },
    help: {
      creator: 'Ranking of those who publish the most content',
      earner: 'Ranking of those who convert with their users',
      loved: 'Ranking of the most loved',
      followed: 'Ranking of the most followed'
    }
  },

  comments: {
    title: 'Comments',
    reviews: 'Reviews',
    review_placeholder: 'Leave a review...'
  },

  agents: {
    title: 'Creators',
    partners: 'Partners',
    monetization: 'Monetization',
    delete_agent_question: 'Do you really want to remove {username} as an Agent?',
    delete_partner_question: 'Do you really want to remove @{partner} from the Agent @{agent}?',
    agentPayout: 'Agent Payout'
  },

  conferences: {
    total: 'Total',
    private_hint: 'This Live is private and you cannot watch it.',
    exclusive_hint:
      'This Live is private for you. Price is {amount}/min and will be charged only active minutes',
    public_hint: 'This Live is public and any user can join.',
    premium_hint:
      'Live\'s price is {amount}/min and it will be charged only when the Star is online.',
    video_check: 'Checking camera availability',
    video_success: 'Your camera is available.',
    video_error:
      'We couldn\'t connect with the camera. Keep it in mind if you are going Live with this device.',
    video_error_hint:
      'We couldn\'t connect with the camera. If you denied camera access then you need to reload the page or change the page settings to allow us accessing the camera.',
    no_webcam_access:
      'We couldn\'t connect with the camera. If you are on iPhone or iPad, you will need to use Safari since no other browers is allowed to use the camera.',
    connection_error:
      'There was a problem connecting this Live. Please try again.',
    waiting_star:
      'The Star has not connected yet. The session will start as soon as she connects. You will not be charged until then.',
    finished_message: 'This Live is already finished',
    tip_given: '@{user} sent you {amount} tip',
    free_fans: '{username}\'s subscribers can join for free',
    news_message:
      'We just published Lives. It is at Beta stage and for this period, no fees will be applied to Lives charges',
    minute_price_label: '{amount}/min',
    change_conference: 'Change prefs',
    new_conference: {
      title: 'This Star has started other Live',
      description:
        'This Live has finished because of the Star has started a new one with different conditions.'
    },
    form: {
      already_created:
        'You already have one Live online. If you publish a new one, previous Live will be closed'
    },
    empty_wallet: {
      title: 'Not enough funds',
      description:
        'You doen\'t have enough funds. You can reload your wallet here to join this Star\'s live.'
    }
  },

  users: {
    not_found: 'This profile does not exist',
    blocked_description:
      'This profile has been blocked for violating @:project T&C',
    search_user: 'Search user...',
    search: 'Search Stars with \'{query}\'',
    published: 'Publications',
    following: 'Following',
    subscribed: 'Subscribed',
    subscribe: '{price}/month',
    content_participants: 'People appearing on my content',
    featured_users: 'People I want to promote on my profile',
    profile: {
      edit: 'Edit profile',
      upload_banner: 'New banner',
      upload_avatar: 'New avatar',
      starMode: 'Star View',
      fanMode: 'Fan View',
      scatbook: 'Check out my Scatbook profile',
      tabs: {
        timeline: 'Timeline',
        mosaic: 'Mosaic',
        photo: 'Photo',
        video: 'Video',
        content: {
          open: {
            title: 'FREE: {post}',
            tooltip: '{post} Free Content'
          },
          followers: {
            title: 'FANS: {post}',
            tooltip: '{post} Followers\' content'
          },
          premium: {
            title: 'PREMIUM: {post}',
            tooltip: '{post} Pay content'
          }
        }
      },
      share: {
        share_twitter: 'Share in Twitter',
        share_facebook: 'Share in Facebook',
        share_instagram: 'Share in Instagram',
        share_whatsapp: 'Share in Whatsapp',
        share_gmail: 'Share in Gmail',
        copy_link: 'Copy link',
        text_send: 'Visit this profile of {plaform} at {url}'
      },
      info: {
        message: 'Private message',
        follow: 'Follow for free',
        copy: 'Copy link to this profile'
      }
    },
    discovery: {
      empty_title: 'There are a lot of Stars waiting for you!',
      title: 'Many more Stars are waiting for you!',
      empty_description:
        'You are not seeing any content from your favorites Stars yet. Let us help you to discover some Stars for you to love',
      description:
        'Wanna see more? Let us help you to find out more Stars for you to love'
    }
  },

  featured_users: {
    title: 'People I want to promote on my profile',
    no_users: 'You haven\'t selected any user yet'
  },

  blocked_users: {
    title: 'Blocked users',
    description:
      'These users cannot see your profile, your content or lives neither they wont be able to chat with you.',
    no_users: 'You haven\'t blocked any user yet',
    remaining_blocked_count: 'and {count} more',
    block_user: 'Block user',
    dialog: {
      question: 'Do you really want to block @{username}?',
      description:
        'If you do so, he/she wont have access* to any of your content**, you wont be able to chat with you through @:project***.',
      description_1:
        '*Except subscribers. The access will be blocked at the moment subscription ends',
      description_2:
        '**User will still have access to the content he/she has purchased',
      description_3: '***Henceforth, this user wont be able to chat with you',
      blocked: 'The user {username} has been blocked the communication.'
    }
  },

  subscriptions: {
    canceled: 'Canceled',
    subscribed: 'Subscribed',
    subscription_config: 'Configure subscriptions prices',
    discount: 'Discount',
    monthly_price: 'Monthly price',
    offer_packages: 'Offers',
    offer_packages_hint: 'Discount must be in range 1-70%',
    vat_explain:
      'plus {price} (including VAT and Bank Expenses, marketbilling.net+34656897133 , CGBILLINGCOM*Artemisa or СGBILLING*Artemisa will appear on your card statement)',
    vat_ca_explain:
      'plus {price} (including GST/HST and Bank Expenses, marketbilling.net, CGBILLINGCOM*Artemisa or СGBILLING*Artemisa will appear on your card statement)',
    charge_explain:
      'CGBILLINGCOM*Artemisa, marketbilling.net+34656897133  or СGBILLING*Artemisa will appear on your card statement',
    min_price_error: 'The total price cannot be lower than {price}',
    unique: 'PAYING {months} MONTH FOR {price}',
    uniques: 'PAYING {months} MONTHS FOR {price}',
    form: {
      title: 'Subscription',
      description: 'This is the modal with the payment form',
      cancel_description:
        'We are going to cancel this subscription. Are your sure?'
    },
    discounts: {
      month: '1st month',
      trimester: '3 months',
      semester: '6 months',
      annual: '12 months',
      enabled_month: '1st month: {price}',
      enabled_trimester: '3 months: {price}',
      enabled_semester: '6 months: {price}',
      enabled_annual: '12 months: {price}',
      normal_monthly_price:
        'Subsription monthly price without discount would be {price}',
      hints: {
        month:
          'Monthly subscription discount. The first month the user pays discounted price, following months he pays the base price. This will only apply if the user has not been previously subscribed',
        trimester:
          'Discount for 3 months. Payments will be done every 3 months',
        semester: 'Discount for 6 months. Payments will be done every 6 months',
        annual: 'Discount for 12 months. Payments will be done every 12 months'
      }
    },
    descriptions: {
      month:
        '<strong>1 Month</strong> Subscription | First month | First month',
      trimester: '<strong>3 months</strong> Subscription',
      semester: '<strong>6 months</strong> Subscription',
      annual: '<strong>12 months</strong> Subscription',
      hints: {
        month: 'After first month, price will be {price}/month'
      },
      unique: '<br>One-time payment of <strong>{price}</strong>*',
      specialOffer: '<br>Special Offer, {discount}% off until {endDate}.<strong>{specialPrice}</strong>',
      offer:
        '<br><strong>{offer}%</strong> discount<br>After the first month <strong>{price}</strong>*'
    },
    descriptions_2: {
      make_me_fan: 'Subscribe as VIP Fan',
      monthly_payment:
        '{amount} per month  | {amount} per month  | {amount} per month ({months} months)',
      unique_payment: 'payment of {amount}'
    },
    beFan:
      'BE MY FAN AND GAIN ACCESS TO ALL MY VIDEOS AND DIRECT CHAT WITH ME FOR: '
  },

  avatar: {
    record_disabled:
      'Your device is incompatible with recording the video. We are improving this section to make it avaialbe to all devices. We are sorry for the inconveniencies.',
    beta: {
      photo: {
        message_1:
          'Welcome to the <strong> Beta </strong> of the <strong> Avatar </strong> of @:project "<strong> Lovely </strong>"',
        message_2:
          'You can <strong> customize </strong> and place it with the <strong> expression </strong> that you want, to <strong> create a banner </strong> with text, which allows you to invite your fans from other social networks to <strong> follow your profile </strong> of @:project',
        message_3:
          'We recommend that if you are going to use a <strong> mobile device </strong> you do it in <strong> vertical position </strong> for a better experience',
        message_4: 'Have fun!'
      },
      video: {
        message_1:
          'Welcome to the <strong> Beta </strong> of the <strong> Avatar </strong> of @:project "<strong> Lovely </strong>"',
        message_2:
          'You can <strong> personalize it </strong> and create a <strong> video with your voice </strong> to invite your fans from other social networks to <strong> follow your profile </strong> of @:project',
        message_3:
          'To use the <strong> video functionality </strong>, you must <strong> allow </strong> access to the camera and microphone of your device',
        message_4:
          '<strong> not wearing glasses </strong>, having a <strong> clear forehead </strong> and <strong> good lighting </strong> will help Lovely\'s Artificial Intelligence to better follow your expressions',
        message_5:
          'Likewise, if you are going to use a <strong> mobile device </strong>, we recommend that you do it in <strong> vertical position </strong> for a better experience.',
        message_6: 'Have fun!'
      },
      accept: 'Ok, I understand'
    },
    comment:
      'Hello, \nmy name is \n\'Lovely\',your \nVirtual Avatar\nYou can \ncustomize me!',
    content: 'What type of content do you want to generate with your avatar?',
    photo: 'Photo',
    photo_content:
      'Create your avatar, place it with the expression you want, write a personalized text and share it on your networks.',
    video: 'Video',
    video_content:
      'Create your avatar, and use your webcam, to animate it and record an audio with your voice. <br> Surprise your fans.',
    placeholder: 'Max 92 Characters',
    skintone: 'Skintone',
    eyeColour: 'Eye Colour',
    hair: 'Hair',
    hairColour: 'Hair Colour',
    sections: {
      mouth: {
        title: 'Mouth',
        smile: 'Smile',
        disgusted: 'Disgusted',
        open: 'Open',
        round: 'Round'
      },
      eyes: {
        title: 'Eyes/Eyebrows',
        right_eyebrow: 'Right Eyebrow',
        left_eyebrow: 'Left Eyebrow',
        right_eye: 'Right Eye',
        left_eye: 'Left Eye'
      },
      posrot: {
        title: 'Position/Rotation',
        position: 'Position',
        rotation: 'Rotation'
      }
    }
  },

  payments: {
    table_title: 'Pagos',
    email_verification_needed:
      'It is mandatory to have a verified email to purchase',
    go_to_settings: 'Go to account settings',
    paying: 'Making the payment. Please wait.',
    successful: 'Successful payment',
    failed: 'Error in payment',
    chose_payment_type: 'Chose the payment type',
    credit_card_disabled_temporary:
      'Credit card payments are temporary disabled.',
    credit_not_enought_amount:
      'The minimum amount to pay with Credit Card is {amount}',
    credit_not_enought_amount_min_max: 'Payment by card is minimum: {amountMin} and maximum: {amountMax} without fees',
    wallet_not_enought_amount: 'You don\'t have enought funds in your wallet',
    user_never_payed: 'Top up your wallet or subscribe to one of our creators to be able to use the chat or comment',
    info: {
      product_price: '{price} Product',
      post_price: '{price} Post',
      shipping_price: '{price} Shipping cost',
      vat_price:
        '{price} VAT and Ex. CGBILLINGCOM*Artemisa or СGBILLING*Artemisa will appear on your card statement',
      vat_ca_price:
        '{price} GST/HST and Ex. CGBILLINGCOM*Artemisa or СGBILLING*Artemisa will appear on your card statement',
      charge_explain:
        'CGBILLINGCOM*Artemisa or СGBILLING*Artemisa will appear on your card statement',
      reserved_amount: '-{amount} Reserved amount',
      total_price: '{price} Total'
    },
    include_vat:
      'Its free! | Buy for {price} | Buy for {price} (price + VAT and Ex.)',
    include_ca_vat:
      'Its free! | Buy for {price} | Buy for {price} (price + GST/HST and Ex.)',
    last_payments: 'Last payments',
    buyer_name: 'Billing name',
    detail: 'Details',
    title: {
      post: 'Acquire {username}\'s publication',
      subscription: '{username}\'s Subscription',
      product: 'Acquire {username}\'s product',
      wallet: 'Reload wallet'
    },
    cascade: {
      description:
        'There was an error with the payment and we have not charged your credit card for this purchase. Please, try again.'
    },
    posts: {
      engagment_1: 'Complete access to this post',
      engagment_2: 'Comment and chat',
      engagment_3: 'Save on your bookmarks',
      success_message_1:
        'Congratulations! The payment was successful and you have full access to post made by {username}.',
      success_message_2:
        'Remember you can comment and interact with this STAR aside from watch the publication. This content will be alwayas available at your profile or in your Favorites section.',
      failed_message:
        'We have not charged your credit card for this publication.'
    },
    products: {
      success_message_1:
        'Congratulations! The payment was successful and you have acquired the product {name} from {username}.',
      success_message_2:
        'We will notify you when {username} sends the product.',
      failed_message: 'We have not charged your credit card for this product.',
      error: {
        text: 'There has been an error with the payment.',
        text_2: 'We have not charged your wallet for it.',
        not_shippable:
          'This item cannot be shipped to your country. Please, contact with the vendor via PM if you want to buy it.',
        not_allowed: 'This item cannot be purchased at the moment.',
        mandatory_email: 'The email is need to continue with the process',
        mandatory_shipping_details:
          'This item require delivery info to continue with the process',
        not_enough_funds: 'You don\'t have enough funds on your wallet'
      }
    },
    subscriptions: {
      engagment_1: 'Access to membership content',
      engagment_2: 'Chatting with this user',
      engagment_3: 'Cancel the subscription whenever you want',
      success_message_1:
        'Congratulations! The payment was successful and you are subscribed to {username}\'s channel.',
      success_message_2:
        'You have access to all his membership content now. You don\'t need to worry anymore, the subscription is automatically renewed so you\'ll will this Star\'s content whenever you want. You can cancel this subscription whenever you want.',
      failed_message:
        'We have not charged your credit card for this subscription'
    },
    wallets: {
      success_message_1:
        'The payment was successful and you have the money in your wallet now.',
      success_message_2: 'Your wallet amount is {amount}',
      failed_message: 'We have not charged your credit card for this reload.'
    },
    carts: {
      success_message_1:
        'Your order was successully paid and you have access to the publications you have purchased.',
      success_message_2:
        'If you have purchased any product, we will notify you when the merchant sends the product.',
      failed_message: 'We have not charged your credit card for this order.'
    },
    types: {
      credit: 'Credit card',
      wallet: 'Wallet'
    },
    checkout: {
      subscription_line_title: 'Subscription for {username}',
      subscription_line_description:
        'nothing | for 1 month | for {count} months'
    },
    errors: {
      695: 'There was an issue with your payment, some data was not correct. Check it and try again.'
    }
  },

  timeline: {
    no_posts:
      'There are no post in your timeline. Use the search engine to find out Stars.',
    engagment: 'Explore what\'s happening in @:project:',
    include_recommendations: 'Include recommended'
  },

  messages: {
    title: 'Messages',
    users: 'Users',
    start_chat: 'Start Chat',
    allow_chat: 'Allow messages',
    new_message: 'New message',
    search: 'Search in messages',
    search_conversation: 'Search Conversation',
    user_search: 'Search Users',
    placeholder: 'Write here your message',
    send_message_to: 'Sends message to...',
    cannot_create: 'You can chat only with subscribed Stars',
    must_be_approved:
      'You must request permission for sending messages to this user',
    pending_approved: 'We are waiting for @{username} to accept your request',
    request_permission: 'Send request',
    pending_approval: 'Pending approval',
    settings: 'Messages Settings',
    tabs: {
      messages: 'Messages',
      reports: 'Reports'
    },
    status: {
      blocked: 'Chat Blocked',
      blocked_hint: 'This user cannot send you messages',
      approved: 'Chat Approved',
      approved_hint: 'This user can send you as messages as he/she wants',
      premium: 'Paid Chat',
      premium_hint: 'This user will be charged {price}/message '
    },
    premium: {
      title_1: 'FREE',
      title_2: 'New price',
      title: 'Message price',
      description_1: 'Pricing setup for this chat.',
      description_2:
        'We will charge the price you chose for every message this user sends you. The amount will be added to your wallet automatically.',
      warning_1: 'This Creator has marked the chat as Paid Chat.',
      warning_2:
        'The price will be {price}/message you send. This amount will be charged to your wallet automatically.'
    }
  },

  notifications: {
    title: 'Notifications',
    users:
      '<strong>{usernames}</strong> | <strong>{usernames} y {more}</strong>',
    new_trial: {
      desktop:
        'No trial claimed | <strong>{usernames}</strong> has claimed a trial | <strong>{usernames} and {more}</strong> have claimed trials',
      mobile: 'No trial claimed | Trial claimed | Trials claimed'
    },
    new_subscription: {
      desktop:
        'No one is subscribed | <strong>{usernames}</strong> has subscribed to your channel | <strong>{usernames} and {more}</strong> have subscribed to your channel',
      mobile:
        'No one is subscribed | New Subscriber | New Subscribers to your channel'
    },
    new_follower: {
      desktop:
        'No one follows you | <strong>{usernames}</strong> has started to follow your channel | <strong>{usernames} and {more}</strong> have started to follow your channel',
      mobile: 'No one follows you | New Follower | New Followers'
    },
    post_liked: {
      desktop:
        'No one likes it | <strong>{usernames}</strong> has liked your post | <strong>{usernames} and {more}</strong> have liked your post',
      mobile: 'No one likes it | Liked your Post | Liked your Post'
    },
    post_saved: {
      desktop:
        'No one saves it | <strong>{usernames}</strong> has saved your post| <strong>{usernames} and {more}</strong> have saved your post',
      mobile: 'No one saves it | Post saved | Post saved'
    },
    post_shared: {
      desktop:
        'No one shares it| <strong>{usernames}</strong> has shared your post | <strong>{usernames} and {more}</strong> have shared your post',
      mobile: 'Shared your post | Shared your Post'
    },
    new_comment: {
      desktop:
        'No one commented it | <strong>{usernames}</strong> has comment your post | <strong>{usernames} and {more}</strong> have comment your post',
      mobile: 'New Comment | New comments'
    },
    tip: {
      desktop:
        '<strong>{usernames}</strong> has sent your a tip for <strong>{amount}</strong>',
      mobile: 'Has sent your a tip for <strong>{amount}</strong>'
    },
    post_published: {
      desktop: '<strong>{usernames}</strong> has publish a new post',
      mobile: 'New post'
    },
    product_published: {
      desktop: '<strong>{usernames}</strong> has put on sale a new product',
      mobile: 'New product'
    },
    post_acquired: {
      desktop:
        'No one acquired it | <strong>{usernames}</strong> has acquired your post | <strong>{usernames} and {more}</strong> have acquired your post',
      mobile: 'Has acquired your post | have acquired your post'
    },
    product_acquired: {
      desktop: '<strong>{usernames}</strong> has acquired your product',
      mobile: 'Has acquired your Product'
    },
    product_bidded: {
      desktop:
        'No none bidded for it | <strong>{usernames}</strong> has bidded for your product | <strong>{usernames} and {more}</strong> have bidded for your product',
      mobile:
        'No none bidded for it | Has bidded for your product | Have bidded for your product'
    },
    product_auction_end: {
      desktop: 'The auction has finished without bids',
      mobile: 'The auction has finished without bids'
    },
    product_auction_winner: {
      desktop:
        'The auction has finished and <strong>{usernames}</strong> has won it',
      mobile: 'Has won the auction'
    },
    comment_mention: {
      desktop:
        '<strong>{usernames}</strong> has mentioned you on a comment | <strong>{usernames}</strong> have mentioned you on a comment',
      mobile: 'Has mentioned you on a comment | Have mentioned you on a comment'
    },
    post_mention: {
      desktop:
        '<strong>{usernames}</strong> has mentioned you on a post | <strong>{usernames}</strong> has mentioned you on a post',
      mobile: 'Has mentioned you on a post | Have mentioned you on a post'
    },
    info: 'A non-controlled event has ocurred',
    success: 'The request has been successfully',
    copy: 'Profile Link copied',
    media_in_use: 'This Media is in use and can\'t be deleted',
    error: 'An error has ocurred: {error}'
  },

  new_chat: {
    notifications: {
      tip: {
        influencer:
          '<span><strong>{username}</strong>&nbsp;has sent you a tip for {amount}</span>',
        user:
          '<span>You have sent a tip of {amount} to&nbsp;<strong>{username}</strong></span>'
      },
      purchase: {
        influencer:
          '<span><strong>{username}</strong>&nbsp;has purchased your content for {amount}</span>',
        user:
          '<span>You has purchased a content for {amount} to&nbsp;<strong>{username}</strong></span>'
      },
      fan: {
        influencer:
          '<span><strong>{username}</strong>&nbsp;has joined your profile as <strong>FAN</strong></span>',
        user:
          '<span>You just already join&nbsp;<strong>{username}</strong>&nbsp;profile as a <strong>FAN</strong></span>'
      },
      free: {
        influencer:
          '<span>This conversation has switched to <strong>FREE</strong></span>',
        user:
          '<span>This conversation has switched to <strong>FREE</strong></span>'
      },
      payment: {
        influencer:
          '<span>This conversation has switched to pay sexting. Cost:&nbsp;<strong>{amount}</strong>&nbsp;per message.</span>',
        user:
          '<span>This conversation has switched to pay sexting. Cost:&nbsp;<strong>{amount}</strong>&nbsp;per message.</span>'
      },
      translated:
        '<span>This conversation is being autotranslated by <strong>@:project_upper</strong><br>Click here if you want to disable autotranslate.</span>',
      not_translated:
        '<span>This conversation has ceased to be translated by <strong>@:project_upper</strong><br>Click here to enable autotranslate.</span>',
      call: '<span>You have been on a call with <strong>{user}</strong> for {time} minutes</span> | <span>You have been on a call with <strong>{user}</strong> for {time} minute</span> |<span>You have been on a call with <strong>{user}</strong> for {time} minutes</span>',

      lost_call: {
        called_me: '<span><strong>{user}</strong> has tried to contact you</span>',
        called: '<span>Have you tried to contact <strong>{user}</strong></span>'
      }
    }
  },

  user_profile: {
    edit: 'Edit profile',
    subscriptions_count:
      '0 subscriptions | 1 subscription | {count} subscriptions',
    followers_count: '0 followers | 1 follower | {count} followers',
    followings_count: '{count} following',
    post_count: '{count} post',
    premium_count: '{count} Premium',
    subscribers_count: 'No fans | 1 fan | {count} fans',
    sections: {
      publications: 'Publications',
      fans: 'My fans',
      followers: 'My followers',
      following: 'Following',
      market: 'Shop',
      subscriptions: 'My subscriptions',
      recommendations: 'According to your preferences',
      purchases: 'My purchases'
    },
    publications: {
      promote: {
        title: 'Become an STAR!',
        button: 'Become an Star'
      }
    }
  },

  resources: {
    banner: 'Banner',
    new_banner: 'New Banner',
    banner_promo: 'Banner 10000 @:project',
    header_promo: 'Header 10000 @:project',
    black_friday_2023: 'Black Friday',
    header_christmas: 'Header Christmas',
    banner_christmas: 'Banner Christmas',
    header_new_chat: 'New Chat Header',
    banner_new_chat: 'New Chat Banner',
    header_videochat: 'VideoChat Header',
    banner_videochat: 'VideoChat Banner',
    header_halloween_2023: 'Halloween Header',
    banner_halloween_2023: 'Halloween Banner',
    selfie_halloween_2023: 'Halloween Selfie',
    header_may_2024: 'May Header',
    banner_may_2024: 'May Banner',
    selfie_may_2024: 'May Selfie',
    header_follow_rabbit: 'Follow the Rabbit Header',
    banner_follow_rabbit: 'Follow the Rabbit Banner',
    valentine: 'Valentine\'s Day Resources',
    carnival: 'Carnival\'s Resources',
    chick_next_door: 'TheChickNextDoor Resources',
    tik_tak_fans: 'TikTakFans Resources',
    header_summer_2023: 'Summer 2023 Header',
    banner_summer_2023: 'Summer 2023 Banner',
    selfie_summer_2023: 'Summer 2023 Selfie',
    header_tik_tak_fans: 'TikTakFans Header',
    banner_tik_tak_fans: 'TikTakFans Banner',
    selfie_tik_tak_fans: 'TikTakFans Selfie',
    twitter: {
      comment:
        'If you\'re looking for something unusual, this is your place, visit my extreme profile at {link} and enjoy with me.'
    },
    download: {
      title: 'Downloads',
      watermark_flies: {
        title: 'Watermarks/flies',
        watermark: 'WaterMark',
        flies: 'Flies',
        help: {
          watermark:
            'Add a watermark to your content and protect it from unauthorized use.\nYou can add the watermark directly to the @:project platform\nor you can download it to add it to your preferred graphic editing software.',
          fly:
            'Add a fly to your content and protect it from unauthorized use.\nYou can add the fly directly to the @:project platform\nor you can download it to add it to your preferred graphic editing software.'
        }
      },
      logo: {
        title: 'Logo',
        help:
          'Download the @:project logo, to be able to use it in the material you generate.',
        horizontal: 'Horizontal Logo',
        vertical: 'Vertical Logo'
      },
      video: {
        title: 'Videos',
        help:
          'Download the videos with the animation of the @:project logo to be able to use it in your own videos, as a header, as a closure or as both.'
      },
      avatar: {
        title: 'Avatares',
        help:
          'Download the avatar with the @:project logo and your profile link.'
      }
    },
    avatar: 'Lovely'
  },

  recommendation: {
    stars: 'Stars',
    market: 'Market'
  },

  promotion: {
    title: 'Identity verification',
    description:
      'You are just one step away to become an STAR! To continue with the process you only need to choose your subscription\'s price and give us some information to verify your age. That is, you don\'t need anything more!',
    document_data: 'Fill these fields with the info of your ID Document',
    subscription_price:
      'The monthly price your FANs will pay to see your member\'s content.',
    dni: 'ID Document',
    dni_description: 'Upload a photo of each side of your ID Document',
    dni_invalid: 'Invalid ID Document',
    verification: 'Verification photo',
    verification_description:
      'Take a photo with your ID Document. You and the document must be visible.',
    verification_invalid: 'Invalid photo',
    pictures: 'Photos',
    chose_price_placeholder: 'Chose price...',
    state: {
      pending:
        'We are processing request to become an STAR. We will notify you when it is completed.'
    },
    blocked_country: {
      title: 'ID Document info',
      text: 'For residents in Spain, Id Document country must be Spain and the document type must by NIF or NIE'
    },
    engagment: {
      title: 'Do you want to be an Star?',
      description: 'Descripción o no',
      engagment_1: 'Look for every type of content and products',
      engagment_2: 'Follow you fav Stars',
      engagment_3:
        'Become the new @:project Star and earn money interacting with your fans',
      become_influencer: 'Yes, I wanna become an Star',
      continue: 'No thank you, go to the site.'
    },
    october: {
      date: 'October 5',
      rules: 'changes the rules of the game',
      banner_1: 'Stay tuned',
      banner_2: 'on October 5, 2020',
      banner_3: '<b>@:project</b> is going to give a <b>bombshell</b>,',
      beStar: 'Become Star for free, to learn more'
    },
    request_finished: {
      text_1:
        'Your content creator process has finished and, if everything is ok, you will receive an email confirming your content creator verification.',
      text_2:
        'We are really fast :-) but if you think we are taking too much time (12 hours max), please, send us an email to {email} to check your verification status.'
    },
    user: {
      rejected: {
        YOTI_COUNTERFEIT: 'Possible counterfeiting',
        YOTI_EXPIRED_DOCUMENT: 'Expired Document',
        YOTI_FRAUD_LIST_MATCH: 'Flag in fraud list',
        YOTI_DOCUMENT_COPY: 'Document copied',
        YOTI_ISSUING_AUTHORITY_INVALID: 'Authority Invalid',
        YOTI_TAMPERED: 'Tampered document',
        YOTI_MISSING_HOLOGRAM: 'Missing Hologram',
        YOTI_NO_HOLOGRAM_MOVEMENT: 'Missing Hologram Movement',
        YOTI_DATA_MISMATCH: 'Data mismatch',
        YOTI_DOC_NUMBER_INVALID: 'Document number invalid',
        YOTI_CHIP_DATA_INTEGRITY_FAILED: 'Chip data integrity failed',
        YOTI_CHIP_SIGNATURE_VERIFICATION_FAILED: 'Chip signature verification failed',
        YOTI_CHIP_CSCA_VERIFICATION_FAILED: 'Chip CSCA verification failed',
        YOTI_PHOTO_OVEREXPOSED: 'Photo overexposed',
        YOTI_PHOTO_TOO_DARK: 'Photo too dark',
        YOTI_PHOTO_TOO_BLURRY: 'Photo too blurry',
        DOCUMENT_TOO_DAMAGED: 'Document too damaged',
        YOTI_GLARE_OBSTRUCTION: 'Glare obstruction',
        YOTI_OBJECT_OBSTRUCTION: 'Object obstruction',
        YOTI_NO_DOCUMENT: 'No document',
        YOTI_PARTIAL_PHOTO: 'Partial photo',
        YOTI_IMAGE_RESOLUTION_TOO_LOW: 'image resolution too low',
        YOTI_COUNTRY_NOT_SUPPORTED: 'Country not supported',
        YOTI_DOCUMENT_NOT_SUPPORTED: 'Document not supported',
        YOTI_INCORRECT_DOCUMENT_TYPE: 'Incorrect document type',
        YOTI_INCORRECT_MRZ: 'Incorrect MRZ',
        YOTI_DOCUMENT_VERSION_NOT_SUPPORTED: 'Document version not supported',
        YOTI_MISSING_DOCUMENT_SIDE: 'Missing document side',
        YOTI_BLACK_AND_WHITE_IMAGE: 'Black and white image',
        YOTI_MISUSE: 'Misuse',
        YOTI_INVALID: 'Invalid',
        YOTI_CHIP_PARSE: 'Chip parse',
        YOTI_CHIP_SOD_PARSE: 'Chip sod parse',
        YOTI_NOT_COMPLETED: 'The registration process could not be completed, please try again or contact {email}'
      }
    }
  },

  settings: {
    tabs: {
      account: 'Account',
      personal: 'Personal data',
      promotion: 'Service',
      payments: 'Account Settings',
      notifications: 'Notifications',
      privacy: 'Privacy',
      messages: 'Messages',
      payments_datas: 'Transactions',
      documents: 'Documents',
      promo_star: 'Promotions to STAR',
      billing: 'Billing'
    },
    account: {
      delete_account: 'Delete account',
      delete_account_hint:
        'For security check, first write your @:project username',
      email_validated_hint: 'The email is verified',
      email_not_validated_hint:
        'It is mandatory verify your email address to have full access',
      password: 'Password',
      change_password: 'Change password',
      delete: {
        warning: 'Important!',
        description:
          'We are going to delete your account. <strong>This action cannot be undone and all your data is going to be removed</strong>. The actions to be carried out are detailed below:',
        personal_data: 'Deleting your personal data.',
        wallet: 'You wont be able to recover your wallet funds.',
        subscriptions: 'Canceling your active subscriptions (no refund).',
        social_media: 'Deleting all social media conection like Twitter, etc.',
        loverfans_actions:
          'Deleting all @:project actions: Likes, saved posts, messajes, notifications, etc.',
        subscribers:
          'If you have any active subscriber, they wont be renew and your account will be deleted after the subscription period ends.',
        posts: 'Deleting your posts and comments if you have them.',
        premium_posts:
          'Users who bought premium posts will still have access to them.'
      }
    },
    personal: {
      personal_data: 'Personal data',
      address: 'Address data',
      contact: 'Contact Information',
      verified: 'These data are verified, if you want to modify them',
      contactUs: 'contact us'
    },
    payments: {
      legal_doc: 'Legal docs',
      epayment: 'ePayments data',
      bank_data: {
        datas: 'Bank Data',
        description: 'Transfer to your bank account'
      },
      paypal: {
        datas: 'Paypal',
        id: 'Paypal ID'
      },
      western: {
        datas: 'Western Union',
        id: 'Western Union ID'
      },
      subscription_price: 'Subscription price',
      subscription_price_description:
        'Price your fans will pay to subscribe your private content',
      pending_activation: 'Star profile is not activated yet.',
      methods: 'Payment methods'
    },
    twitter: {
      signup: 'Signup finished',
      description:
        'We have chosen some fields for you but you have the chance to change them now. Remember that you need your email verified to have complete access to @:project so we recommend you to setup one and verify it.'
    }
  },

  faq: {
    title: 'FAQ: Frequent asked questions',
    loverfans: {
      tittle: 'Why @:project?',
      question_1: 'What is @:project?',
      response_1:
        'Is the new and worldwide Fans Web Platform, that allows influencers to manage their whole Fans Business in an unique site.',
      question_2: 'All in a single Action',
      response_2:
        '@:project allows you to create with a simple action from generate content for your subscribers, upload paid content, create custom content for a special fan, to put on sale a real or digital garment or merchandising. Additionally, it includes a differential tool for which you can auction among all your fans.',
      question_3: 'How to signup a STAR profile?',
      response_3:
        'Signup in @:project, chose your username and display name. Select "I want to become an STAR" and fill the personal data and your ID verification. Our admins will check it and will promote you as soon as possible. Register via Twitter and keep your fans updated to your @:project feed. Once you have filled your bank or card (wallet) information you’ll be ready to manage your influencer business in the unique global platform for fans.',
      question_4: 'Why be a FAN in @:project?',
      response_4:
        'Because in @:project we’re commited in bringing you the most popular STARS, uarantees your privacy and maximum security. It includes a secure payment platform and a technological environment, so that all your content is always available at the highest possible quality and speed.'
    },

    stars: {
      tittle: 'Most Popular STARS FAQs',
      question_1: 'What amount do I choose in my subscription?',
      response_1:
        'Our commitment to your FANS is to bring the most popular STARS, and our commitment to the STARS, is to bring the most faithful FANS, we eliminate the transactional risk, assuming all of it, so you can choose the subscription that is most associated with your STAR profile. Try us and you will see that quality starts with you and stays with your FANS.',
      question_2: 'What does Visibility mean during the content publication?',
      response_2_1:
        'Open: The content you’re uploading could be seen by any member of @:project Community. Use it, to allow our community knows more about you.',
      response_2_2:
        'My fans: Content only for those FANS with an active subscription.',
      response_2_3:
        'Premium: Are selected content, done with Love and work, theses contents are not included in the subscription model, so anybody who wants to see them have to pay.',
      response_2_4:
        'User: Custom content, you have prepare for a special FAN, once the content is ready the FAN proceed to its payment, and it that moment the content will be ready for your FAN. You can use this modality for a customize greetings, a hight quality video, you make their fantasies real, you fix the price and you define your rules.',
      question_3: 'How do I put a product on sale?',
      response_3:
        'It is very easy, from the same publishing action, you first select product then you choose between direct sale or auction mode.',
      question_4: 'What is the difference between sale and auction?',
      response_4: `A product for sale, can be something both physical (garment, object that you use or merchandising material) or digital (a dedicated photo, a personalized poster, etc.), you put a price, and your faster FAN will buy it and you will send to him in the conditions that you agree.
                While an auction is an innovative tool, we offer you, you propose the starting price and the duration in days of the auction and at the end of the period the FAN that has bid the most for your product, will be the winner, We will confirm when the payment has been made successfully, and you can send the product as you have agreed.`,
      question_5: 'Should I include the shipping price in the amount?',
      response_5:
        'The amount for which you bid will be the total amount that the FAN will pay, so you will have to include all the costs including, You can explain your conditions in the product description.',
      question_6: 'From where can create a new direct?',
      response_6:
        'Everything from the publish button or "+", gives live option, check the operation of the camera, select the visibility you want for your new live connection, just have to start it from your new live room.',
      question_7: 'What are the different visibility options?',
      response_7_1:
        'Public: Visible for any user registered in @:project, use it to make yourself know or call other @:project\' users.',
      response_7_2:
        'Premium: Set a price per minute for connected users, if you aren\'t connected, users won\'t be charged until you start the show, they can pause at your convenience. Additionally you have an option to activate if this modality is free for your fans (for a fee).',
      response_7_3:
        'Private: In this mode you can indicate a minute price and the user with whom you want to share that moment.',
      question_8: 'How many live shows can be open?',
      response_8: 'You can only have one room either live or scheduled.',
      question_9:
        'Can I use my camera in some other application while doing live?',
      response_9:
        'For security reasons, you can only use the camera while you @:site direct.',
      question_10:
        'Can I have my profile open on some other device while doing direct?',
      response_10:
        'Yes, you can, but you cannot be in the direct room. It\'ll automatically disconnect. You can change devices, it will automatically remove you from the session on the previous device and you will have to start DIRECT from the room again.',
      question_11: 'Does it work on all devices and browsers?',
      response_11:
        'It works on all browsers and devices, like mobiles, tablets or landlines, but Apple blocks the use of the camera in mobile operating systems other than Safari. For whether you have an iPhone or an iPad, use Safari to ensure proper operation.',
      question_12:
        'What is "content type" filtering and where can I complete it so my fans can find me better?',
      response_12_1:
        'Our @:project\' community is growing every day, we want to help your fans find you more easily and to show them the content they like more precisely.',
      response_12_2:
        'If you go to "SETTINGS/LOVERSTAR/Types of Content" and click to modify, you can change your profile. The categories are:',
      response_12_3:
        'What are you? It is a unique category, select what best defines you.',
      response_12_4:
        'How do you categorize your content? It is multiple selection, if you create different types of content select those that represent you the most.',
      response_12_5:
        'As you look? Identify the age range that represents you the most.'
    },

    fans: {
      tittle: 'Most Popular FANS FAQs',
      question_1:
        'Why my final statement is different that the price in @:project?',
      response_1:
        'At the end amount we add your country taxes, in that way we could guarantee all your consumer rights, all under the supervision of our legal department.',
      question_2: 'What information Will be displayed in my card statement?',
      response_2:
        'Your card statement will show that the payment was made to Cgbilling*artemisa 3',
      question_3: 'How to cancel a subscription?',
      response_3:
        'You can cancel your subscription at any time, from your list of active subscriptions in your profile, you will see a button with the text "Subscribed", when you hover over it, it will change to "CANCEL". Once clicked, you will see a confirmation pop-up and warning you of what the cancellation will entail.',
      question_4: 'I don’t like the STAR content',
      response_4:
        'All purchases made on @:project are final and non-refundable. Bur if you want, you cancel your subscription at any time.',
      question_5: 'What can I pay by wallet and what can I pay by card?',
      response_5_1:
        'Only with the credit card you can make subscription payments, since they are periodic purchases, they cannot depend on whether there is a balance in the wallet or not.',
      response_5_2:
        'On the other hand, from the wallet, you can make any type of transaction except subscription payments.',
      response_5_3:
        'Only from wallet, you can make payments of amounts less than € 10, with the exception of subscriptions.',
      response_5_4:
        'For example, a tip to a STAR, the payment of a content or a product that has an amount less than € 10.'
    },

    lives: {
      stars: {
        tittle: 'Most Popular Lives FAQs between STARs',
        question_1: 'What are live shows?',
        response_1_1:
          '<p>It\'s a new way to view and chat with your <strong>FANS</strong> in real time.</p>',
        question_2: 'When and how do I charge for live shows?',
        response_2:
          'In real time, you will see the income console and also an additional tip. At the end of the live show, this income will be consolidated in your wallet.',
        question_3:
          'How can I go from a public room to another Premium or Private?',
        response_3:
          'You have to create a new direct, all users who are in the previous room will receive a new link to the new room and they could connect directly if they accept the new conditions.',
        question_4:
          'Have you tried to challenge your followers during the Live?',
        response_4:
          'Challenge your followers, proposing new challenges. Ask them to hit a defined tip amount and challenge against that amount. It\'s a way to encourage the use of tips during the live show.',
        question_5: 'Importance of planning direct.',
        response_5_1:
          'You will see that you\'ve an option when create a new live called, "schedule for later", this functionality is the key. Live connection is a functionality specially designed to have a closer experience with your fans and followers. Let them know when you\'ll be going to connect and communicate through your networks when you\'ll be online. This way your followers will be waiting for you.',
        response_5_2:
          'When you start the live show, @:project will notify your followers via email sending them a shortcut to your new live room.'
      },
      fans: {
        tittle: 'Most Popular Lives FAQs between FANs',
        question_1: 'What are live shows?',
        response_1_1:
          '<p>It\'s a new way to view and chat with your favourite <strong>STARS</strong> in real time, stay tuned to notifications, follow or become a <strong>FAN</strong> of <strong>STARS</strong> that you want to be notified of when they will connect.</p>',
        response_1_2:
          '<p>Stay tuned for the schedule of your favourite <strong>STARS\'</strong> events, these will appear in the <strong>DIRECT</strong> section of the menu on your left.<p>',
        response_1_3:
          '<p>We\'ll send you an email when a <strong>STAR</strong> that you follow or you\'re a FAN is <strong>LIVE</strong>.<p>',
        question_2:
          'My Favourite STAR is planning a Live, how could I know when?.',
        response_2_1:
          'If your favourite Star is planning a live show, she/he should communicate via social networks when will be online.',
        response_2_2:
          'When the broadcast time comes, @:project will notified you via Email with a shortcut link when the Live show start.'
      }
    },

    common: {
      tittle: 'Most Popular All Users FAQs',
      question_1: 'Why my @:project profile photo is a low quality one?',
      response_1:
        'Pretty sure you\'ve registered via Twitter, Twitter send us your photo in low quality, but if you get into yor profile you can change your photo for one with better quality.',
      question_2:
        'I get a warning that a word is not allowed, what are those words?',
      response_2:
        'For legal and security reasons, certain words are not allowed in any content on the site, unless it is to inform what they are. Here is the list of prohibited words that we currently have enabled:',
      question_3: 'How can I delete my account?',
      response_3_1:
        'Go to Settings -> Account Section, at the end, you\'ll see a dropdown menu "Delete account". On that menu, you must indicate your account name, press "Delete" button and confirm delete.',
      response_3_2:
        'If you\'re a STAR and your content has been purchased, it\'ll be available to users who have purchased it, and if you\'ve fans at the end of the subscription, their subscription will be canceled and they won\'t have access to their FAN and public content, the account will be deleted with the last fan.',
      response_3_3:
        'All payments and collections will be public access and anonymous as law required.'
    }
  },

  contact: {
    title: 'Contact',
    subject: 'Subject',
    message: 'Message',
    no_email:
      'In order to answer you an email is required in your account settings.'
  },

  wallet: {
    balance: 'Balance',
    current_balance: 'Current balance',
    current_balance_amount: 'Wallet current balance is {amount}',
    subscriptions_balance: 'Subscriptions',
    sales_balance: 'Sales',
    tips_balance: 'Tips',
    conference_balance: 'Lives',
    chats_balance: 'Chats',
    withdrew_balance: 'Withdrew',
    movement_balance: 'Wallet mov.',
    currency_wallet: '{currency} Wallet',
    hide_detail: 'Hide details',
    show_detail: 'Details',
    move_funds: 'Move funds to {currency}',
    reload: {
      title: 'Reload funds',
      amount:
        'Chose the amount for reloading your wallet | Chose the amount for reloading your wallet ({count}% Ex.) | Chose the amount for reloading your wallet ({count}% Ex.)',
      remember: 'Remember you can reload your wallet'
    },
    events: {
      type_reload: 'Reload',
      type_fee: 'Fee',
      type_purchase: 'Purchase',
      type_sale: 'Sale',
      type_agent: 'Agent',
      type_post: 'Post',
      type_product: 'Market',
      type_cart: 'Cart',
      type_cart_sale: 'Sale (Cart)',
      type_subscription: 'Subscription',
      type_rebill: 'Rebill',
      type_tip: 'Tip',
      type_conference: 'Live',
      type_promo: 'Story',
      type_chat: 'Chat',
      type_referral: 'Referral',
      type_refund: 'Refund',
      type_bonus: 'Bonus',
      type_affiliate: 'Affiliates',
      type_auction: 'Auction',
      type_penalized_auction: 'Penalty (auction)',
      type_reserve: 'Reserved',
      type_scatbook_transfer: 'Scatbook transfer',
      type_wallet_transfer: 'Wallet mov.',
      type_agency: 'Agency',
      credit_card: 'credit card',
      affiliate_user: 'User from affiliates',
      sfs_revolution: 'SFS Revolution',
      reserved_amount: 'Reserved amount: {amount}',
      type_videochat: 'Video Chat'
    },
    referral: {
      posts: '@{username}\'s post',
      products: '@{username}\'s product',
      subscriptions: '@{username}\'s subscription',
      tips: '@{username}\'s tip',
      conversation: '@{username}\'s conversation',
      conversation_messages: '@{username}\'s messages'
    },
    bonus: {
      black_friday: 'Black Friday'
    }
  },

  withdraws: {
    title: 'Withdraw funds',
    last_withdraws: 'Last withdraws',
    amount: 'Amount to withdraw',
    change_payment: 'Change payment method',
    change_payment_method: 'Modify your payment method',
    go_to_config: 'Configure payment methods',
    no_config: 'You must configure a payment method',
    withdraw_info: `Minimum amount to withdraw funds is $ {amount} or {amount}€`,
    error_sign_contract: "To withdraw money, you must sign our creator's contract.",
    error_withdraw_tw: 'Due to problems external to the platform we cannot issue temporary payments for TransferWise.',
    error_withdraw_btc: 'Due to problems external to the platform we cannot issue temporary payments for Bitcoin.',
    error_withdraw_paypal: 'Due to problems external to the platform we cannot issue temporary payments for Paypal.',
    error_withdraw_paypal_warning: 'Por problemas externos a la plataforma no podemos emitir temporalmente pagos por Paypal. Modify your payment method to continue.',
    not_enough_funds:
      'You have not enough funds to withdraw funds. The minimun amount is {amount}',
    payment_info: 'Payment information',
    payment_method: 'Payment method: {method}',
    fullname: 'Name: {name}',

    error_withdraw: 'Your payment of {price} was rejeted for {message}',
    warningPaypal2023: {
      title: 'IMPORTANT NOTICE: CHANGE YOUR PAYMENT METHOD',
      subtitle: '(PAYPAL WILL HAVE AN EXTRA FEE FROM 1TH OF JULY)',
      desc1: 'Dear creator: due to a change of Paypal policies about currency exchange payouts via Paypal will have an extra fee of 5% from the next 1th of July.',
      desc2: 'We strictly recommend to change your payment method among the other ones you have available on the platform. They don\'t have any extra fee on the payout.',
      desc3: 'Also the ones label under "P2P" allow you to have the payment on your wallet instantly.',
      list1: '<a href="https://www.paxum.com/" target="_blank">Paxum (P2P)</a>',
      list2: '<a href="https://es.cosmopayment.com/" target="_blank">CosmoPayment (P2P)</a>',
      list3: 'Wire Transfer (LATAM included)',
      list4: 'Bitcoin',
      desc4: 'As always if you need help and/or further information don\'t hesitate to reach out us at [STARS_SUPPORT_MAIL] for creators support and [FINANCIAL_SUPPORT_MAIL] to reach out our finance department directly. '
    },
    warningWireTransferTitle: 'TEMPORARY INCIDENT WITH PAYMENTS BY BANK TRANSFER TO YOUR COUNTRY',
    warningWireTransferText: '<p>During last month we are having problems with the payment method of wire transfer to your country.<u> This is something alien to us as our partner decided not to work with fan platforms anymore.</u> We are working hard to fix the situation and <b>while we resume the service and to don\'t have any delays in your payments we kindly ask you to select among the other available payment methods:</b> <br><br> Paxum, CosmoPayment, BTC (Bitcoin), Paypal<br><br><u>We expect to resume the normal service before the first week of February. </u>As always we are available to help you and for further information at:{email}</p>',
    warning:
      'The payout will become effective the last business day of the week. You wont be enable to request a new payout until the pending request is resolved.',
    method_account: 'Account: {account}',
    fees: 'Expenses: {amount}',
    total: 'Total: {amount}',
    bank: {
      uncomplete:
        'More bank data is needed. Please, finish the Bank form at payment methods to use Bank Account Transfers.',
      username: 'Owner: {name}',
      user_country: 'Country: {country}',
      country: 'Bank Country: {country}',
      currency: 'Bank Currency: {currency}',
      name: 'Bank Name: {name}',
      account: 'Account Number: {account}',
      swift: 'SWIFT / BIC: {swift}',
      routing_number: 'ABA: {aba}',
      transit_number: 'Transit Number: {aba}'
    },
    paypal: {
      id: 'Paypal ID: {paypalID}'
    },
    western_union: {
      id: 'Western Union ID: {westernId}'
    },
    options: {
      bank: 'Bank Account',
      paypal: 'Paypal',
      western: 'Western Union',
      epayment: 'ePayments'
    },
    descriptions: {
      bank:
        'In the following days we will make a transfer to your configured bank account',
      epayment:
        'In the following days we will make a payment to your configured eWallet'
    },
    state: {
      rejected: 'Your payment of {amount} was rejeted for {reason}',
      pending:
        'You have a withdrawal {amount} on going. Expected payment date: {period}',
      pending_old: 'You have a withdrawal {amount} on going. We will notify you when it is accomplished.',
      holiday:
        'Due to Easter Holidays your payment will be proccesed on {endDate} instead {startDate}'
    },
    withdraw_data: {
      title: 'Payout data',
      description: 'This is the personal data for payouts, .',
      uncomplete: 'Payout data is mandatory for requesting payments',
      personal_data: 'Data',
      business: 'Company',
      vat_data: 'VAT Identification',
      check_change_data: 'Same billing data',
      upload_file_text: 'Select or drop some files',
      tab1: 'Account holder',
      tab2: 'Company',
      tab3: 'Third Party Payment',
      alert: 'If you want to modify this data go to',
      bypass_irpf: 'Bypass IRPF'
    },
    withdraw_methods: {
      title: 'Payout methods',
      no_data: 'There aren\'t any payout method added yet.',
      withdraw_data_needed:
        'You need to add personal payout data before setting up a payout method',
      chose_description: 'Chose the payout method',
      file_uploaded: 'Document of authenticity of account holder',
      file_uploaded_desc: 'help information',
      types: {
        bank: 'Wire transfer',
        cosmo: 'CosmoPayments',
        e_pay_services: 'ePayService',
        paxum: 'Paxum',
        skrill: 'Skrill',
        transfer_wise: 'TransferWise',
        btc: 'Bitcoin',
        paypal: 'PayPal',
        western: 'Western Union'
      },
      bank: {
        form_description: 'Bank data',
        info: {
          description_1: 'Recommended for EU and USA',
          description_2: 'Payouts directly to your bank account',
          description_3: 'Local currency of your bank',
          description_4: 'Estimated payout arrival: between 1 and 3 days'
        }
      },
      cosmo: {
        info: {
          description_1: 'Recommended for EU, UK, CO, MX, BR, PE, CL, UY',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      e_pay_services: {
        info: {
          description_1: 'Recommended for: check your country {link}',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      paxum: {
        info: {
          description_1: 'Recommended for EU, USA and LATAM',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      paypal: {
        info: {
          fee: 'Fee of 5% for each payout',
          description_1: 'Recommended for: world wide',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      skrill: {
        info: {
          description_1: 'Recommended for: EU, USA',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      transfer_wise: {
        info: {
          description_1: 'Recommended for: EU, USA',
          description_2: 'Open your {link} account',
          description_3: 'Currency: $ y €',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      btc: {
        info: {
          description_1: 'Recommended for: world wide',
          description_2: 'Our recommended partner: {link}',
          description_3: 'Currency: BTC',
          description_4: 'Estimated payout arrival: Immediately'
        }
      },
      western: {
        info: {
          description_1: 'Recommended for: LATAM',
          description_2: 'Currency: Local currency of your country',
          description_3:
            'Fee: 4,90€ (it could be increased depending on the amount received)',
          description_4: 'Estimated payout arrival: Immediately',
          description_5:
            'It is strictly necessary that the receptor must be the same of account holder'
        }
      }
    }
  },

  tags: {
    title: 'Info',
    content_info: 'Content tags',
    description_1:
      'Hello {name}! We are asking for some info about the content you are creating. With this info, fans with similar interests will have much easier to find you.',
    description_2:
      'It won\'t take too much time. Just a few clicks and it\'s done.',
    male: 'Male',
    female: 'Female',
    couple: 'Couple',
    trans: 'Transexual',
    producer: 'Producer',
    model: 'Model',
    soft: 'Soft',
    straight: 'Straight',
    swinger: 'Couples/Swinger',
    homo: 'Gay',
    Gay: 'Gay',
    lesbian: 'Lesbian',
    bdsm: 'BDSM',
    mistress: 'Mistress',
    trans_content: 'Transexual',
    extreme: 'Extreme',
    scat: 'Scat',
    teen: '18-25',
    young: '26-35',
    adult: '36-45',
    mature: '>46',
    none: 'None',
    white: 'Caucasian',
    latin: 'Latin',
    asian: 'Asian',
    black: 'Afro-American',
    indian: 'Indian',
    category: {
      race: 'Which one describe you better?',
      gender: 'What are you?',
      content: 'How you describe your contents?',
      age: 'How old are you?'
    },
    filter: {
      gender: 'Gender',
      content: 'Categories',
      age: 'Age',
      race: 'Race',
      country: 'Country',
      language: 'Language',
      status: 'Status'
    },
    warning: {
      text:
        'If your content has scat fetish it is mandatory to check this field',
      keep: 'Keep checked',
      remove: 'Uncheck'
    }
  },

  blocked_countries: {
    title: 'Blocked countries',
    description:
      'Users from chosen countries won\'t be able to see your content nor your profile',
    no_countries: 'There are no selected countries',
    select_country: 'Block country',
    content_blocked: 'This content is blocked on your country'
  },

  tips: {
    dialog: {
      title: 'Send tip',
      hint: 'Tips are sent with wallet\'s funds only',
      amount: 'Tip',
      receiver: 'Send to:'
    }
  },

  referrals: {
    title: 'Referrals',
    desc: 'Share this link with those who you want to register on the website throughout you in order to get the 5% of their sells.You can share your profile link from your own profile page.',
    url: 'Referral Url'
  },

  widgets: {
    recommendations: {
      title: 'Maybe you are interested in...',
      title_2: 'Our recommendations',
      all: 'Show all',
      product: {
        title: 'Recommended products:'
      }
    },
    lives: {
      recorded: {
        title: 'STARs who do Lives frequently'
      }
    }
  },

  twitter: {
    notifications: {
      title: 'Twitter notifications',
      subscription: 'You subscribe to an Star',
      subscriber: 'You have a new subscriber',
      follower: 'You have a new follower',
      premium_content: 'You have created a Premium post',
      other_content: 'You create other posts',
      product: 'You publish a product',
      purchased: 'You sell a product',
      auction_finished: 'An auction ends',
      lives: 'You start a Live'
    }
  },

  models: {
    bonuses: {
      id: 'ID',
      name: 'Name',
      bonusCode: 'Bonus Code',
      blocked: 'Blocked',
      createdAt: 'Created At',
      applied: 'Applied',
      appliedAt: 'Applied At',
      description: 'Description',
      amount: 'Amount',
      total: 'Uses',
      totalApplied: 'Claimed',
      requiredAmount: 'Required Amount',
      startDate: 'Start at',
      expirationDate: 'End at'
    },
    promo: 'Promo Post',
    validate: {
      id: 'Id',
      username: 'Username',
      date: 'Publication date',
      createdAt: 'Created date',
      updatedAt: 'Updated date',
      validation_date: 'Validation date',
      validated: 'Validated',
      participants: '# Participants',
      details: 'Details',
      actions: '',
      status: 'Status',
      postType: 'Type'
    },
    users: {
      name: 'Stars',
      singular_name: 'Star',
      order: {
        verifiedAt: 'Latest additions',
        ranking: 'Relevance',
        username: 'Name',
        promotedProfile: 'Top Creators',
        online: 'Online'
      },
      showCount: {
        label: 'Show Fans and Followers Public',
        tooltip:
          'Your visitors will be able to see the number of fans and followers you have on the platform publicly.<br>This will be automatically displayed if minimum fan (100) and follower (20) requirements are met.'
      },
    },
    categories: {
      name: 'Categories',
      singular_name: 'Category'
    },
    videocall: {
      singular_name: 'Video Chat'
    },
    conferences: {
      name: 'Lives',
      singular_name: 'Live'
    },
    payments: {
      name: 'Payments',
      singular_name: 'Payment'
    },
    posts: {
      name: 'Posts',
      singular_name: 'Post',
      auto_publish:
        'The content will be published as soon as it\'s created since the date is in the past.'
    },
    products: {
      name: 'Products',
      singular_name: 'Product'
    },
    wallets: {
      name: 'Wallets',
      singular_name: 'Wallet'
    },
    withdraws: {
      name: 'Withdraws',
      singular_name: 'Withdraw'
    },
    roles: {
      name: 'Roles',
      admin: 'Admin',
      influencer: 'Star',
      compliance: 'Compliance',
      account_manager: 'Account Manager',
      content_manager: 'Content Manager',
      follower: 'Follower',
      influencer_plural: 'Stars',
      controller: 'Controller',
      follower_plural: 'Followers',
      agent: 'Agent',
      agent_plural: 'Agents',
      studio: 'Studio'
    },
    tag: {
      name: 'Tags'
    },
    user: {
      username: 'Username',
      bonusCode: 'Bonus Code',
      displayName: 'Display Name',
      scatProfile: 'Scat Profile',
      bdsmProfile: 'BDSM',
      smokeProfile: 'Smoke',
      diaperProfile: 'Diaper',
      footFetishProfile: 'Foot Fetish',
      promotedProfile: 'Top',
      displayName_hint:
        '(optional) Name displayed in @:project instead of your username',
      createdAt: 'Register',
      name: 'Name',
      name_hint: 'Your real name',
      surname: 'Surname',
      dni: 'Document ID',
      cif: 'Document ID/CIF',
      perm_doc: 'Permanent document',
      cp: 'CP',
      birthdate: 'Birthdate',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      email: 'Email',
      email_confirmation: 'Repeat email',
      current_password: 'Current password',
      password: 'Password',
      new_password: 'New Password',
      password_confirmation: 'Repeat new password',
      address: 'Address',
      country: 'Country',
      postal_code: 'Postal code',
      province: 'Province',
      city: 'City',
      role: 'Role',
      blocked: 'Blocked',
      active: 'Active',
      description: 'Description',
      e_wallet: 'eWallet number',
      bank_account_number: 'Bank account number',
      iban_number: 'IBAN',
      swift_number: 'Bank SWIFT / BIC',
      institution_code: 'Institution Code',
      real_name: 'Full Name',
      live_country: 'Country',
      bank_name: 'Bank name',
      bank_currency: 'Bank Currency',
      bank_country: 'Bank Country',
      select_option: 'Select an option',
      subscription_price: 'Monthly subscription price',
      error_min_price_subscription: 'The total minimum we accept from subscribers is {price}',
      error_price_subscription: 'The total maximum we accept from subscribers is {price}. For a higher price contact us at ',
      subscription_fee: 'Subscription fee',
      premium_fee: 'Premium post fee',
      market_fee: 'Market fee',
      tip_fee: 'Tip fee',
      accept: 'I accept',
      acceptedTerms: 'I accept terms & conditions',
      isAdult: 'I confirm that i am 18 years or older',
      state: 'State',
      routing_number: 'Routing Number (ABA)',
      transit_number: 'Transit Number',
      telegram: 'Telegram ID',
      twitter: 'Twitter',
      skype: 'Skype ID',
      phone_prefix: 'Intl Prefix',
      mobile: 'Mobile Phone',
      trustedProfile: 'Verified Star',
      untrustedProfile: 'Untrusted Star',
      personalDataApproved: 'Verify Data',
      premiumModel: 'Premium Model',
      unPersonalDataApproved: 'Unverify Data',
      whitelistProfile: 'Add whitelist',
      blacklistProfile: 'Remove whitelist',
      subscribed_by: 'is your Fan',
      followed_by: 'is following you',
      new_payout: 'New payouts',
      refund_policy: 'Refund Policy',
      agencyPayout: 'Agency payouts',
      verifiedData: 'Verified data',
      active_logs: 'Active Logs',
      extract_wallet: 'Extract Wallet',
      payment_person: {
        title: 'Payment person data',
        desc: 'Same data as the account holder',
        doc_completed: 'AUTHORIZATION OF TRANSFER TO A THIRD PARTY (Document to be completed)',
        cert_identity: 'I certify that this withdrawal method belongs to the person indicated in the tax collection data',
        info:
          '<strong style="font-family: Quicksand, sans-serif">PLEASE NOTE! </strong>if the person to be charged is different from the account holder, our financial department will contact you to ask for extra documentation from this person from the first payment'
      },
      on_board: {
        step_one: {
          title: 'STEP ONE',
          subtitle: 'ACCOUNT DETAILS',
          desc:
            'Set up your account details and fill in your contact details so that an account manager can contact you (highly recommended but not mandatory).'
        },
        step_two: {
          title: 'STEP TWO',
          subtitle: 'YOUR PERSONAL AND PAYMENT DETAILS',
          desc:
            'Confirm the personal data of the account holder. They must match the identity document you provide us with.'
        },
        step_three: {
          title: 'STEP THREE ',
          subtitle: 'ACCOUNT VERIFICATION',
          desc:
            'You\'re almost there! Upload an official ID so we can confirm your personal details and age of majority.'
        },
        step_four: {
          title: 'STEP FOUR AND LAST',
          subtitle: 'SIGN CREATORS CONTRACT',
          desc:
            'Sign the creators contract to be able to get paid'
        },
        step_final: {
          title: 'THANK YOU!',
          subtitle: '',
          desc: ''
        }
      },
      status: {
        online: 'ONLINE',
        absent: '',
        offline: ''
      },
      topUsers: 'Top Creators',
      isOnline: 'Online'
    },
    agent: {
      username: 'Agent',
      partners_count: '# Represented',
      actions: '',
      partner: {
        username: 'Star',
        date: 'Date',
        fee: 'Fee',
        earning: '$ Earning'
      }
    },
    conference: {
      title: 'Title',
      visibility: 'Visibility',
      minute_price: '€/min',
      free_fans: 'Free fans',
      free_fans_hint: 'My fans can watch the show without paying',
      choose_starts_at: 'Starts at chosen date',
      starts_at: 'Starts date',
      starts_date: 'Date to start',
      starts_time: 'Time to start',
      only_actives: 'Emitting now',
      selected_user: 'User',
      selected_users: 'Users',
      singular_name: 'Live',
      types: {
        public: 'Public',
        premium: 'Premium',
        exclusive: 'Private'
      },
      type_description: {
        unsigned: 'Login to watch this Live',
        public: 'Any Loverfan member can watch your Live',
        premium: 'Only members who pay per minute will watch your Live',
        exclusive: 'Only the chosen member will watch your Live'
      }
    },
    complaint: {
      reason_question: 'Tell us why you want to report',
      reason_question_video: 'Tell us why you want to report this video call',
      resource: 'Resource type',
      date: 'Date',
      user: 'User',
      comment: 'Reason',
      status: 'Status',
      status_types: {
        pending: 'Open',
        closed: 'Closed',
        content_blocked: 'Content blocked'
      }
    },
    influencer_request: {
      verifiedAt: 'Verified',
      description: 'Description',
      id_document: 'ID Document',
      id_document_type: 'Type',
      id_document_number: 'Number',
      id_document_country: 'Country',
      id_document_expiration: 'Expiration',
      verification_photo: 'Verification photo',
      status: 'Status',
      detail: 'Detail',
      id_document_types: {
        passport: 'Passport',
        id_document: 'National ID Document',
        cif: 'CIF/TIN/VAT Num.',
        driver_license: 'Driver License',
        step_one:
          'ID: upload a photo of your document in good quality and on both sides.',
        step_two:
          'Verification photo: upload a photo holding your document and where it is clear that you are the person appearing on the document.',
        step_two_bis: 'If your Id Document doesnt have a your pic then hold a document with a photo of yours when taking the selfie.',
        step_three:
          'Fill in the following information to complete the verification process.'
      }
    },
    post: {
      content: 'Title',
      selected_user: 'User',
      selected_users: 'Users',
      visibility: 'Who can see this post',
      price: 'Price',
      publish_at: 'Schedule at',
      choose_publish_at: 'Schedule post',
      starts_date: 'Date',
      starts_time: 'Time',
      multiple_people: 'There are more people apart of me on this video',
      participant_chip: 'Character {number}',
      added_participant_chip: 'New character {number}',
      visibility_types: {
        open: 'Free',
        followers: 'My fans',
        premium: 'Premium',
        exclusive: 'Users'
      },
      participants: {
        selfie: 'Selfie holding ID',
        dni: 'Participant ID',
        dni1: 'Side 1 of Char. Doc. Id',
        dni2: 'Side 2 of Char. Doc. Id',
        upload_dni1: 'Upload Side 1',
        upload_dni2: 'Upload Side 2',
        agreement: 'Model release or contract agreement with the performer'
      },
      persona_id: {
        description: 'Only characters who are not you',
        first_side: 'Side 1 of {number} character Doc. ID',
        second_side: 'Side 2 of {number} character Doc. ID',
        agreement: 'Model release or contract agreement with the performer'
      }
    },
    product: {
      name: 'Product name',
      description: 'Description',
      price: 'Price',
      auction_duration: 'Auction duration (days)',
      delivery_type: 'Delivery type',
      sale_type: 'Sale type',
      infinite_stock: 'Unlimited stock',
      infinite_stock_hint:
        'Select to sell multiple times this article. You can edit it later when you dont want to sell the item anymore.',
      delivery_types: {
        digital: 'Digital',
        packet: 'Postal/Package'
      },
      sale_types: {
        market: 'Normal',
        sale: 'Normal',
        auction: 'Auction'
      }
    },
    payment: {
      accept: {
        subscription: 'By Click on next, I confirm that I accept the <a href="{contract}" target="_blank">Contract Conditions</a> and I will be billed {period} by {amount}, until cancellation. To unsubscribe, please visit <a href="https://marketbilling.net" target="_blank">marketbilling.net</a> or email us at <a href="mailto:{support}" target="_blank">{support}</a>',
        unique: 'By Click on next, I confirm that I accept the <a href="{contract}" target="_blank">Contract Conditions</a> and I will be billed {amount}. To any claim, please visit <a href="https://marketbilling.net" target="_blank">marketbilling.net</a> or email us at <a href="mailto:{support}" target="_blank">{support}</a>'
      },
      month: 'MONTHLY',
      trimester: 'QUARTERLY',
      semester: 'BIMONTHLY',
      annual: 'ANNUALLY',
      billed_at: 'Date',
      bruto: 'Gross',
      influencer_cost: 'Influencer Cost',
      loverfans_fee: '@:project  fee',
      transaction_id: 'Transaction',
      payment_user_ip: 'IP',
      country: 'Country',
      user: 'User',
      receiver: 'Receiver',
      resource: 'Source',
      name: 'Name',
      card_holder: 'Card Holder',
      credit_card: 'Card',
      currency: 'Currency',
      amount: 'Amount',
      net_amount: 'Net Amount',
      influencer_amount: 'Stars',
      total_amount: 'Total',
      vat: 'VAT',
      vat_country: 'Country VAT',
      fee: 'Fee',
      exes: 'Expenses',
      user_transaction: 'Transaction Id: {transaction}',
      user_transaction_gateway: 'Gateway: {gateway}',
      user_transaction_mail: 'Email: {mail}',
      user_country: 'Country: {country}',
      user_ip: 'IP: {ip}',
      items: 'Articles',
      unique: 'One charge',
      types: {
        affiliates: 'Affiliates',
        tips: 'Tip',
        subscriptions: 'Subscription',
        posts: 'Post',
        products: 'Product',
        wallets: 'Wallet',
        conferences: 'Live',
        chats: 'Chat',
        back_friday: 'Bonus (Black Friday)',
        cart: 'Cart',
        carts: 'Cart',
        agents: 'Creators',
        conversations: 'Conversations',
        conversation_messages: 'Message',
        subscription_bonus: 'Sub. Bonus',
        august_bonus: 'August Promo',
        videochats: 'Videochats',
        halloween_bonus: 'Halloween',
        black_friday_2022: 'Black Friday',
        valentine_2023: 'San Valentín',
        valentine_2024: 'San Valentín 2024',
        new_year_2023: 'New Year',
        carnival_2023: 'Carnival',
        avn_seller_2024: 'AVN2024',
        hosting: 'Hosting',
        spring_2024: 'Spring',
        summer_2023: 'Verano',
        july_2023: 'July 2024',
      }
    },
    comment: {
      comment: 'Comment',
      placeholder: 'Leave a comment'
    },
    withdraw: {
      automatic_payments: 'Automatic Payments',
      date: 'Date',
      uuid: 'Id',
      payoutDate: 'To be payed',
      type: 'Type',
      user: 'User',
      name: 'Name',
      amount: 'Amount',
      fee: 'Fee',
      tax: 'IRPF',
      vat: 'VAT',
      total_amount: 'Net amount',
      actions: 'Actions',
      status: 'Status',
      user_email: 'Email: {email}',
      user_bank_user: 'Owner: {name}',
      user_bank_user_country: 'Country: {country}',
      user_bank_name: 'Bank: {name}',
      user_bank_country: 'Bank country: {country}',
      user_bank_currency: 'Currency: {account}',
      user_bank: 'Acc. Number: {account}',
      user_swift: 'SWIFT / BIC: {account}',
      user_paypal: 'PaypalId: {paypal}',
      user_western: 'Western: {western}',
      email: 'Email: {email}',
      full_name: 'Fullname: {name}',
      country: 'Country: {country}',
      bank_name: 'Bank: {name}',
      bank_country: 'Bank Country: {country}',
      bank_currency: 'Currency: {currency}',
      bank_account: 'Bank account: {account}',
      bank_aba: 'Routing Number (ABA): {aba}',
      bank_swift: 'SWIFT / BIC: {swift}',
      account_id: 'Account: {account}',
      account: 'Account:',
      title_info_payment: 'Your selected payment method:',
      title_info_holder: 'Data of the collection holder:',
      data_colletion: 'Collection data:',
      pending_week: 'Pending this week=',
      pending_bonus: 'You have bonuses pending to be unlocked',
      available_today: 'Available to pick up today=',
      attention: '!ATTENTION!',
      msg_attention: 'CHECK YOUR PERSONAL AND COLLECTION DATA BEFORE REQUEST THE WITHDRAWAL',
      min_withdraw_msg: 'Remember that the <strong> minimum </strong> withdrawal amount is',
      title: 'WITHDRAW FUNDS',
      button_tutorial: 'How does it work?',
      move_funds_title: 'TRANSFER BETWEEN YOUR WALLETS',
      move_funds_available: 'Available to move',
      move_funds_all_funds: 'Total balance',
      move_funds_text: 'Move your funds from <strong> {currentInit} </strong> to <strong> {currentEnd} </strong>',
      payment_types: {
        bank: 'Bank',
        western: 'Western Union',
        paypal: 'PayPal'
      },
      statuses: {
        all: 'All',
        pending: 'Pending',
        processing: 'Processing',
        auto_processing: 'Automatic Pending',
        auto_pending: 'Automatic Pending',
        commited: 'Commited',
        rejected: 'Rejected',
        canceled: 'Canceled',
        accepted: 'Accepted'
      },
      trusted: {
        true: {
          title: 'Verify Trusted creator',
          text:
            'You are going to verify <strong>{user}</strong> as a <strong>trusted user</strong>, this process assumes that he has passed the tests and can receive payments normally.'
        },
        false: {
          title: 'Remove Trusted Creator',
          text:
            'You are going to remove the <strong>Trusted status</strong> to <strong>{user}</strong>, this process assumes that he has not passed the tests and should be verified again.'
        }
      }
    },
    withdraw_new_method: {
      paxum: {
        email: 'Email',
        account: 'Account holder'
      },
      transfer_wise: {
        email: 'Account',
        account: 'Account holder'
      },
      paypal: {
        email: 'Account',
        account: 'Account holder'
      },
      cosmo: {
        email: 'Account',
        account: 'Owner'
      },
      btc: {
        email: 'Wallet address',
        account: 'Account holder'
      }
    },
    withdraw_method: {
      currency: 'Currency',
      account_id: 'Account ID',
      cosmo_account: 'COSMO 9 or 10-digit SAN',
      e_pay_account: 'ePayServices wallet ID',
      paxum_account: 'Email of your Paxum\'s account',
      paypal_account: 'Paypal ID or email associated',
      skrill_account: 'Email of your Srill\'s account',
      transfer_wise_account:
        'Your Transferwise account number or email associated',
      btc_account: 'Coinbase email or BTC wallet address',
      western_account: 'Western Union ID'
    },
    withdraw_bank_new_info: {
      iban_number: 'IBAN',
      swift_number: 'SWIFT / BIC',
      institution_code: 'Institution Code',
      bank_name: 'Bank',
      bank_currency: 'Currency',
      routing_number: 'ABA/Routing',
      transit_number: 'Transit Number'
    },
    withdraw_bank_info: {
      bank_account: 'Bank account number',
      iban_number: 'IBAN',
      swift_number: 'Bank SWIFT / BIC',
      institution_code: 'Institution Code',
      bank_country: 'Bank country',
      bank_name: 'Bank name',
      bank_currency: 'Bank Currency',
      routing_number: 'ABA Routing Number',
      transit_number: 'Transit Number'
    },
    wallet: {
      date: 'Date',
      type: 'Type',
      source: 'From',
      base: 'Base price',
      amount: 'Amount',
      added_amount: 'Shipping',
      vat: 'VAT',
      fee: 'Fee',
      exes: 'Expenses',
      total_price: 'Total',
      total: 'Wallet Total'
    },
    referral: {
      username: 'User',
      date: 'Date',
      fee: 'Fee',
      role: 'Role',
      totalFee: 'Total Fee'
    },
    ranking: {
      postsCount: 'Posts',
      earningCount: 'Earned',
      fansCount: 'Fans'
    }
  },

  types: {
    birthdate: 'Birthdate',
    currency: {
      format_hint: 'Enter the amount in this format: XXXX,YY',
      minValue:
        'Default value for {name}: {quantity} (Write 0 or let blank to use default). Minimun value for {name}: {quantity}'
    },
    date: {
      format: 'MM/DD/YYYY',
      name: 'Date',
      day: 'Day',
      days: 'Days',
      month: 'Month',
      year: 'Year',
      hour: 'Hour',
      minute: 'Minute',
      hours: 'Hours',
      minutes: 'Minutes',
      second: 'Second',
      seconds: 'Seconds',

      invalid: 'The date is not correct'
    },
    datetime: {
      format: 'MM/DD/YYYY HH:MM:SS'
    },
    boolean: {
      true: 'Yes',
      false: 'No'
    },
    payment: {
      mail: 'Write the mail to send the payment',
      id: 'ID'
    }
  },

  labels: {
    filter_dates: 'Filter between dates',
    file_input: 'Select a file'
  },

  listview: {
    no_users_found: 'We couldn\'t find any STAR with \'{query}\'',
    no_posts_found: 'We couldn\'t find any publication with \'{query}\'',
    no_content: 'There is no content yet',
    no_posts: 'You haven\'t published anything yet',
    no_posts_visit: 'This STAR has not published any content yet',
    no_market: 'You don\'t have articles in the market yet',
    no_market_visit: 'This STAR doesn\'t have any article for sale yet',
    no_subscriptions: 'You are not subscribed to any Star yet',
    no_subscribers: 'You haven\'t got any Fan yet',
    no_conferences: 'There are no scheduled Lives at the moment',
    no_followers: 'You haven\'t got any follower yet',
    no_following: 'You are not following anybody yet',
    no_purchases: 'You haven\'t purchased any content yet',
    no_favorites: 'You haven\'t saved any content yet',
    no_follower_content: 'There are no recently added content',
    no_tutorials_content: 'There are no tutorials content',
    no_sales: 'There are no products for sale',
    no_auctions: 'There are no products for auctions',
    not_found: 'We couldn\'t find anything',
    no_notifications: 'You haven\t got notifications',
    no_comment: 'There are no comments',
    pagination: {
      per_page: 'Show per page'
    }
  },

  file_uploader: {
    uploaded: 'Uploaded',
    error: 'Error uploading file',
    retry: 'Retry',
    resume: 'Resume ({seconds})',
    max_images: 'one picture | {count} pictures',
    max_videos: 'one video | {count} videos',
    max_items_hint:
      'You can upload {items1} | You can upload {items1} or {items2}.',
    max_items_hint_promo:
      'Only videos in vertical format will be validated.',
    files_size_exceded:
      'The file { files } is too large.Max 6GB | The files { files } are too large.Max 6GB per file',
    max_video_size: 'Max video size is 6GB.',
    ios_preview_disabled:
      'Video preview is disabled on Safari browser. This won\'t affect video uploading.'
  },

  snackbar: {
    email_sent_success: 'Email sent to your account\'s email',
    email_sent_failed: 'Wait some minutes before requesting another email',
    item_delete_success: '{item} was deleted successfully',
    delete_success: 'Deletion was successful',
    report_success: 'Report was successful',
    create_success: 'Created successfully',
    create_error: 'Error creating',
    daily_limit_exceeded: 'Daily limit exceeded',
    update_success: 'Updated successfully',
    item_create_success: '{item} was created successfully',
    publish_success: 'Publication created successfully',
    item_publish_success: '{item} was successfully published',
    clipboard_copied: 'Link copied to clipboard',
    contact_success: 'Message sent successfully',
    save_success: 'Saved succesfully',
    request_success: 'Request sent successfully',
    cannot_process_request: 'The request cannot be processed. Try again later.'
  },

  alerts: {
    product_payment_required:
      'An auction finished and you have been the winner! Go to the product\'s page to make the payment and fill the delivery details.',
    product_delivery_required:
      'One of the products you have acquired doesn\'t have delivery details.'
  },

  errors: {
    _default_length_field: 'chars',
    not_found: 'Ops... You haven\'t found what you are looking for',
    no_min_amount: 'The min amount is {amount}',
    no_max_amount: 'The max amount is {amount}',
    required: 'This field is required',
    no_white_spaces: 'This field cannot include white spaces',
    no_html_tags: 'This field cannot include html tags',
    bad_email: 'Invalid email',
    email_in_use: 'This email is already in use',
    email_not_validated:
      'This eamil is already in use and it is not validated. You must validate your @:project account email before to link it to your Google\'s account.',
    bad_password:
      'The password must be at least 8 chars length, have at least a lowercase letter, a capital letter and a number',
    dont_match: 'Does not match',
    amount_too_low: 'Minimun amount is {amount}',
    not_enough_funds: 'You don\'t have enough funds in your wallet',
    min_value: 'Min value is {value}',
    max_value: 'Max value is {value}',
    no_min_length: 'At least {min} {field}',
    no_max_length: 'Cannot have more than {max} chars',
    no_min_max_length: 'The length must be between {min} and {max}',
    no_min_max_field_length:
      'The length of {field} must be between {min} and {max}',
    only_alphanumerics: 'Allowed chars are letters, numbers and \'_\'',
    only_integers: 'Only numbers are allowed',
    already_taken_username: 'This username is already in use',
    too_many_files: 'You have selected too many files. Max. {max} files',
    discarted_too_many_files:
      'Too many files selected (max {max} files). Some files has been discarted',
    files_size_exceded:
      'The file {files} is too large. Max 6GB | The files {files} are too large. Max 6GB per file.',
    mime_type_not_valid: 'The file{file} does not seem an image or video.',
    banned_word: 'One or more banned words detected: {word}',
    invalidNif: 'Invalid Document Number.',
    requiredText: 'Type any message to send',
    not_phone: 'Phone Numbers not allowed',
    no_links_allowed: 'Links not Allowed',
    over_18: 'You have to be over 18 years old'
  },

  remote_errors: {
    security_exception: 'You must be logged in to continue',
    bad_credentials: 'The username/email or password are incorrect',
    not_allowed: 'You cannot do this action',
    not_found: 'Ops... You haven\'t found what you are looking for',
    invalid_field: 'The field {field} is not valid',
    already_finished: 'Already finished',
    not_enough_funds: 'You don\' have enough funds',
    no_white_spaces: 'White spaces are not allowed',
    no_html_tags: 'HTML tags not allowed',
    does_not_match: 'Does not match',
    already_resolved: 'This withdraw is already resolved',
    lower_field: 'The amount must be greater than {min}',
    already_exists: '{field} already exists',
    must_be_adult: 'You must be an adult in your country to continue',
    must_accept_terms: 'Terms & Cond. must be accepted to continue',
    user_blocked: 'Your user is blocked',
    already_pending_withdraw: 'There already is a pending withdraw',
    dont_delete_acquired:
      'Cannot be deleted because it has been already acquired',
    dont_delete_premium: 'Cannot delete premium content at the moment',
    login_attempt_excedeed: 'You have reached the maximum logon attempts. Try again in 5 minutes.',
    invalid_username: 'The username is invalid',
    amount_reached: 'Max reloading amount is {reload}',
    daily_limit_reached: 'You have reached reloading daily limit: {daily}',
    bonus_code: 'Bonus Code doesn\'t exists or expired',
    invalid_captcha: 'Error creating the user, please try again or contact <a href="mailto:@:contact_email"><b>@:contact_email</b> </a>'
  },

  termsAndCond: {
    title: 'TERMS AND CONDITIONS',
    section1: {
      title: '1 IDENTIFICATION, ACCEPTANCE AND ACCESS TO THE WEBSITE',
      paragraph1: {
        paragraph1_1:
          'These Terms and Conditions regulate the access and use of the Web Site owned by ARTEMISA 3000 TECH SOLUTIONS S.L. (from now on, ',
        paragraph1_2: '@:project_upper',
        paragraph1_3:
          ') with NIF B-8 8 4 6 7 4 3 and address at CALLE GOYA, NUM 47 PLANTA 7, PUERTA 12 28001 MADRID (Valid address to receive correspondence)'
      },
      paragraph2:
        'You can contact @:project_upper through an email sent to the address: @:contact_email.',
      paragraph3: {
        paragraph3_1: 'The access to the Website @:site, (from now on, the',
        paragraph3_2: '"Website"',
        paragraph3_3:
          ') implies the acceptance of these Terms and Conditions, as well as the different modifications and/or additional legal texts that @:project_upper may include in the future.'
      },
      paragraph4:
        '@:project_upper offers the possibility of viewing streaming videos of adult and sexual content through the Website. The User is interested in hiring the services of @:project_upper in order to enjoy the content available through the Website.',
      paragraph5: {
        paragraph5_1: 'In addition, content providers ("',
        paragraph5_2: 'Influencers',
        paragraph5_3:
          '"), may create their own channel where they upload videos with adult and sexual content for Users to enjoy in streaming through the Website.'
      },
      paragraph6:
        'Access and navigation through the Website will require registration of Users and Influencers.'
    },
    section2: {
      title: '2 OBJECT',
      paragraph1:
        'The purpose of these Terms and Conditions is to regulate the conditions under which @:project_upper will provide its services to Users and Influencers through the Website.',
      paragraph2:
        'The services that @:project_upper will provide to the User will consist of making available streaming videos of sexual content for their enjoyment. On the other hand, the videos offered on the Website, will be videos provided by the Influencers through its own channel located on the Website.',
      paragraph3:
        'The content of the services includes videos, audios (e.g. music and other sounds), graphics, text, photos, etc. The content of the videos is the sole responsibility of the Influencer that has uploaded the video to its Channel hosted on the Website. If any User or Influencer would like to report any content that violates these Terms and Conditions, they can do so through the Report Channel or at the following email address @:info_email.',
      paragraph4:
        'Also, the description of the service offered to the Users, its characteristics, and the different modalities of contracting are determined in the Web site @:site.'
    },
    section3: {
      title: '3 WEB SITE OPERATION ',
      paragraph1:
        'The User and Influencer must create a profile through a login by completing a registration form, which will include an email, password and username.',
      paragraph2:
        '@:project_upper will proceed to assign a login and password to the User and Influencer for access to the services available on the Website. This login and password allow the authentication of the User for access to the Website, which @:project_upper makes available. @:project_upper uses this system to identify, authenticate and verify the person of the User.',
      paragraph3: {
        paragraph3_1:
          'Once registered the User and Influencer will have access to all the contents of the Website, depending on the type of account selected:',
        paragraph3_2: 'Free, Monthly or Video to Video.'
      },
      paragraph4:
        'The contents of the Website will be organized according to the following parameters: most viewed videos, latest news, free and channels of each of the Influencers.'
    },
    section4: {
      title: '4 WEBSITE ACCOUNTS',
      section1: {
        title: '4.1 USER ACCOUNT',
        paragraph1:
          'In order to access the content published on the Website, the User must create an account through an online form that must be filled in with the data required on the form. They can also sign up via Twitter.',
        paragraph2:
          'Users will have full responsibility for the use of their User account. Consequently, Users are responsible for the proper custody and confidentiality of the User name and/or passwords, as well as all data allowing access to their account, and agree not to transfer their use to third parties, either temporarily or permanently, nor to allow access to them by third parties.',
        paragraph3:
          'By virtue of the above, users must notify immediately to @:project_upper via email @:info_email any misuse of your username and / or password, due to circumstances such as theft, loss or unauthorized access to them, so that @:project_upper can proceed to its cancellation or blocking and / or disabling as soon as it is aware of the improper use of your user account. While not reported such facts, @:project_upper will be exempt from any liability that may arise from the misuse of usernames or passwords by unauthorized third parties.'
      },
      section2: {
        title: '4.2 INFLUENCERS\' ACCOUNT',
        paragraph1: {
          paragraph1_1:
            'Influencers to provide content to the Web site must be registered on the Web site (like the Users), and once registered, they must access the section "Influencer Account" where they must ',
          paragraph1_2:
            'complete their registration by uploading a personal photo to the Web site showing the Influencer and their ID, and ',
          paragraph1_3:
            'select the form of payment of fees: via bank transfer or debit card. In addition,',
          paragraph1_4:
            'it is a requirement for the use of the Influencer Account to be of legal age (18 years), or 21 years of age, depending on the age of majority in your country of origin.'
        },
        paragraph2:
          'Once they have successfully completed the registration by meeting all the above requirements, the Influencer may begin to upload content to its Channel for viewing by Users',
        paragraph3:
          'Influencers will have full responsibility for the use of their Account and their Channel. Consequently, the Influencers are responsible for the proper custody and confidentiality of the User name and/or passwords, as well as all data, which allows access to your account. The Influencers are exclusively responsible for all the content videos, images, sounds, texts, graphics, music, audios, etc. that they upload to their channel. In this sense, @:project_upper is not responsible for the inclusion by the Influencers in the videos of their channel of any content that allows their personal identification (objects or personal contact details, among others).',
        paragraph4: {
          paragraph4_1:
            'In this sense, the Influencer will have full responsibility for its content and the consequences of publishing it. The Influencer guarantees that:',
          paragraph4_2:
            'it is the creator and owner of its Influencer Channel Content,',
          paragraph4_3:
            'its Influencer Content will not infringe, violate, or usurp any rights of any third party, including copyrights, trademarks, patents, trade secrets, moral rights, privacy rights, image rights, or any other intellectual property rights, or defame any other person; and ',
          paragraph4_4:
            'its Influencer Content does not contain viruses, adware, spyware, worms, or any other harmful or malicious code.'
        },
        paragraph5:
          'The Influencer is fully responsible that the Content uploaded and published through its Channel does not infringe the intellectual or industrial property rights of third parties, as material protected by copyright',
        paragraph6:
          '@:project_upper will take reasonable security measures to attempt to protect the Content of the Influencer from unauthorized copying and distribution. Notwithstanding the foregoing, @:project_upper does not guarantee that such unauthorized copying, use or distribution of the Influence Content by third parties will not take place. The Influencer agrees that @:project_upper is not responsible for any copying, use or distribution of the Influencer Content by third parties, and disclaims all liability and claims against @:project_upper for any unauthorized copying or use of the Influencer Content. @:project_upper does not guarantee that the security measures can resist attempts to circumvent the security mechanisms or that there will be no cracks, deactivations or other ways to circumvent these security measures',
        paragraph7:
          'The Influencer can remove the videos you want from your Channel at any time, as well as publish as many videos as you want without restriction, always within the law',
        paragraph8:
          'Under the above, the Influencers must immediately notify @:project_upper via email @:info_email any misuse of your Influencer name and / or password, due to circumstances such as theft, loss or unauthorized access to them, so that @:project_upper can proceed to its cancellation or blocking and / or disabling as soon as it knows the improper use of your Influencer Account. @:project_upper will be exempt from any responsibility that could derive from the improper use of the names of Influencers or passwords by third parties not authorized.'
      }
    },
    section5: {
      title: '5 CONDUCT OF USERS AND INFLUENCERS ON THE WEBSITE',
      paragraph1:
        'The Users and Influencers commit to make a licit, diligent, honest and correct use of all the information or contents that they have access to through the Web site, and all this under the principles of good faith and respecting at all times the current legality and the present Terms and Conditions. It is not permitted to download the Content offered through the Website',
      paragraph2:
        'In particular, but without limitation, Users and Influencers shall not:',
      list1:
        'Register or communicate data that is not true, accurate, complete and/or updated, nor access the Website using the name, identification data or password of another User or influence or impersonate any person or identity',
      list2:
        'Use the Website for fraudulent purposes, or in connection with criminal offences or unlawful activities of any kind ',
      paragraph3:
        '@:project_upper reserves the right to deny any attempt to access the Website, cancel accounts, remove or modify content, or cancel the provision of Services in cases where there is an incorrect use of the Website',
      section5_1: {
        title: '5.1 PUBLICATION OF CONTENT',
        paragraph1:
          'Influencers will be able to publish content of different types and with different viewing permissions.',
        paragraph2:
          'The contents should not violate the law and may be reviewed by @:project_upper and withdrawn if deemed necessary for legal reasons, moral or others.',
        section5_1_1: {
          title: '5.1.1 Multimedia Contents',
          paragraph1:
            'These are Images, Videos or Texts published on the website by the Influencers. They can be made available to followers in different ways, including free viewing, pay per view, content for subscribers, paid content focused on specific users. The modalities of consumption of the service may be modified at the will of @:project_upper.',
          paragraph2:
            'Once the User has paid for the paid content, he will be able to view this content through the Website.'
        },
        section5_1_2: {
          title: '5.1.2 Products in store',
          paragraph1:
            'The products offered for sale in the store may be offered for sale directly, or the Influencer may assign a price to the product for direct sale, or auction it, assigning a minimum price at which users may equal or exceed, obtaining the product the user who has offered the highest price for the product.',
          paragraph2:
            'The Influencer shall be responsible for delivering the product to the buying User in an efficient and diligent manner within a reasonable time. The shipping method and a tracking number or detailed information may be indicated on the platform to the User who purchased the product.',
          paragraph3:
            'The maximum delivery time of the products will be 15 days, unless the Influencer indicates another term in the product description.',
          section5_1_2_1: {
            title: '5.1.2.1 Social Media Publishing',
            paragraph1:
              'The user will be able to link his Social Networking accounts with his @:project user profile',
            paragraph2:
              'For those accounts linked to your profile, @:project may publish messages related to your activity on @:project.'
          },
          section5_1_2_2: {
            title: '5.1.2.2 Prohibited products',
            paragraph1:
              'The sale of products that fall into any of the following categories is expressly prohibited:',
            list1: 'Any type of Pharmacological Product',
            list2: 'Any type of Vitamin Product',
            list3: 'Restorative or recommended for health',
            list4: 'Any type of birth control method'
          }
        }
      },
      section5_2: {
        title: '5.2 PROHIBITED CONTENTS',
        paragraph1:
          'Videos or photographs containing any of the following will not be allowed to be published',
        list1: 'Copyrighted material.',
        list2: 'Drugs or any related items.',
        list3: 'Content that incites hatred or violence.',
        list4: 'Child pornography.'
      },
      section5_3: {
        title: '5.3 SUSPENSION AND CANCELLATION OF THE ACCOUNT ',
        section5_3_1: {
          title: '5.3.1 Cancellation of the account by the User/Influencer',
          paragraph1:
            'The User may stop using the Service at any time. However, if the User has contracted the Monthly Rate, he must communicate @:project_upper in writing his decision not to continue with the services offered by @:project_upper',
          paragraph2:
            'The Influencer can remove Content from your Influencer account, which means closing your @:project_upper channel with the option to first download a copy of your Content. '
        },
        section5_3_2: {
          title: '5.3.2 Cancellations and suspensions by @:project_upper',
          paragraph1:
            '@:project_upper may suspend or cancel access to the User/Influence account if: ',
          list1:
            'are in breach of any of the obligations described in these Terms and Conditions;',
          list2:
            'when @:project_upper must do so to comply with a legal requirement or a court order;',
          list3:
            'when @:project_upper has reasonable grounds to believe that there has been conduct by the User or the Influencer that gives rise to liability or damage to a User, a third party, or @:project_upper'
        }
      }
    },
    section6: {
      title: '6 PRICES AND PAYMENT ',
      paragraph1:
        'All prices of the Services offered through the Website are expressed in Euros and do not include the taxes corresponding to the country where the purchase originates. Before making the payment we will inform you of the taxes applicable in your country to make the payment and be able to offer you the corresponding documentation.',
      section6_1: {
        title: '6.1 PRICES AND PAYMENT BY USERS',
        paragraph1:
          'The User will be able to enjoy the contents offered on the Website, depending on the package selected:',
        section6_1_1: {
          title: '6.1.1 Free content',
          paragraph1:
            'Content made available to the User free of charge, limited to 1000 monthly views of free content, this figure may vary at the discretion of @:project_upper. This content will be available to registered Users on the platform.'
        },
        section6_1_2: {
          title: '6.1.2 Payment Content',
          paragraph1:
            'The User, each time he wants to visualize one of the paid contents under this modality, will have to pay the price assigned to that content. Once the payment has been made, the content will be available to the User through the platform',
          paragraph2:
            'The service is provided immediately after payment, giving access to premium web content. All content is distributed exclusively through the website.'
        },
        section6_1_3: {
          title: '6.1.3 Content Subscription',
          paragraph1:
            'In this modality the User will be able to make the monthly payment of a fee to be able to subscribe to the contents published by each Influencer. The price of the subscription will be established by the Influencer. This fee may be updated by the Influencer, which will imply the express acceptance of the new fee by the User to continue subscribing to the contents of the Influencer',
          paragraph2:
            'The payment of this fee will give the User access to the contents published by the Influencer as long as the subscription is active and up to date.',
          paragraph3:
            'The MONTHLY rate will be for one month from the time of the contract, renewable for periods of equal duration, unless the User cancels the subscription within 15 working days before the renewal of the rate.'
        },
        section6_1_4: {
          title: '6.1.4 Methods of Payment',
          paragraph1:
            'The User may pay the monthly fee or video to video by the means of payment accepted on the Website: Payment by bank card.',
          paragraph2:
            'Within a maximum period of twenty-four (24) hours, @:project_upper will send an email to the User, confirming the details of the contracted service, its price and the details of the option chosen to make the payment for the service offered, as well as the corresponding proof once the payment has been formalized, that is to say, after the acceptance of these Terms and Conditions and the corresponding payment',
          paragraph3:
            'Users are guaranteed that the payment process is carried out with absolute security, in accordance with the protocols and security services required',
          paragraph4:
            'The User must notify @:project_upper of any undue or fraudulent charges in the method of payment used for the provision of services by sending an email to @:info_email, in the shortest possible time so that @:project_upper can make the appropriate arrangements.'
        }
      },
      section6_2: {
        title: '6.2 RETURN POLICY',
        paragraph1:
          'Given that we have informed the user of the prices and conditions of our products and he has accepted the terms and conditions contained in this text, we inform that each claim will be evaluated individually before making any return. By default, only duplicates charges made by the payment gateway will be refunded. When the user has not carried out the automatic unsubscription process within the established period (a user can cancel their subscription for the next renewal period, never for the current one), no refund will be made. Please contact @:info_email to make any claims. If accepted, it will be available between the next 10 working days after confirmation.',
        paragraph2:
          'Subscriptions can be cancelled at any time through the website, being active for the period already charged and until the end of it',
        paragraph3:
          'In the event that a refund is approved, you will have the amount available on your card within a maximum of 10 working days from confirmation. No refunds are made through other means of payment such as checks or cash.'
      },
      section6_3: {
        title: '6.3 REMUNERATION AND PAYMENT TO INFLUENCERS',
        paragraph1:
          'The Influencer will offer to the Users through its own channel hosted in the Web site, the multimedia Contents and Products in store that it considers, always within the Terms and Conditions of the present document. These publications and / or sales / auctions will generate revenues that will be received by @:project_upper by the methods of payment indicated',
        paragraph2:
          'In this sense, the following distribution of the total income obtained is defined according to the origin of the income:',
        list1:
          'Of the income obtained on the platform, the Influencer will receive 80% of the total and @:project the remaining 20%.',
        paragraph3:
          'The Influencer may request the corresponding remuneration as long as it has a minimum credit balance of 100 euros',
        paragraph4:
          'In the case that the Influencer needs an invoice in relation to the invoicing of its services, you should contact the Accounting Department of @:project_upper, at the following email address: @:info_email',
        paragraph5:
          '@:project_upper will allow Influencer the possibility of being paid through a debit card. This method must be requested by the Influencer through its private area of its profile, specifically in the payment options.'
      }
    },
    section7: {
      title: '7 INFORMATION ABOUT @:project_upper SERVICES',
      paragraph1:
        'The data included on the Website in the descriptions of the Services have a purely informative function.',
      paragraph2:
        'Therefore, @:project_upper declines any responsibility for the appearance of errors in this information, but undertakes to take all measures within its power to correct, as soon as possible, such errors or omissions after being informed of them',
      paragraph3:
        'It is not allowed to download the Contents published through the Website.',
      section7_1: {
        title: '7.1 PUBLISHING/FOLLOWING UP SOCIAL NETWORKING',
        paragraph1:
          '@:project_upper makes available to the Influencers a Social Network platform in which through the same may communicate with users, publish content in text and image format, always with the compliance of these Terms and Conditions regarding content (point 5.2)'
      },
      section7_2: {
        title: '7.2 MESSAGING CHANNEL',
        paragraph1:
          '@:project_upper offers users a messaging channel where users can send messages to the Influencers and interact with them in cases of purchase of a product through the established methods or requests related to the videos of the Influencer',
        paragraph2:
          'The Influencers will set a price on each of the messages. Also, Influencers will be able to modify the price rates of the messages with the Users. In the event that the Influencer changes the price of the message, the User must accept the new rate and continue the conversation by making the corresponding payment.',
        paragraph3:
          'The User who wishes to communicate with the Influencer will make the payment with the funds from his wallet and each message will be paid at the time of generation.',
        paragraph4:
          'At any time, one of the two parties may terminate the conversation.'
      },
      section7_3: {
        title: '7.3 PRODUCT AND SERVICE SHOP',
        paragraph1:
          '@:project_upper offers to Influencers a platform for the sale of products and / or services self-managed. Through it the Influencers will be able to sell the products and/or services that they consider, always within the scope of the current legality.',
        paragraph2:
          'For the purchase of products, the User must be registered on the Website, as well as carry out the payment by any of the payment methods offered by @:project_upper.'
      },
      section7_4: {
        title: '7.4 AUCTION PLATFORM',
        paragraph1:
          '@:project_upper offers users the opportunity to participate in different bids for some of the products that may appear in the videos of the Influencers. The User must be registered on the Website to participate in an auction. The bidding period and its conditions will be included in the mentioned form.'
      },
      section7_5: {
        title: '7.5 LIVE VIDEO PUBLISHING PLATFORM',
        paragraph1:
          '@:project_upper offers to Influencers a platform for the publication of multimedia contents (video and audio) in real time, contents that can be enjoyed by the Users of the platform.',
        paragraph2:
          'The contents may be published according to the different privacy and payment methods described in this document and always in compliance with these Terms and Conditions regarding contents (point 5.2).'
      }
    },
    section8: {
      title: '8 INTELLECTUAL PROPERTY',
      section8_1: {
        title:
          '8.1 INTELLECTUAL AND INDUSTRIAL PROPERTY RELATING TO THE WEBSITE FOR USERS',
        paragraph1:
          'All rights to the content, design and source code of this website and in particular, but not limited to, all rights to logos, designs, trademarks, trade names, data included in the website and any other rights of intellectual property are owned by @:project_upper, or third parties who have expressly authorized @:project_upper for the use of them on its website',
        paragraph2:
          'Therefore, and by virtue of the provisions of Royal Legislative Decree 1/1996, of April 12, 1996, which approves the revised text of the Intellectual Property Law, regularizing, clarifying and harmonizing the legal provisions in force on the subject, as well as in Law 17/2001, of December 7, 2001, on Trademarks and complementary legislation on intellectual and industrial property, is expressly prohibited the reproduction, transmission, adaptation, translation, distribution, public communication, including its method of making available, all or part of the contents of the Website owned by @:project_upper, in any medium and by any technical means, unless express permission is granted in writing by @:project_upper',
        paragraph3:
          '@:project_upper does not grant any license or authorization of use of any kind on their rights of intellectual property or any other property or right related to the Website, and in no case shall be construed that access and navigation of users implies a waiver, transmission, license or transfer all or part of such rights by @:project_upper',
        paragraph4:
          'Any use of these contents not previously authorized by @:project_upper will be considered a serious breach of intellectual or industrial property rights and will give rise to the legally established responsibilities'
      },
      section8_2: {
        title:
          '8.2 INTELLECTUAL AND INDUSTRIAL PROPERTY REGARDING THE WEBSITE IN RELATION TO THE INFLUENCERS',
        paragraph1:
          'The Influencers are the owners of all the Content published by them in their Channel and, therefore, owners of the intellectual and industrial property rights that may correspond to them, by virtue of the provisions of the Intellectual Property Law, regularizing, clarifying and harmonizing the legal provisions in force on the subject, as well as in Law 17/2001, of December 7, 2001, on Trademarks and complementary legislation on intellectual and industrial property',
        paragraph2:
          'At no time, @:project_upper will be held legally responsible for any infringement of any unauthorized published Content protected by law',
        paragraph3:
          'However, if @:project_upper has reasonable grounds to believe that the Content published by the Influencer in its Channel, breaches the provisions of these Terms and Conditions or that may generate a damage to @:project_upper, our Users or third parties, @:project_upper may remove or disable such Content, either in part or in whole. In this case, @:project_upper will inform the Influencer of the reason for this action, unless it has reasonable grounds to believe that doing so would: violate the law or the guidelines of a competent authority, or would entail a legal responsibility for @:project_upper'
      }
    },
    section9: {
      title: '9 AVAILABILITY AND INTERRUPTION OF SERVICE',
      paragraph1: {
        paragraph1_1:
          'Access to the Website is available 24 hours, every day of the year, being expressly accepted by the User and Influencer that @:project_upper reserves the right to suspend access to the service particularly in the following cases: ',
        paragraph1_2:
          'for the maintenance needs of the Website (including the corresponding updates),',
        paragraph1_3:
          'for the improvement and installation of new functionalities of the Website,',
        paragraph1_4:
          'for the verification of the functioning and use of the Website, as well as ',
        paragraph1_5:
          'to solve any incidence in case of breakdown or possibility of breakdown. '
      },
      paragraph2:
        'In no case, @:project_upper will compensate the User or Influencer with any compensation or payment of any amount, as a result of the facts indicated in this Clause.',
      paragraph3: {
        paragraph3_1: 'The User and Influencer acknowledge and accept ',
        paragraph3_2: 'the characteristics and limits of the Internet,',
        paragraph3_3:
          'that the confidentiality of data transmitted over the Internet cannot be fully guaranteed and that this data may be intercepted by malicious users, ',
        paragraph3_4:
          'and that the response times of the services may be affected by network malfunctioning.'
      }
    },
    section10: {
      title: '10 EXCLUSION OF LIABILITY',
      paragraph1:
        '@:project_upper does not give any guarantee nor is responsible, in any case, for damages of any kind that may arise from access or use of the contents of the Website. Among others, and by way of illustration and not limitation, @:project_upper is not responsible for the following circumstances: ',
      list1:
        'For the lack of availability, maintenance and effective operation of the Website and/or its services or content, excluding, to the maximum extent permitted by current legislation, any liability for damages of any kind that may be due to the lack of availability or continuity of operation of the Website.',
      list2:
        'Of the lack of usefulness of the Website or the contents for any service',
      list3:
        'Of the content of other Internet pages to which links placed on the Website may be directed',
      list4: 'From third parties who might appear in the Influencer videos',
      list5:
        'Of the illicit, negligent, fraudulent use, contrary to the present Terms and Conditions or to the good faith, of the Website or its contents, on the part of the Users and Influencers, including any infraction of the rights of Intellectual and/or Industrial Property of @:project_upper or of third parties',
      list6:
        'Of the incidents caused by the lack of due diligence by the User and Influencer, as well as the improper use of the Website by any of them. ',
      list7:
        'Of the publication through the Website of the comments and opinions that infringe the rules mentioned in these Terms and Conditions, the applicable legislation or the rights of third parties',
      list8:
        'Of the cases of force majeure, understood as the failure, suspension or interruption of services or use of the Website as a result of energy restrictions, blocking of telecommunications or the Internet network, actions or omissions of third parties, telecommunications operators or service companies, supply or transport or any other cause or circumstance beyond the control of @:project_upper that prevent the normal use of the Website.'
    },
    section11: {
      title: '11 HYPERLINKS',
      paragraph1:
        'The Website may contain hyperlinks that allow the User to access third party websites. @:project_upper assumes no responsibility for the content, information or services that may appear on these sites, which will be offered exclusively for information purposes by @:project_upper, and in no case imply any relationship, acceptance or support between @:project_upper and the persons or entities owners of such content or owners of the sites where they are'
    },
    section12: {
      title: '12 DISPUTE RESOLUTION',
      paragraph1:
        'These Terms and Conditions are subject to Spanish law. The parties, in accordance with the Royal Legislative Decree 1/2007, of 16 November, which approves the revised text of the General Law for the Defence of Consumers and Users and other complementary laws, are subject to the Courts and Tribunals of the User\'s place of residence',
      paragraph2:
        'On the other hand, for the interpretation and resolution of the conflicts that could arise between the Influencer and @:project_upper for any discrepancy, question or claim resulting from the execution or interpretation of the present Terms and Conditions they are submitted to the jurisdiction of the Courts and Tribunals of Madrid Capital'
    },
    section13: {
      title: '13 MODIFICATIONS',
      paragraph1:
        '@:project_upper reserves the right to make as many changes as it deems appropriate in these Terms and Conditions, in which case they will be communicated in advance to users',
      paragraph2:
        'These modifications will be valid from the moment they are published on the Website.'
    },
    section14: {
      title: '14 SAFEGUARD CLAUSE',
      paragraph1:
        'All the clauses or extremes of these Terms and Conditions must be interpreted independently and autonomously, the rest of the stipulations not being affected in case one of them has been declared null and void by a judicial sentence or final arbitration resolution. The affected clause or clauses will be replaced by another or others that preserve the effects sought by the Terms and Conditions'
    }
  },

  cookies_v2: {
    title: 'Cookies Policy',
    desc_1: 'The following cookie policy (hereinafter, the “<b>Política de Cookies</b>”)is an enclosed aspect of the Privacy Policy by Artemisa3000 Tech Solutions, S.L., with Tax Identity Number B88466743 and registered office at Calle Goya nº47, Planta 7, Pta. 12, 28001, Madrid, Spain (hereinafter, “<b>Artemisa</b>”).',
    desc_2: 'The information contained in this Cookie Policy allows you to consult the different cookies or other data storage and recovery devices of similar functionality in detail (hereinafter, the “<b>Cookies</b>”) that may be installed in your browser as a result of accessing and using the website {linkSite} ((hereinafter, the “<b>Web Page</b>”).',
    desc_3: 'In this sense and intending to guarantee all the necessary information for correct browsing, we provide you with the following informative text on what cookies are, what types of cookies are used on our Website and how they can be managed or disabled.',
    section_1: {
      title: 'WHAT IS A COOKIE?',
      desc_1: 'Cookies are files containing small amounts of information which are downloaded to your device when you visit a website',
      desc_2: 'Cookies are essential for the functioning of the Internet, as they offer technical solutions that allow your navigation; they cannot damage your equipment/device and, if activated in your browser settings, help identify and resolve possible errors in the Website’s functionality. They may also be used for advertising or analytical purposes.'
    },
    section_2: {
      title: 'USE OF COOKIES BY @:project_upper',
      desc_1: 'You can consent to use this type of cookies on your devices by accessing the Website. If you disable cookies, your browsing of the Website may not be optimal and some of the functionalities available on the Website may not work correctly',
      desc_2: 'Specifically, @:project uses first-party cookies and third-party cookies, belonging to third party companies, for the specific purposes outlined hereunder. If @:project decide to use other cookies in the future, you will be informed of this',
      part_1: {
        title: 'Technical Cookies',
        desc_1: 'These cookies are necessary for the Website to function and cannot be disabled on our systems. They are generally used in response to actions carried by the user that amount to a request for services, such as setting your privacy preferences, login in or completion of forms.',
        desc_2: 'For the installation of cookies which are necessary to activate the functionalities of the Website, your consent is not required, since their use is based on the need to provide the requested services. You can set your browser to block or inform you of the presence of these cookies, but browsing the Website may not be optimal and some of the utilities may not work correctly.',
        desc_3: 'We use the following cookies and service providers:',
        table_1: {
          title: 'TECHNICAL COOKIES',
          header_1: {
            title: 'Service Provider',
            desc_1: 'Artemisa',
            desc_2: 'Twitter'
          },
          header_2: {
            title: 'Additional Information',
            desc_1: 'Session Cookies.<br>User Language.<br>Referral.<br>SingUp Tracking.',
            desc_2: '<a href="https://developer.twitter.com/en/docs/authentication/oauth-1-0a/obtaining-user-access-tokens" target="_blank">https://developer.twitter.com/en/docs/authentication/oauth-1-0a/obtaining-user-access-tokens</a>'
          },
          header_3: {
            title: 'Expiry',
            desc_1: '1 year y 1 month<br>1 year y 1 month<br>3 hours<br>1 hour',
            desc_2: '1 year y 1 month'
          }
        }
      },
      part_2: {
        title: 'Performance Cookies',
        desc_1: 'These Cookies collect information about how you use the Website, storing, for example, which pages you visit most often, whether you receive error messages from web pages, which sections of the Website you access or which buttons on the Website you click. All information obtained is aggregated and therefore anonymous. The function of these Cookies is to obtain statistics and reports that allow us to improve the functioning of the Website.',
        desc_2: 'Among the analytical cookies used are Google Analytics cookies. Google Analytics uses its own cookies. These cookies do not collect information that identifies you. They are only used to improve the Website´s performance. You can find more information about Google Analytics Cookies here:',
        cookies: '<a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies" target="_blank">https://developers.google.com/analytics/resources/concepts/gaConceptsCookies.</a>',
        desc_3: 'You can prevent the use of Google Analytics in connection with your use of our Website by downloading and installing the browser opt-out available through this link:',
        analytics: '<a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=en-GB<a/>',
        desc_4: 'We use the following cookies and service providers:',
        table_1: {
          title: 'PERFORMANCE COOKIES',
          header_1: {
            title: 'Service Provider',
            desc_1: 'Google (Analytics, Optimize and GTM)',
            desc_2: 'Facebook (Only Loverfans)'
          },
          header_2: {
            title: 'Additional Information',
            desc_1: '<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy – Privacy & Terms – Google</a>',
            desc_2: '<a href="https://www.facebook.com/privacy/policy/" target="_blank">Privacy Policy</a>'
          },
          header_3: {
            title: 'Expiry',
            desc_1: '1 year y 1 month',
            desc_2: '3 months'
          }
        },
        desc_5: 'These Cookies are only installed on your device when you accept them.'
      },
      part_3: {
        title: 'Personalization Cookies',
        desc_1: 'These Cookies allow you to access the service with some predefined general characteristics depending on a series of criteria in your device such as, for example, the language, the type of browser through which you access the service, the regional configuration from where you access the service, etc',
        desc_2: 'The specific personalization Cookies and their providers are listed below:',
        table_1: {
          title: 'PERSONALIZATION COOKIES',
          header_1: {
            title: 'Service Provider',
            desc_1: 'Artemisa'
          },
          header_2: {
            title: 'Additional Information',
            desc_1: 'Preference cookies<br>Views Preferences'
          },
          header_3: {
            title: 'Expiry',
            desc_1: '1 year y 1 month'
          }
        },
        desc_3: 'These Cookies are only installed on your device when you accept them with your consent.'
      }
    },
    section_3: {
      title: 'HOW TO REJECT OR BLOCK COOKIES',
      desc_1: 'You can accept or decline cookies at any time by going back to our cookie browser settings',
      cookie_set: 'Cookies Settings',
      desc_2: 'In addition, you can block cookies through your browser\'s settings tools, or you can set your browser to inform you when a server wants to install a cookie:',
      list_1: {
        desc_1: 'If you are using Microsoft Edge, you will find the option in the Settings menu > Cookies and site permissions > Manage and delete cookies and site data. To learn more',
        desc_2: '<a href="https://support.microsoft.com/es-es/microsoft-edge/permitir-temporalmente-las-cookies-y-los-datos-del-sitio-en-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2" target="_blank">https://support.microsoft.com/es-es/microsoft-edge/permitir-temporalmente-las-cookies-y-los-datos-del-sitio-en-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2</a>',
        desc_3: 'In case of direct access from Microsoft Edge, use this address by copying it into your browser: <a href="edge://settings/content/cookies" target="_blank">edge://settings/content/cookies</a>'
      },
      list_2: {
        desc_1: 'If you are using Firefox, you will find the option in the menu Tools > Options > Privacy > Cookies. To learn more',
        desc_2: '<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we" target="_blank">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>'
      },
      list_3: {
        desc_1: 'If you are using Chrome, in the section Options > Advanced options > Privacy. Find out more:',
        desc_2: '<a href="https://support.google.com/accounts/answer/61416?hl=es" target="_blank">https://support.google.com/accounts/answer/61416?hl=es</a>'
      },
      list_4: {
        desc_1: 'If you are using Opera, in the Security and Privacy option, you can manage your browser. Find out more:',
        desc_2: '<a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">https://help.opera.com/en/latest/web-preferences/#cookies</a>'
      },
      list_5: {
        desc_1: 'If you are using Safari, you will find the option in the Preferences/Privacy menu. To learn more:',
        desc_2: '<a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES" target="_blank">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a>'
      }
    },
    contact: 'For any questions regarding our Cookie Policy, please contact us at the following e-mail addres {mailContact}'
  },

  cookies: {
    accept: 'Accept',
    showCookieInfo: 'More Info',
    content: 'We use our own and third-party cookies and similar technologies to ensure the proper functioning of the Website, customize content according to user preferences and analyze the interaction of visitors accessing the Website, as well as their browsing habits. Para más información, consulte la <a href="/cookies" target="_blank">Cookies Policy</a>. Pulse aceptar todas las cookies pulsando el botón "Aceptar", así como configurarlas o rechazarlas pulsando "Configurar".',
    title_2: '<b>For what purposes is my information used and by whom?</b>',
    desc_1: 'This website uses its own and third-party cookies. If you do not agree with any of these purposes, you can customize your choices through this screen.',
    desc_2: 'You can see here the cookies used on this site, the owner and the purpose.',
    desc_3: 'You can set your consent preferences separately for each of the cookies.',
    desc_4: 'You consent to the use of cookies for the following purposes:',
    title: 'Cookies Policy',
    paragraph1:
      '<img src="images/artemisa_{color}.png" style="margin-bottom: -1px; width: 180px; height: 13px"/> (onwards, the "OWNER") informs, through this Cookies Policy, about the use of data storage and recovery devices in terminal equipment of the Users.',
    access: {
      question: '1 What are Cookies?',
      answer: {
        part1:
          'Cookies are files that are downloaded to the User\'s computer / smartphone / tablet when accessing to certain web pages and applications that allow the user\'s browsing preferences to be stored, making the interaction between the User and the Website faster and easy.',
        part2:
          'The information collected by cookies is anonymous and doesn\'t contain any sensitive information since they don\'t collect data that can personally identify the User. In any case, the User may access the settings of their browser to modify and / or block the installation of Cookies sent from the Website, without preventing access to the content.'
      }
    },
    object: {
      question: '2 What does the owner use cookies for?',
      answer: {
        part1:
          'In accordance with Recital 30 of the General Data Protection Regulation ("RGPD"), this Website uses cookies or other devices for storing and retrieving information to track the interactions of Users with the services offered in the website.',
        part2:
          'Cookies allow the recognition of a User\'s browser, as well as the type of device from which the Website is accessed and are used to facilitate the User\'s next visit and make the Website more useful to them.'
      }
    },
    functionallity1: {
      question: '3 How to disable cookies?',
      answer: {
        part1:
          'Those cookies that aren\'t mandatory for browsing this Website may be disabled in the configuration of the Website. These settings are found in the footer of the Website. In addition, all browsers allow changes to be made to disable cookie settings.',
        part2:
          'This is the reason why most browsers offer the possibility of managing cookies, to obtain more precise control over privacy. These settings are located in the "options" or "preferences" of the menu of your browser.',
        part3:
          'Here you can find links to each browser to disable cookies by following directions:',
        part4:
          'If you don\'t want to be tracked by cookies, Google has developed a plug-in to install in your browser that you can access at the following link: '
      }
    },
    functionallity2: {
      question: '4 Cookies in Mobile Devices',
      answer: {
        part1:
          'The owner of the Website also uses cookies or other storage devices on mobile devices.',
        part2:
          'Those cookies that aren\'t mandatory for browsing this Website may be disabled in the configuration of the Website.',
        part3:
          'These settings can be found in the footer of the Website. In addition, as in computer browsers, mobile device browsers allow you to make changes to privacy options or settings to disable or delete cookies.',
        part4:
          'If you want to modify the privacy options, follow the instructions specified by the developer of your mobile device browser.'
      }
    },
    conduct: {
      question: '5 Cookie acceptance',
      answer: {
        part1:
          'This Website doesn\'t install our cookies on users\' devices until they have accepted their installation.',
        part2:
          'We inform you that in the case of not accepting the installation of cookies or disabling any of these in the configuration, it\'s possible that certain services are not available without the use of these or that you can\'t access certain services or take full advantage of everything what this Website offers you.'
      }
    },
    owner: {
      title: 'Own Cookies',
      desc: 'Necessary for the proper functioning of the web.',
      session: {
        title: '<img src="/images/icons/cookie.png" height="20" width="20" class="mr-2" /> Session Cookies',
        desc: '<img src="/images/icons/target.png" height="20" width="20" class="mr-2" /> @:project',
        need: '<img src="/images/icons/need.png" height="20" width="20" class="mr-2" /> Necessary for the proper functioning'
      },
      preferences: {
        title: '<img src="/images/icons/cookie.png" height="20" width="20" class="mr-2" /> Preference Cookies',
        desc: '<img src="/images/icons/target.png" height="20" width="20" class="mr-2" /> @:project',
        need: '<img src="/images/icons/need.png" height="20" width="20" class="mr-2" /> Necessary for the proper functioning'
      }
    },
    third: {
      title: 'Third Cookies',
      twitter: {
        title: '<img src="/images/icons/cookie.png" height="20" width="20" class="mr-2" /> Twitter Login',
        desc: '<img src="/images/icons/target.png" height="20" width="20" class="mr-2" /> Twitter',
        need: '<img src="/images/icons/need.png" height="20" width="20" class="mr-2" /> Necessary for the proper functioning'
      },
      analytics: {
        title: '<img src="/images/icons/cookie.png" height="20" width="20" class="mr-2" /> Analytics, GTM, Optimize',
        desc: '<img src="/images/icons/target.png" height="20" width="20" class="mr-2" /> Google',
        need: '<img src="/images/icons/need.png" height="20" width="20" class="mr-2" /> Traffic analytics'
      },
      facebook: {
        title: '<img src="/images/icons/cookie.png" height="20" width="20" class="mr-2" />Meta',
        desc: '<img src="/images/icons/target.png" height="20" width="20" class="mr-2" />Meta',
        need: '<img src="/images/icons/need.png" height="20" width="20" class="mr-2" />Traffic analytics'
      }
    },
    additional: 'Additional information: You can know the complete information about the use of cookies, its configuration, origin, purposes and rights in our <a href="/cookies" target="_blank">Cookies Policy</a>.'
  },
  category: {
    title: 'Choose your Preferences',
    subtitle: 'Enhance the {project} Experience',
    all: 'All',
    straight: 'Straight',
    homo: 'Gay Boys',
    trans: 'Trans'
  },
  promo_code: {
    desade: '50€ BONUS!!',
    bsummit: '500€ BONUS!!',
    commUpdate: '50€ BONUS!!',
    DESADE_info: 'You need to invoice {amount} more to unlock your {bonus} bonus corresponding to the code {code}',
    DESADE_condition: 'Conditions: {bonus} bonus that is unlocked when you reach {minAmount} of income.',
    bsummit_info: 'You need to invoice {amount} more to unlock your {bonus} bonus corresponding to the code {code}',
    bsummit_condition: 'Conditions: {bonus} bonus that is unlocked when you reach {minAmount} of income.',
    SANCTUARYBCN23_info: 'You need to invoice {amount} more to unlock your {bonus} bonus corresponding to the code {code}',
    SANCTUARYBCN23_condition: 'Conditions: {bonus} bonus that is unlocked when you reach {minAmount} of income.',
    developBonusCode_info: 'You need to invoice {amount} more to unlock your {bonus} bonus corresponding to the code {code}',
    developBonusCode_condition: 'Conditions: {bonus} bonus that is unlocked when you reach {minAmount} of income.'
  }
}
