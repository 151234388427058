<template>
  <div class="d-flex  align-center justify-center fill-height">
    <div class=" pa-4  elevation-0">
      <v-img
        :src="`/images/${brandingLogo}.svg?dfk23`"
        alt="Logo"
        contain
        max-height="40"
        class="mb-4"
      ></v-img>
      <h1 class="text-center font-weight-bold mb-2 text-title">{{$t('application.under_construction')}}</h1>
      <p class=" text-center text-gray text-desc">{{ $t('application.under_construction_description')}}</p>
    </div>
  </div>
</template>
<script>

import {mapState} from 'vuex'

export default {
  name: 'Maintance',
  computed: {
    ...mapState('preferences', ['darkTheme']),
    brandingLogo() {
      if (!this.darkTheme) {
        return this.isMobile ? 'logo' : 'logo_horizontal'
      } else {
        return this.isMobile ? 'logo_white' : 'logo_horizontal_white'
      }
    }
  }
}
</script>

<style>
.text-center {
  text-align: center !important;
}

.text-title {
  font-size: 22px;
}

.text-desc {
  font-size: 16px;
  color: darkgrey;
}
</style>
