<template>
  <section>
    <v-row align="center">
      <v-col cols="12" md="4">
        <div class="title-text">
          {{ $t("wallet.currency_wallet", { currency: wallet.currency }) }}
          <span class="large-title-text semibold">{{
            $currency(wallet.amount, wallet.currency)
          }}</span>
        </div>
      </v-col>
      <v-divider />
      <v-col cols="12" md="8">
        <v-row no-gutters>
          <v-col cols="auto" class="pa-1">
            <v-btn small rounded depressed outlined @click="showDetail = true">
              {{ $t("wallet.show_detail") }}
            </v-btn>
          </v-col>
          <v-col v-if="!isInfluencer" cols="auto" class="pa-1">
            <PaymentButton
              wallet
              small
              color="secondary"
              :label="$t('actions.reload')"
            />
          </v-col>
          <v-col v-if="isInfluencer" cols="auto" class="pa-1">
            <c-btn
              :disabled="!canWithdraw"
              small
              color="secondary"
              @click="showWithdrawWarning"
            >
              {{ $t("actions.withdraw") }}
            </c-btn>
          </v-col>
          <v-col v-if="isInfluencer" cols="auto" class="pa-1">
            <v-btn
              :disabled="!wallet.amount || wallet.amount <= 0"
              small
              rounded
              depressed
              color="blue"
              @click="showMoveFunds = true"
            >
              {{ otherCurrencyText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <warning
      v-model="showWarning"
      :title="$t('models.withdraw.title')"
      :body="$t('withdraws.error_withdraw_paypal_warning')"
      @accept="redirectWarning"
      @close="showWarning = false"
    >
    </warning>
    <WalletDetailDialog v-model="showDetail" :wallet="wallet" />
    <Withdraw v-model="showWithdraw" :wallet="wallet" />
    <warning
      :closable="false"
      :actions="false"
      :title="$t('application.contract.title')"
      :body="$t('application.contract.signContractWallet')"
      v-model="showModal2Contract">
      <div style="display: flex;justify-content: center">
        <c-btn color="secondary" class="mr-2" @click="showModal2Contract=false">
          {{ $t('application.contract.btnClose') }}
        </c-btn>
        <c-btn class="ml-2" to="/contract">
          {{ $t('application.contract.btnSign') }}
        </c-btn>
      </div>
    </warning>
    <MoveFunds
      v-model="showMoveFunds"
      :wallet="wallet"
      :initCurrency="otherCurrencyText"
    />
  </section>
</template>
<script>
import { mapState } from "vuex";
import PaymentButton from "@/components/payments/PaymentButton";
import WalletDetailDialog from "./WalletDetailDialog";
import Withdraw from "./Withdraw";
import MoveFunds from "./MoveFunds";

export default {
  name: "WalletBalance",
  components: { PaymentButton, WalletDetailDialog, Withdraw, MoveFunds },
  props: {
    wallet: Object
  },
  data() {
    return {
      showWithdraw: false,
      showMoveFunds: false,
      showDetail: false,
      showWarning: false,
      showModal2Contract: false,
    };
  },
  computed: {
    ...mapState("withdraws", ["withdrawData", "withdrawMethods"]),
    ...mapState("profile", ["currentUser", "isInfluencer", "payout"]),
    otherCurrencyText() {
      const other = this.wallet.currency === "EUR" ? "USD" : "EUR";
      return this.$t("wallet.move_funds", { currency: other });
    },
    mustCompleteData() {
      return !this.withdrawData.isCompleted || !this.withdrawMethods.length;
    },

    hasEnoughFunds() {
      return this.wallet.amount >= 10000 || (this.wallet.amount > 2000 && this.currentUser.withdrawData.country);
    },
    canWithdraw() {
      const dataAndFunds = !this.mustCompleteData && this.hasEnoughFunds;
      if (this.payout.newPayout) {
        return dataAndFunds;
      } else {
        return !this.wallet.blockedAmount && dataAndFunds;
      }
    },
    isTransferWise() {
      return this.withdrawMethods[0].type === "transfer_wise";
    },
    isPayPalWithdrawMethod () {
      return this.withdrawMethods.some(m => m.type === 'paypal')
    }
  },
  methods: {
    redirectWarning() {
      this.$router.push({ name: "settings", query: { tab: "service" } });
    },
    showWithdrawWarning() {
      if (!this.currentUser.signedDocument) {
        this.showModal2Contract = true
      } else {
        if (this.isPayPalWithdrawMethod) {
          this.showWarning = true;
        } else {
          this.showWithdraw = true;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.current-balance {
  min-width: 180px;
  max-width: 250px;
}
.unit-balance {
  max-width: 150px;
  min-width: 120px;
}
</style>
