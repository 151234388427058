<template>
  <v-form ref="form" v-model="validForm">
    <span class="title-text-2 semibold">{{
      $t(`subscriptions.subscription_config`)
    }}</span>
    <v-row>
      <v-col cols="12">
        <CurrencyInput
          outlined
          color="orange"
          :dark="dark"
          :label="$t('models.user.subscription_price')"
          v-model="subscriptionPrice"
          hint="XXX,YY"
          :rules="[$vRequired]"
          min-value="500"
        />
        <span v-if="showErrorMinValue" class="color-primary">
          {{ $t("models.user.error_min_price_subscription", { price: $currency(100) }) }}
        </span>
        <span v-if="showErrorMaxValue" class="color-primary"
          >{{
            `${$t("models.user.error_price_subscription", {
              price: $currency(42000)
            })}`
          }}<a
            :href="`mailto:stars@${project.project}.com`"
            target="_blank"
            style="color:var(--unnamed-color-base)"
          >
            <b>stars@{{ project.project }}.com</b>
          </a></span
        >
      </v-col>
    </v-row>
    <h2>{{ $t("subscriptions.offer_packages") }}</h2>
    <v-row no-gutters v-for="offer in getOffers" :key="offer.type">
      <v-col cols="4">
        <v-checkbox
          v-model="offer.enabled"
          :label="$t(`subscriptions.discounts.${offer.type}`)"
          @change="switchOffer($event, offer)"
        />
      </v-col>
      <v-col cols="3" class="px-2">
        <v-text-field
          outlined
          hide-details
          :label="$t('subscriptions.discount')"
          :disabled="!offer.enabled"
          :rules="offerRules(offer)"
          :error="offer.enabled && offer.error"
          :value="offer.discount"
          @change="updateOffer($event, offer)"
        >
          <template #append><strong>%</strong></template>
        </v-text-field>
      </v-col>
      <v-col cols="5" v-if="offer.enabled" class="offer-total">
        <span :class="{ 'color-primary': showErrorOfferAmount(offer) }"
          >Total: {{ $currency(offerPrice(offer)) }}</span
        >
      </v-col>
      <v-col cols="12" class="my-1" v-if="offer.enabled && offer.error">
        <span class="small-text primary--text">{{
          $t("subscriptions.min_price_error", {
            price: $currency(offer.minPrice, currentUser.billing.currency)
          })
        }}</span>
      </v-col>
      <v-col cols="12" class="mb-2">
        <span class="small-text">{{
          $t(`subscriptions.discounts.hints.${offer.type}`)
        }}</span>
      </v-col>
    </v-row>
    <v-row justify="end" align="center">
      <v-col cols="auto">
        <c-btn text color="secondary" @click="$emit('cancel')">
          {{ $t("actions.close") }}
        </c-btn>
      </v-col>
      <v-col cols="auto">
        <c-btn :disabled="!validForm || hasErrors || showErrorMinValue || showErrorMaxValue" @click="saveSettings">{{
          $t("actions.save")
        }}</c-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import { ProfileActions } from "@/store";
import project from "@/project";
import validatable from "@/components/mixins/validatable";

export default {
  name: "OffersForm",
  mixins: [validatable("form")],
  props: {
    dark: { type: Boolean, default: null }
  },
  data() {
    return {
      subscriptionPrice: 0,
      offers: [],
      project
    };
  },
  computed: {
    ...mapState("profile", ["currentUser"]),
    getOffers() {
      return this.currentUser.subscriptionOffers || [];
    },
    enabledOffers() {
      return this.getOffers.filter(o => o.enabled);
    },
    hasErrors() {
      return !!this.enabledOffers.find(o => o.error);
    },
    showErrorMinValue() {
      return !!this.getOffers.find(offer => offer.enabled && this.offerPrice(offer) < 100);
    },
    showErrorMaxValue() {
      return !!this.getOffers.find(offer => offer.enabled && this.offerPrice(offer) >= 42000);
    }
  },
  methods: {
    offerRules(offer) {
      return offer.enabled
        ? [this.$vRequired, this.$vMin(1), this.$vMax(99)]
        : [];
    },
    offerPrice(offer) {
      const discountPrice =
        (parseFloat(offer.discount) / 100) * this.subscriptionPrice;
      const price = offer.months * (this.subscriptionPrice - discountPrice);
      return price;
    },
    showErrorOfferAmount(offer) {
      return this.offerPrice(offer) < 100 || this.offerPrice(offer) >= 42000;
    },
    switchOffer(enabled, offer) {
      offer.enabled = enabled;
      this.$forceUpdate();
    },
    updateOffer(discount, offer) {
      offer.discount = discount;
      const discountPrice =
        (parseFloat(offer.discount) / 100) * this.subscriptionPrice;
      const price = offer.months * (this.subscriptionPrice - discountPrice);
      offer.error = offer.enabled && price < offer.minPrice;
      this.$forceUpdate();
    },
    saveSettings() {
      const params = {
        subscriptionPrice: this.subscriptionPrice,
        subscriptionOffers: this.getOffers
      };
      this.$store.dispatch(ProfileActions.UpdateSubscriptionOffers, params);
      this.$emit("submitted", params);
    }
  },
  mounted() {
    this.subscriptionPrice = this.currentUser.subscriptionPrice;
  }
};
</script>
<style lang="scss" scoped>
.color-primary {
  color: var(--v-primary-base);
}
.offer-total {
  display: flex;
  align-content: center;
  line-height: 56px;
}
</style>
